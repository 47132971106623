// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import {Property} from "@swim/component";
import {GeoShape} from "@swim/geo";
import {GeoPoint} from "@swim/geo";
import {Graphics} from "@swim/graphics";
import {Hyperlink} from "@swim/controller";
import type {GeoLayerTraitObserver} from "@swim/map";
import {GeoLayerTrait} from "@swim/map";
import type {GeoLayerController} from "@swim/map";
import {AtlasEntityController} from "./"; // forward import

/** @public */
export interface AtlasEntityTraitObserver<T extends AtlasEntityTrait = AtlasEntityTrait> extends GeoLayerTraitObserver<T> {
  traitDidSetGeoShape?(geoShape: GeoShape | null, trait: T): void;

  traitDidSetGeoCenter?(geoCenter: GeoPoint | null, trait: T): void;

  traitDidSetGeoIcon?(geoIcon: Graphics | null, trait: T): void;
}

/** @public */
export class AtlasEntityTrait extends GeoLayerTrait {
  declare readonly observerType?: Class<AtlasEntityTraitObserver>;

  @Property({
    valueType: GeoShape,
    value: null,
    didSetValue(geoShape: GeoShape | null): void {
      this.owner.callObservers("traitDidSetGeoShape", geoShape, this.owner);
      this.owner.geoPerspective.setIntrinsic(geoShape);
      if (geoShape !== null) {
        this.owner.geoCenter.setIntrinsic(geoShape.bounds.center);
      }
    },
  })
  readonly geoShape!: Property<this, GeoShape | null>;

  @Property({
    valueType: GeoPoint,
    value: null,
    didSetValue(geoCenter: GeoPoint | null): void {
      this.owner.callObservers("traitDidSetGeoCenter", geoCenter, this.owner);
    },
  })
  readonly geoCenter!: Property<this, GeoPoint | null>;

  @Property({
    valueType: Graphics,
    value: null,
    didSetValue(geoIcon: Graphics | null): void {
      this.owner.callObservers("traitDidSetGeoIcon", geoIcon, this.owner);
    },
  })
  readonly geoIcon!: Property<this, Graphics | null>;

  @Property({valueType: Hyperlink, value: null})
  get hyperlink(): Property<this, Hyperlink | null> {
    return Property.getter();
  }

  override createGeoController(): GeoLayerController {
    return new AtlasEntityController();
  }
}
