// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Lazy} from "@swim/util";
import type {Graphics} from "@swim/graphics";
import {VectorIcon} from "@swim/graphics";
import type {SheetController} from "@swim/sheet";
import {AspectTrait} from "@nstream/domain";
import {AtlasController} from "./AtlasController";

/** @public */
export class AtlasAspectTrait extends AspectTrait {
  constructor() {
    super();
    this.id.setIntrinsic("atlas");
    this.title.setIntrinsic("Atlas");
    this.icon.setIntrinsic(AtlasAspectTrait.icon);
  }

  override createTabController(): SheetController | null {
    return (this.constructor as typeof AtlasAspectTrait).sharedController();
  }

  /** @internal */
  static SharedController: AtlasController | null | undefined = void 0;
  static sharedController(): AtlasController | null {
    let sharedController: AtlasController | null | undefined;
    if (Object.hasOwnProperty.call(this, "SharedController")) {
      sharedController = this.SharedController;
    }
    if (sharedController === void 0) {
      sharedController = this.createSharedController();
      Object.defineProperty(this, "SharedController", {
        value: sharedController,
        enumerable: true,
        configurable: true,
      });
    }
    return sharedController;
  }

  static createSharedController(): AtlasController | null {
    return new AtlasController();
  }

  /** @internal */
  @Lazy
  static get icon(): Graphics {
    return VectorIcon.create(24, 24, "M20.5 3l-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM10 5.47l4 1.4v11.66l-4-1.4V5.47zm-5 .99l3-1.01v11.7l-3 1.16V6.46zm14 11.08l-3 1.01V6.86l3-1.16v11.84z");
  }
}
