// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import type {Observes} from "@swim/util";
import {Property} from "@swim/component";
import {Length} from "@swim/math";
import {GeoShape} from "@swim/geo";
import {GeoPoint} from "@swim/geo";
import type {Trait} from "@swim/model";
import {TraitRef} from "@swim/model";
import type {SelectionOptions} from "@swim/model";
import {SelectableTrait} from "@swim/model";
import {Look} from "@swim/theme";
import type {NumberOrLook} from "@swim/theme";
import {NumberLook} from "@swim/theme";
import type {ColorOrLook} from "@swim/theme";
import {ColorLook} from "@swim/theme";
import {Feel} from "@swim/theme";
import type {View} from "@swim/view";
import {ViewRef} from "@swim/view";
import type {PositionGestureInput} from "@swim/view";
import {Graphics} from "@swim/graphics";
import {IconLayout} from "@swim/graphics";
import {TraitViewRef} from "@swim/controller";
import {Hyperlink} from "@swim/controller";
import {GeoView} from "@swim/map";
import type {GeoLayerControllerObserver} from "@swim/map";
import {GeoLayerController} from "@swim/map";
import {GeoShapeView} from "@swim/map";
import {GeoIconView} from "@swim/map";
import {Status} from "@nstream/domain";
import {EntityTrait} from "@nstream/domain";
import {AtlasEntityTrait} from "./AtlasEntityTrait";

/** @public */
export interface AtlasEntityControllerObserver<C extends AtlasEntityController = AtlasEntityController> extends GeoLayerControllerObserver<C> {
  controllerWillAttachEntityTrait?(entityTrait: EntityTrait, controller: C): void;

  controllerDidDetachEntityTrait?(entityTrait: EntityTrait, controller: C): void;
}

/** @public */
export class AtlasEntityController extends GeoLayerController {
  declare readonly observerType?: Class<AtlasEntityControllerObserver>;

  @Property({
    valueType: Status,
    value: Status.unknown(),
    didSetValue(status: Status): void {
      this.owner.geo.updateStatus(status);
    },
  })
  readonly status!: Property<this, Status>;

  @TraitRef({
    traitType: EntityTrait,
    consumed: true,
    observes: true,
    willAttachTrait(entityTrait: EntityTrait): void {
      this.owner.callObservers("controllerWillAttachEntityTrait", entityTrait, this.owner);
    },
    didAttachTrait(entityTrait: EntityTrait): void {
      this.owner.selectable.setTrait(entityTrait.getTrait(SelectableTrait));
    },
    initTrait(entityTrait: EntityTrait): void {
      this.owner.status.bindInlet(entityTrait.status);
      this.owner.selectable.setTrait(entityTrait.getTrait(SelectableTrait));
    },
    deinitTrait(entityTrait: EntityTrait): void {
      this.owner.selectable.setTrait(null);
      this.owner.status.unbindInlet(entityTrait.status);
    },
    willDetachTrait(entityTrait: EntityTrait): void {
      this.owner.selectable.setTrait(null);
    },
    didDetachTrait(entityTrait: EntityTrait): void {
      this.owner.callObservers("controllerDidDetachEntityTrait", entityTrait, this.owner);
    },
    traitDidInsertTrait(memberTrait: Trait, targetTrait: Trait | null): void {
      if (memberTrait instanceof SelectableTrait) {
        this.owner.selectable.setTrait(memberTrait);
      }
    },
  })
  readonly entity!: TraitRef<this, EntityTrait> & Observes<EntityTrait>;

  @TraitRef({
    traitType: SelectableTrait,
    observes: true,
    initTrait(selectableTrait: SelectableTrait): void {
      this.updateSelected(selectableTrait.selected);
    },
    traitDidSelect(options: SelectionOptions | null): void {
      this.updateSelected(true);
    },
    traitWillUnselect(): void {
      this.updateSelected(false);
    },
    updateSelected(selected: boolean): void {
      this.owner.fillOpacity.setIntrinsic(selected ? 0.25 : 0.1);
      this.owner.strokeOpacity.setIntrinsic(selected ? 0.5 : 0.2);
    },
  })
  readonly selectable!: TraitRef<this, SelectableTrait> & Observes<SelectableTrait> & {
    updateSelected(selected: boolean): void;
  };

  @TraitViewRef({
    extends: true,
    traitType: AtlasEntityTrait,
    initTrait(geoTrait: AtlasEntityTrait): void {
      if (this.owner.entity.trait === null) {
        this.owner.entity.setTrait(geoTrait.getTrait(EntityTrait));
      }
      this.owner.geoPerspective.bindInlet(geoTrait.geoPerspective);
      this.owner.geoShape.bindInlet(geoTrait.geoShape);
      this.owner.geoCenter.bindInlet(geoTrait.geoCenter);
      this.owner.geoIcon.bindInlet(geoTrait.geoIcon);
      this.owner.hyperlink.bindInlet(geoTrait.hyperlink);
    },
    deinitTrait(geoTrait: AtlasEntityTrait): void {
      this.owner.geoPerspective.unbindInlet(geoTrait.geoPerspective);
      this.owner.geoShape.unbindInlet(geoTrait.geoShape);
      this.owner.geoCenter.unbindInlet(geoTrait.geoCenter);
      this.owner.geoIcon.unbindInlet(geoTrait.geoIcon);
      this.owner.hyperlink.unbindInlet(geoTrait.hyperlink);
      if (this.owner.entity.trait === geoTrait.getTrait(EntityTrait)) {
        this.owner.entity.setTrait(null);
      }
    },
    viewType: GeoView,
    initView(geoView: GeoView): void {
      super.initView(geoView);
      this.updateStatus(this.owner.status.value);
    },
    updateStatus(status: Status): void {
      const geoView = this.view;
      if (geoView !== null) {
        geoView.modifyMood(Feel.default, status.moodModifier);
      }
    },
  })
  override readonly geo!: TraitViewRef<this, AtlasEntityTrait, GeoView> & GeoLayerController["geo"] & {
    updateStatus(status: Status): void;
  };

  @ViewRef({
    viewType: GeoShapeView,
    observes: true,
    get parentView(): View | null {
      return this.owner.geo.attachView();
    },
    insertChild(parent: View, child: GeoShapeView, target: View | null, key: string | undefined): void {
      if (target === null) {
        target = this.owner.icon.view;
      }
      parent.insertChild(child, target, key);
    },
    initView(shapeView: GeoShapeView): void {
      super.initView(shapeView);
      shapeView.geoShape.bindInlet(this.owner.geoShape);
      shapeView.fill.bindInlet(this.owner.fill);
      shapeView.fillOpacity.bindInlet(this.owner.fillOpacity);
      shapeView.stroke.bindInlet(this.owner.stroke);
      shapeView.strokeOpacity.bindInlet(this.owner.strokeOpacity);
      shapeView.strokeWidth.bindInlet(this.owner.strokeWidth);
      shapeView.hyperlink.bindInlet(this.owner.hyperlink);
    },
    deinitView(shapeView: GeoShapeView): void {
      shapeView.geoShape.unbindInlet(this.owner.geoShape);
      shapeView.fill.unbindInlet(this.owner.fill);
      shapeView.fillOpacity.unbindInlet(this.owner.fillOpacity);
      shapeView.stroke.unbindInlet(this.owner.stroke);
      shapeView.strokeOpacity.unbindInlet(this.owner.strokeOpacity);
      shapeView.strokeWidth.unbindInlet(this.owner.strokeWidth);
      shapeView.hyperlink.unbindInlet(this.owner.hyperlink);
      super.deinitView(shapeView);
    },
    viewDidEnter(shapeView: GeoShapeView): void {
      // hook
    },
    viewDidLeave(shapeView: GeoShapeView): void {
      // hook
    },
    viewDidPress(input: PositionGestureInput, event: Event | null, shapeView: GeoShapeView): void {
      // hook
    },
    viewDidLongPress(input: PositionGestureInput, shapeView: GeoShapeView): void {
      // hook
    },
  })
  readonly shape!: ViewRef<this, GeoShapeView> & Observes<GeoShapeView>;

  @Property({
    valueType: GeoShape,
    value: null,
    didSetValue(geoShape: GeoShape | null): void {
      this.owner.geoPerspective.setIntrinsic(geoShape);
      if (geoShape !== null) {
        this.owner.shape.insertView();
      } else {
        this.owner.shape.deleteView();
      }
    },
  })
  readonly geoShape!: Property<this, GeoShape | null>;

  @Property({
    valueType: ColorLook,
    value: Look.accentColor,
    transition: true,
  })
  get fill(): Property<this, ColorOrLook | null> {
    return Property.getter();
  }

  @Property({
    valueType: NumberLook,
    value: 0.1,
    transition: true,
  })
  get fillOpacity(): Property<this, NumberOrLook | undefined> {
    return Property.getter();
  }

  @Property({
    valueType: ColorLook,
    value: Look.accentColor,
    transition: true,
  })
  get stroke(): Property<this, ColorOrLook | null> {
    return Property.getter();
  }

  @Property({
    valueType: NumberLook,
    value: 0.2,
    transition: true,
  })
  get strokeOpacity(): Property<this, NumberOrLook | undefined> {
    return Property.getter();
  }

  @Property({
    valueType: Length,
    value: 1,
    transition: true,
  })
  get strokeWidth(): Property<this, Length | null> {
    return Property.getter();
  }

  @ViewRef({
    viewType: GeoIconView,
    observes: true,
    get parentView(): View | null {
      return this.owner.geo.attachView();
    },
    initView(iconView: GeoIconView): void {
      super.initView(iconView);
      iconView.geoCenter.bindInlet(this.owner.geoCenter);
      iconView.iconLayout.bindInlet(this.owner.iconLayout);
      iconView.iconColor.bindInlet(this.owner.iconColor);
      iconView.graphics.bindInlet(this.owner.geoIcon);
      iconView.hyperlink.bindInlet(this.owner.hyperlink);
    },
    deinitView(iconView: GeoIconView): void {
      iconView.geoCenter.unbindInlet(this.owner.geoCenter);
      iconView.iconLayout.unbindInlet(this.owner.iconLayout);
      iconView.iconColor.unbindInlet(this.owner.iconColor);
      iconView.graphics.unbindInlet(this.owner.geoIcon);
      iconView.hyperlink.unbindInlet(this.owner.hyperlink);
      super.deinitView(iconView);
    },
    viewDidEnter(iconView: GeoIconView): void {
      // hook
    },
    viewDidLeave(iconView: GeoIconView): void {
      // hook
    },
    viewDidPress(input: PositionGestureInput, event: Event | null, iconView: GeoIconView): void {
      // hook
    },
    viewDidLongPress(input: PositionGestureInput, iconView: GeoIconView): void {
      // hook
    },
  })
  readonly icon!: ViewRef<this, GeoIconView> & Observes<GeoIconView>;

  @Property({
    valueType: Graphics,
    value: null,
    didSetValue(icon: Graphics | null): void {
      if (icon !== null) {
        this.owner.icon.insertView();
      } else {
        this.owner.icon.deleteView();
      }
    },
  })
  readonly geoIcon!: Property<this, Graphics | null>;

  @Property({
    valueType: GeoPoint,
    value: null,
    transition: true,
  })
  readonly geoCenter!: Property<this, GeoPoint | null>;

  @Property({
    valueType: IconLayout,
    value: IconLayout.of(16, 16),
  })
  get iconLayout(): Property<this, IconLayout | null> {
    return Property.getter();
  }

  @Property({
    valueType: ColorLook,
    value: Look.accentColor,
    transition: true,
  })
  get iconColor(): Property<this, ColorOrLook | null> {
    return Property.getter();
  }

  @Property({valueType: Hyperlink, value: null})
  get hyperlink(): Property<this, Hyperlink | null> {
    return Property.getter();
  }
}
