// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import type {Observes} from "@swim/util";
import {Property} from "@swim/component";
import type {Trait} from "@swim/model";
import {TraitRef} from "@swim/model";
import type {PositionGestureInput} from "@swim/view";
import type {HtmlView} from "@swim/dom";
import {TraitViewRef} from "@swim/controller";
import {TraitControllerRef} from "@swim/controller";
import {TraitControllerSet} from "@swim/controller";
import {TitleToolView} from "@swim/toolbar";
import type {SheetView} from "@swim/sheet";
import type {SheetControllerObserver} from "@swim/sheet";
import {SheetController} from "@swim/sheet";
import {EntityTrait} from "@nstream/domain";
import type {RelationTrait} from "@nstream/domain";
import type {AspectRowController} from "./AspectRowController";
import type {EntityRowController} from "./EntityRowController";
import {EntityTableController} from "./EntityTableController";
import {RelationTableController} from "./RelationTableController";

/** @public */
export interface EntityNavControllerObserver<C extends EntityNavController = EntityNavController> extends SheetControllerObserver<C> {
  controllerWillAttachEntityTrait?(entityTrait: EntityTrait, controller: C): void;

  controllerDidDetachEntityTrait?(entityTrait: EntityTrait, controller: C): void;

  controllerWillAttachEntityTable?(entityTableController: EntityTableController, controller: C): void;

  controllerDidDetachEntityTable?(entityTableController: EntityTableController, controller: C): void;

  controllerDidPressEntityRow?(input: PositionGestureInput, event: Event | null, entityRowController: EntityRowController, controller: C): void;

  controllerDidLongPressEntityRow?(input: PositionGestureInput, entityRowController: EntityRowController, controller: C): void;

  controllerDidPressAspectRow?(input: PositionGestureInput, event: Event | null, aspectRowController: AspectRowController, controller: C): void;

  controllerDidLongPressAspectRow?(input: PositionGestureInput, aspectRowController: AspectRowController, controller: C): void;

  controllerWillAttachRelationTable?(relationTableController: RelationTableController, controller: C): void;

  controllerDidDetachRelationTable?(relationTableController: RelationTableController, controller: C): void;

  controllerDidPressRelatedEntityRow?(input: PositionGestureInput, event: Event | null, entityRowController: EntityRowController, controller: C): void;

  controllerDidLongPressRelatedEntityRow?(input: PositionGestureInput, entityRowController: EntityRowController, controller: C): void;

  controllerDidPressRelatedEntityMore?(input: PositionGestureInput, event: Event | null, entityRowController: EntityRowController, controller: C): void;
}

/** @public */
export class EntityNavController extends SheetController {
  declare readonly observerType?: Class<EntityNavControllerObserver>;

  @TraitRef({
    traitType: EntityTrait,
    observes: true,
    willAttachTrait(entityTrait: EntityTrait): void {
      this.owner.callObservers("controllerWillAttachEntityTrait", entityTrait, this.owner);
    },
    didAttachTrait(entityTrait: EntityTrait): void {
      this.owner.sheet.attachView();
      this.owner.title.set(entityTrait.title.value);
      this.owner.searchable.set(entityTrait.searchable.value);
      this.owner.entityTable.attachTrait(entityTrait);
      const relationTraits = entityTrait.relations.traits;
      for (const traitId in relationTraits) {
        const relationTrait = relationTraits[traitId]!;
        if (!relationTrait.hidden.value) {
          this.owner.relationTables.addTrait(relationTrait);
        }
      }
    },
    willDetachTrait(entityTrait: EntityTrait): void {
      this.owner.relationTables.deleteTraits(entityTrait.relations.traits);
      this.owner.entityTable.deleteTrait(entityTrait);
    },
    didDetachTrait(entityTrait: EntityTrait): void {
      this.owner.callObservers("controllerDidDetachEntityTrait", entityTrait, this.owner);
    },
    traitDidSetTitle(title: string): void {
      const titleController = this.owner.title.attachController();
      const titleView = titleController.tool.attachView();
      if (titleView instanceof TitleToolView) {
        titleView.content.set(title);
      }
    },
    traitDidSetSearchable(searchable: boolean): void {
      this.owner.searchable.setIntrinsic(searchable);
    },
    traitWillAttachRelation(relationTrait: RelationTrait, targetTrait: Trait | null): void {
      if (!relationTrait.hidden.value) {
        this.owner.relationTables.addTrait(relationTrait, targetTrait);
      }
    },
    traitDidDetachRelation(relationTrait: RelationTrait): void {
      this.owner.relationTables.deleteTrait(relationTrait);
    },
    traitDidSetRelationHidden(hidden: boolean, relationTrait: RelationTrait): void {
      if (hidden) {
        this.owner.relationTables.deleteTrait(relationTrait);
      } else {
        this.owner.relationTables.addTrait(relationTrait);
      }
    },
  })
  readonly entity!: TraitRef<this, EntityTrait> & Observes<EntityTrait>;

  @TraitViewRef({
    extends: true,
    //initView(sheetView: SheetView): void {
    //  super.initView(sheetView);
    //},
  })
  override readonly sheet!: TraitViewRef<this, Trait, SheetView> & SheetController["sheet"];

  @Property({
    extends: true,
    didSetValue(newSearching: boolean, oldSearching: boolean): void {
      super.didSetValue(newSearching, oldSearching);
      const entityTrait = this.owner.entity.trait;
      if (entityTrait !== null) {
        entityTrait.searching.setIntrinsic(newSearching);
      }
    },
  })
  override readonly searching!: Property<this, boolean>;

  override updateSearch(query: string, inputView: HtmlView): void {
    const entityTrait = this.entity.trait;
    if (entityTrait !== null) {
      entityTrait.updateSearch(query);
    }
  }

  override submitSearch(query: string, inputView: HtmlView): void {
    const entityTrait = this.entity.trait;
    if (entityTrait !== null) {
      entityTrait.submitSearch(query);
    }
  }

  @TraitControllerRef({
    controllerType: EntityTableController,
    binds: true,
    observes: true,
    getTraitRef(entityTableController: EntityTableController): TraitRef<unknown, EntityTrait> {
      return entityTableController.entity;
    },
    willAttachController(entityTableController: EntityTableController): void {
      this.owner.callObservers("controllerWillAttachEntityTable", entityTableController, this.owner);
    },
    didAttachController(entityTableController: EntityTableController): void {
      entityTableController.table.insertView(this.owner.sheet.view);
    },
    didDetachController(entityTableController: EntityTableController): void {
      this.owner.callObservers("controllerDidDetachEntityTable", entityTableController, this.owner);
    },
    controllerDidPressEntityRow(input: PositionGestureInput, event: Event | null, entityRowController: EntityRowController): void {
      this.owner.callObservers("controllerDidPressEntityRow", input, event, entityRowController, this.owner);
    },
    controllerDidLongPressEntityRow(input: PositionGestureInput, entityRowController: EntityRowController): void {
      this.owner.callObservers("controllerDidLongPressEntityRow", input, entityRowController, this.owner);
    },
    controllerDidPressAspectRow(input: PositionGestureInput, event: Event | null, aspectRowController: AspectRowController): void {
      this.owner.callObservers("controllerDidPressAspectRow", input, event, aspectRowController, this.owner);
    },
    controllerDidLongPressAspectRow(input: PositionGestureInput, aspectRowController: AspectRowController): void {
      this.owner.callObservers("controllerDidLongPressAspectRow", input, aspectRowController, this.owner);
    },
  })
  readonly entityTable!: TraitControllerRef<this, EntityTrait, EntityTableController> & Observes<EntityTableController>;

  @TraitControllerSet({
    controllerType: RelationTableController,
    binds: true,
    observes: true,
    getTraitRef(relationTableController: RelationTableController): TraitRef<unknown, RelationTrait> {
      return relationTableController.relation;
    },
    willAttachController(relationTableController: RelationTableController): void {
      this.owner.callObservers("controllerWillAttachRelationTable", relationTableController, this.owner);
    },
    didAttachController(relationTableController: RelationTableController): void {
      relationTableController.table.insertView(this.owner.sheet.view);
    },
    didDetachController(relationTableController: RelationTableController): void {
      this.owner.callObservers("controllerDidDetachRelationTable", relationTableController, this.owner);
    },
    controllerDidPressEntityRow(input: PositionGestureInput, event: Event | null, entityRowController: EntityRowController): void {
      this.owner.callObservers("controllerDidPressRelatedEntityRow", input, event, entityRowController, this.owner);
    },
    controllerDidLongPressEntityRow(input: PositionGestureInput, entityRowController: EntityRowController): void {
      this.owner.callObservers("controllerDidLongPressRelatedEntityRow", input, entityRowController, this.owner);
    },
    controllerDidPressEntityMore(input: PositionGestureInput, event: Event | null, entityRowController: EntityRowController): void {
      this.owner.callObservers("controllerDidPressRelatedEntityMore", input, event, entityRowController, this.owner);
    },
  })
  readonly relationTables!: TraitControllerSet<this, RelationTrait, RelationTableController> & Observes<RelationTableController>;
}
