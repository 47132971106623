// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import type {Observes} from "@swim/util";
import {Provider} from "@swim/component";
import type {Trait} from "@swim/model";
import {TraitRef} from "@swim/model";
import {Look} from "@swim/theme";
import {Feel} from "@swim/theme";
import type {View} from "@swim/view";
import type {PositionGestureInput} from "@swim/view";
import type {ControllerFlags} from "@swim/controller";
import {Controller} from "@swim/controller";
import {TraitViewRef} from "@swim/controller";
import {TraitViewControllerRef} from "@swim/controller";
import {TraitViewControllerSet} from "@swim/controller";
import type {HistoryState} from "@swim/controller";
import {HistoryService} from "@swim/controller";
import {TitleToolView} from "@swim/toolbar";
import type {ButtonToolController} from "@swim/toolbar";
import type {BarView} from "@swim/toolbar";
import type {BarController} from "@swim/toolbar";
import type {SheetView} from "@swim/sheet";
import {SheetController} from "@swim/sheet";
import type {BinderView} from "@swim/sheet";
import type {BinderControllerObserver} from "@swim/sheet";
import {BinderController} from "@swim/sheet";
import {AspectTrait} from "@nstream/domain";
import {EntityTrait} from "@nstream/domain";

/** @public */
export interface AspectBinderControllerObserver<C extends AspectBinderController = AspectBinderController> extends BinderControllerObserver<C> {
  controllerWillAttachEntityTrait?(entityTrait: EntityTrait, controller: C): void;

  controllerDidDetachEntityTrait?(entityTrait: EntityTrait, controller: C): void;
}

/** @public */
export class AspectBinderController extends BinderController {
  declare readonly observerType?: Class<AspectBinderControllerObserver>;

  @TraitViewRef({
    extends: true,
    didAttachTrait(sheetTrait: Trait): void {
      if (sheetTrait instanceof EntityTrait) {
        this.owner.entity.setTrait(sheetTrait);
      }
    },
    willDetachTrait(sheetTrait: Trait): void {
      if (this.owner.entity.trait === sheetTrait) {
        this.owner.entity.setTrait(null);
      }
    },
  })
  override readonly sheet!: TraitViewRef<this, Trait, SheetView> & BinderController["sheet"];

  @TraitRef({
    traitType: EntityTrait,
    willAttachTrait(entityTrait: EntityTrait): void {
      this.owner.callObservers("controllerWillAttachEntityTrait", entityTrait, this.owner);
    },
    didAttachTrait(entityTrait: EntityTrait): void {
      this.owner.tabs.sort(entityTrait.aspects.sorted);

      // Replace or insert new aspect tabs.
      const aspectTraits = entityTrait.aspects.traits;
      const aspectControllers = {} as {[aspectId: string]: SheetController | undefined};
      for (const traitId in aspectTraits) {
        const aspectTrait = aspectTraits[traitId]!;
        const aspectId = aspectTrait.id.value;
        if (aspectId === void 0) {
          continue;
        }
        const tabController = this.owner.tabs.createController(aspectTrait);
        tabController.sheet.setTrait(aspectTrait);
        if (tabController !== this.owner.getChild(aspectId)) {
          this.owner.setTab(aspectId, tabController);
        }
        aspectControllers[aspectId] = tabController;
      }

      // Remove old aspect tabs.
      const tabControllers = this.owner.tabs.controllers;
      for (const controllerId in tabControllers) {
        const tabController = tabControllers[controllerId]!;
        const tabTrait = tabController.sheet.trait;
        let aspectId: string | undefined;
        if (tabTrait instanceof AspectTrait && (aspectId = tabTrait.id.value) !== void 0 && !(aspectId in aspectControllers)) {
          this.owner.tabs.deleteController(tabController);
        }
      }

      const binderView = this.owner.binder.view;
      if (binderView !== null && binderView.mounted) {
        this.mountBinder(binderView, entityTrait);
      }
    },
    willDetachTrait(entityTrait: EntityTrait): void {
      const binderView = this.owner.binder.view;
      if (binderView !== null && binderView.mounted) {
        this.unmountBinder(binderView, entityTrait);
      }
    },
    didDetachTrait(entityTrait: EntityTrait): void {
      this.owner.callObservers("controllerDidDetachEntityTrait", entityTrait, this.owner);
    },
    traitWillAttachAspect(aspectTrait: AspectTrait): void {
      this.owner.tabs.insertTrait(void 0, aspectTrait);
    },
    traitDidDetachAspect(aspectTrait: AspectTrait): void {
      this.owner.tabs.deleteTrait(aspectTrait);
    },
    traitDidReinsertAspect(aspectTrait: AspectTrait, targetTrait: AspectTrait | null): void {
      this.owner.tabs.reinsertTrait(aspectTrait, targetTrait);
    },
    mountBinder(binderView: BinderView, entityTrait: EntityTrait): void {
      entityTrait.consume(this.owner);
    },
    unmountBinder(binderView: BinderView, entityTrait: EntityTrait): void {
      entityTrait.unconsume(this.owner);
    },
  })
  readonly entity!: TraitRef<this, EntityTrait> & Observes<EntityTrait> & {
    mountBinder(binderView: BinderView, entityTrait: EntityTrait): void,
    unmountBinder(binderView: BinderView, entityTrait: EntityTrait): void,
  };

  @TraitRef({
    traitType: AspectTrait,
    observes: true,
    initTrait(aspectTrait: AspectTrait): void {
      this.owner.title.set(aspectTrait.title.value);
    },
    deinitTrait(aspectTrait: AspectTrait): void {
      this.owner.title.set(void 0);
    },
    traitDidSetTitle(title: string): void {
      const titleController = this.owner.title.attachController();
      const titleView = titleController.tool.attachView();
      if (titleView instanceof TitleToolView) {
        titleView.content.set(title);
      }
    },
  })
  readonly aspect!: TraitRef<this, AspectTrait> & Observes<AspectTrait>;

  @TraitViewRef({
    extends: true,
    observesView: true,
    initView(binderView: BinderView): void {
      super.initView(binderView);
    },
    didAttachView(binderView: BinderView, targetView: View): void {
      const entityTrait = this.owner.entity.trait;
      if (binderView.mounted && entityTrait !== null) {
        this.owner.entity.mountBinder(binderView, entityTrait);
      }
      super.didAttachView(binderView, targetView);
    },
    willDetachView(binderView: BinderView): void {
      super.willDetachView(binderView);
      const entityTrait = this.owner.entity.trait;
      if (binderView.mounted && entityTrait !== null) {
        this.owner.entity.unmountBinder(binderView, entityTrait);
      }
    },
    viewDidMount(binderView: BinderView): void {
      const entityTrait = this.owner.entity.trait;
      if (entityTrait !== null) {
        this.owner.entity.mountBinder(binderView, entityTrait);
      }
    },
    viewWillUnmount(binderView: BinderView): void {
      const entityTrait = this.owner.entity.trait;
      if (entityTrait !== null) {
        this.owner.entity.unmountBinder(binderView, entityTrait);
      }
    },
  })
  override readonly binder!: TraitViewRef<this, Trait, BinderView> & BinderController["binder"];

  protected override didPressTabHandle(input: PositionGestureInput, event: Event | null, tabController: SheetController): void {
    super.didPressTabHandle(input, event, tabController);
    const tabTrait = tabController.sheet.trait;
    let aspectId: string | undefined;
    if (!(tabTrait instanceof AspectTrait) || (aspectId = tabTrait.id.value) === void 0) {
      return;
    }
    this.history.getService().pushHistory({
      parameters: {
        aspect: aspectId,
      },
      environment: {
        aspect: aspectId,
      },
    });
  }

  @TraitViewControllerRef({
    extends: true,
    initController(tabBarController: BarController): void {
      super.initController(tabBarController);
    },
    attachTabBarView(tabBarView: BarView, tabBarController: BarController): void {
      super.attachTabBarView(tabBarView, tabBarController);
      tabBarView.setIntrinsic({
        style: {
          backgroundColor: Look.backgroundColor,
          backdropFilter: "blur(2px)",
        },
        barHeight: 48,
      });
      tabBarView.modifyMood(Feel.default, [[Feel.transparent, 0], [Feel.translucent, 1]]);
    },
  })
  override readonly tabBar!: TraitViewControllerRef<this, Trait, BarView, BarController> & BinderController["tabBar"];

  @TraitViewControllerSet({
    extends: true,
    controllerKey(tabController: SheetController): string | undefined {
      const tabTrait = tabController.sheet.trait;
      if (tabTrait instanceof AspectTrait) {
        return tabTrait.id.value;
      }
      return void 0;
    },
    initController(tabController: SheetController): void {
      super.initController(tabController);
      const handleController = tabController.handle.attachController() as ButtonToolController;
      const handleView = handleController.tool.attachView();
      if (handleView.graphics.value === null) {
        const tabTrait = tabController.sheet.trait;
        if (tabTrait instanceof AspectTrait) {
          handleView.graphics.setIntrinsic(tabTrait.icon.value);
        }
      }
    },
    createController(trait?: Trait): SheetController {
      if (trait instanceof AspectTrait) {
        const tabController = trait.createTabController();
        if (tabController !== null) {
          return tabController;
        }
      }
      return super.createController(trait);
    },
    compare(a: SheetController, b: SheetController): number {
      const entityTrait = this.owner.entity.trait;
      const aTrait = a.sheet.trait;
      const bTrait = b.sheet.trait;
      if (entityTrait !== null && aTrait instanceof AspectTrait && bTrait instanceof AspectTrait) {
        return entityTrait.aspects.compareTraits(aTrait, bTrait);
      }
      return 0;
    },
  })
  override readonly tabs!: TraitViewControllerSet<this, Trait, SheetView, SheetController> & BinderController["tabs"];

  @TraitViewControllerRef({
    extends: true,
    attachActiveTrait(activeTrait: Trait, activeController: SheetController): void {
      super.attachActiveTrait(activeTrait, activeController);
      if (activeTrait instanceof AspectTrait) {
        this.owner.aspect.setTrait(activeTrait);
      }
    },
    detachActiveTrait(activeTrait: Trait, activeController: SheetController): void {
      this.owner.aspect.setTrait(null);
      super.detachActiveTrait(activeTrait, activeController);
    },
  })
  override readonly active!: TraitViewControllerRef<this, Trait, SheetView, SheetController> & BinderController["active"];

  protected updateNavigationState(): void {
    const historyState = this.history.getService().historyState;
    let aspectId = historyState.parameters.aspect;
    if (aspectId === void 0 || aspectId.length === 0) {
      aspectId = historyState.environment.aspect;
    }
    if (aspectId === void 0 || aspectId.length === 0) {
      const entityTrait = this.entity.trait;
      const aspectTrait = entityTrait !== null ? entityTrait.aspect.trait : null;
      aspectId = aspectTrait !== null ? aspectTrait.id.value : void 0;
    }
    if (aspectId === void 0 || aspectId.length === 0) {
      return;
    }
    const tabController = this.getChild(aspectId, SheetController);
    if (tabController === null) {
      return;
    }
    this.active.setController(tabController);
  }

  @Provider({
    serviceType: HistoryService,
    observes: true,
    updateHistory(newHistoryState: HistoryState, oldHistoryState: HistoryState | null): void {
      this.owner.requireUpdate(Controller.NeedsResolve);
    },
    serviceDidPushHistory(newHistoryState: HistoryState, oldHistoryState: HistoryState): void {
      this.updateHistory(newHistoryState, oldHistoryState);
    },
    serviceDidPopHistory(newHistoryState: HistoryState, oldHistoryState: HistoryState): void {
      this.updateHistory(newHistoryState, oldHistoryState);
    },
  })
  readonly history!: Provider<this, HistoryService> & Observes<HistoryService> & {
    updateHistory(historyState: HistoryState, oldHistoryState: HistoryState | null): void;
  };

  protected override onResolve(): void {
    super.onResolve();
    this.updateNavigationState();
  }

  static override MountFlags: ControllerFlags = BinderController.MountFlags | Controller.NeedsResolve;
}
