// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Murmur3} from "@swim/util";
import type {Mutable} from "@swim/util";
import {Numbers} from "@swim/util";
import {Constructors} from "@swim/util";
import type {HashCode} from "@swim/util";
import type {Equivalent} from "@swim/util";
import {Mapping} from "@swim/util";
import {Constant} from "@swim/util";
import {Piecewise} from "@swim/util";
import type {Interpolate} from "@swim/util";
import {Interpolator} from "@swim/util";
import {LinearDomain} from "@swim/util";
import type {Output} from "@swim/codec";
import type {Debug} from "@swim/codec";
import {Format} from "@swim/codec";
import {Feel} from "@swim/theme";
import type {MoodVectorUpdates} from "@swim/theme";

/** @public */
export class Status implements Interpolate<Status>, HashCode, Equivalent, Debug {
  /** @internal */
  constructor(unknown: number, warning: number, alert: number) {
    this.unknown = unknown;
    this.warning = warning;
    this.alert = alert;
  }

  readonly unknown: number;

  withUnknown(unknown?: number): Status {
    if (unknown === void 0) {
      unknown = 1;
    } else if (isFinite(unknown)) {
      unknown = Math.min(Math.max(0, unknown), 1);
    } else {
      throw new Error("invalid unknown level: " + unknown);
    }
    return new Status(unknown, this.warning, this.alert);
  }

  readonly warning: number;

  withWarning(warning?: number): Status {
    if (warning === void 0) {
      warning = 1;
    } else if (isFinite(warning)) {
      warning = Math.min(Math.max(0, warning), 1);
    } else {
      throw new Error("invalid warning level: " + warning);
    }
    return new Status(this.unknown, warning, this.alert);
  }

  readonly alert: number;

  withAlert(alert?: number): Status {
    if (alert === void 0) {
      alert = 1;
    } else if (isFinite(alert)) {
      alert = Math.min(Math.max(0, alert), 1);
    } else {
      throw new Error("invalid alert level: " + alert);
    }
    return new Status(this.unknown, this.warning, alert);
  }

  get moodModifier(): MoodVectorUpdates {
    return [[Feel.primary, 1],
            [Feel.inactive, this.unknown !== 0 ? this.unknown : void 0],
            [Feel.warning, this.warning !== 0 ? this.warning : void 0],
            [Feel.alert, this.alert !== 0 ? this.alert : void 0]];
  }

  interpolateTo(that: Status): Interpolator<Status>;
  interpolateTo(that: unknown): Interpolator<Status> | null;
  interpolateTo(that: unknown): Interpolator<Status> | null {
    if (that instanceof Status) {
      return StatusInterpolator(this, that);
    } else {
      return null;
    }
  }

  equivalentTo(that: unknown, epsilon?: number): boolean {
    if (this === that) {
      return true;
    } else if (that instanceof Status) {
      return Numbers.equivalent(this.unknown, that.unknown, epsilon)
          && Numbers.equivalent(this.warning, that.warning, epsilon)
          && Numbers.equivalent(this.alert, that.alert, epsilon);
    }
    return false;
  }

  canEqual(that: unknown): boolean {
    return that instanceof Status;
  }

  equals(that: unknown): boolean {
    if (this === that) {
      return true;
    } else if (that instanceof Status) {
      return that.canEqual(this)
          && this.unknown === that.unknown
          && this.warning === that.warning
          && this.alert === that.alert;
    }
    return false;
  }

  hashCode(): number {
    return Murmur3.mash(Murmur3.mix(Murmur3.mix(Murmur3.mix(Constructors.hash(Status),
        Numbers.hash(this.unknown)), Numbers.hash(this.warning)), Numbers.hash(this.alert)));
  }

  debug<T>(output: Output<T>): Output<T> {
    const unknown = this.unknown;
    const warning = this.warning;
    const alert = this.alert;
    output = output.write("Status").write(46/*'.'*/);
    if (unknown !== 0) {
      output = output.write("unknown").write(40/*'('*/);
      if (unknown !== 1) {
        output = output.debug(unknown);
      }
      output = output.write(41/*')'*/);
      if (warning !== 0) {
        output = output.write(46/*'.'*/).write("withWarning").write(40/*'('*/);
        if (warning !== 1) {
          output = output.debug(warning);
        }
        output = output.write(41/*')'*/);
      }
      if (alert !== 0) {
        output = output.write(46/*'.'*/).write("withAlert").write(40/*'('*/);
        if (alert !== 1) {
          output = output.debug(alert);
        }
        output = output.write(41/*')'*/);
      }
    } else if (warning !== 0) {
      output = output.write("warning").write(40/*'('*/);
      if (warning !== 1) {
        output = output.debug(warning);
      }
      output = output.write(41/*')'*/);
      if (alert !== 0) {
        output = output.write(46/*'.'*/).write("withAlert").write(40/*'('*/);
        if (alert !== 1) {
          output = output.debug(alert);
        }
        output = output.write(41/*')'*/);
      }
    } else if (alert !== 0) {
      output = output.write("alert").write(40/*'('*/);
      if (alert !== 1) {
        output = output.debug(alert);
      }
      output = output.write(41/*')'*/);
    } else {
      output = output.write("normal").write(40/*'('*/).write(41/*')'*/);
    }
    return output;
  }

  toString(): string {
    return Format.debug(this);
  }

  /** @internal */
  static Normal: Status | null = null;
  static normal(): Status {
    let status = Status.Normal;
    if (status === null) {
      status = new Status(0, 0, 0);
      Status.Normal = status;
    }
    return status;
  }

  /** @internal */
  static Unknown: Status | null = null;
  static unknown(unknown?: number): Status {
    let status: Status | null;
    if (unknown === void 0 || unknown === 1) {
      status = Status.Unknown;
      if (status === null) {
        status = new Status(1, 0, 0);
        Status.Unknown = status;
      }
    } else if (isFinite(unknown)) {
      unknown = Math.min(Math.max(0, unknown), 1);
      status = new Status(unknown, 0, 0);
    } else {
      throw new Error("invalid unknown level: " + unknown);
    }
    return status;
  }

  /** @internal */
  static Warning: Status | null = null;
  static warning(warning?: number): Status {
    let status: Status | null;
    if (warning === void 0 || warning === 1) {
      status = Status.Warning;
      if (status === null) {
        status = new Status(0, 1, 0);
        Status.Warning = status;
      }
    } else if (isFinite(warning)) {
      warning = Math.min(Math.max(0, warning), 1);
      status = new Status(0, warning, 0);
    } else {
      throw new Error("invalid warning level: " + warning);
    }
    return status;
  }

  /** @internal */
  static Alert: Status | null = null;
  static alert(alert?: number): Status {
    let status: Status | null;
    if (alert === void 0 || alert === 1) {
      status = Status.Alert;
      if (status === null) {
        status = new Status(0, 1, 1);
        Status.Alert = status;
      }
    } else if (isFinite(alert)) {
      alert = Math.min(Math.max(0, alert), 1);
      status = new Status(0, 1, alert);
    } else {
      throw new Error("invalid alert level: " + alert);
    }
    return status;
  }

  /** @internal */
  static NormalRange: Constant<Status> | null = null;
  static normalRange(): Constant<Status> {
    let range = Status.NormalRange;
    if (range === null) {
      range = Constant(Status.normal());
      Status.NormalRange = range;
    }
    return range;
  }

  /** @internal */
  static WarningAscending: Interpolator<Status> | null = null;
  /** @internal */
  static WarningDescending: Interpolator<Status> | null = null;
  static warningRange(warning0?: number, warning1?: number): Interpolator<Status> {
    if (warning0 === void 0) {
      warning0 = 0;
    } else if (isFinite(warning0)) {
      warning0 = Math.min(Math.max(0, warning0), 1);
    } else {
      throw new Error("invalid warning level: " + warning0);
    }
    if (warning1 === void 0) {
      warning1 = 1;
    } else if (isFinite(warning1)) {
      warning1 = Math.min(Math.max(0, warning1), 1);
    } else {
      throw new Error("invalid warning level: " + warning1);
    }
    let range: Interpolator<Status> | null;
    if (warning0 === 0 && warning1 === 1) {
      range = Status.WarningAscending;
      if (range === null) {
        range = Status.normal().interpolateTo(Status.warning());
        Status.WarningAscending = range;
      }
    } else if (warning0 === 1 && warning1 === 0) {
      range = Status.WarningDescending;
      if (range === null) {
        range = Status.warning().interpolateTo(Status.normal());
        Status.WarningDescending = range;
      }
    } else {
      range = new Status(0, warning0, 0).interpolateTo(new Status(0, warning1, 0));
    }
    return range;
  }

  /** @internal */
  static AlertAscending: Interpolator<Status> | null = null;
  static AlertDescending: Interpolator<Status> | null = null;
  static alertRange(alert0?: number, alert1?: number): Interpolator<Status> {
    if (alert0 === void 0) {
      alert0 = 0;
    } else if (isFinite(alert0)) {
      alert0 = Math.min(Math.max(0, alert0), 1);
    } else {
      throw new Error("invalid alert level: " + alert0);
    }
    if (alert1 === void 0) {
      alert1 = 1;
    } else if (isFinite(alert1)) {
      alert1 = Math.min(Math.max(0, alert1), 1);
    } else {
      throw new Error("invalid alert level: " + alert1);
    }
    let range: Interpolator<Status> | null;
    if (alert0 === 0 && alert1 === 1) {
      range = Status.AlertAscending;
      if (range === null) {
        range = Status.warning().interpolateTo(Status.alert());
        Status.AlertAscending = range;
      }
    } else if (alert0 === 1 && alert1 === 0) {
      range = Status.AlertDescending;
      if (range === null) {
        range = Status.alert().interpolateTo(Status.warning());
        Status.AlertDescending = range;
      }
    } else {
      range = new Status(0, 1, alert0).interpolateTo(new Status(0, 1, alert1));
    }
    return range;
  }

  /** @internal */
  static PanicRange: Constant<Status> | null = null;
  static panicRange(): Constant<Status> {
    let range = Status.PanicRange;
    if (range === null) {
      range = Constant(Status.alert());
      Status.PanicRange = range;
    }
    return range;
  }

  static worsening(lowerBound: number, warningThreshold: number, alertThreshold: number, panicThreshold: number, upperBound: number): Piecewise<number, Status> {
    const normalMapping = Mapping(LinearDomain(lowerBound, warningThreshold), this.normalRange());
    const warningMapping = Mapping(LinearDomain(warningThreshold, alertThreshold), this.warningRange(0, 1));
    const alertMapping = Mapping(LinearDomain(alertThreshold, panicThreshold), this.alertRange(0, 1));
    const panicMapping = Mapping(LinearDomain(panicThreshold, upperBound), this.panicRange());
    return Piecewise(normalMapping, warningMapping, alertMapping, panicMapping);
  }

  static improving(lowerBound: number, panicThreshold: number, alertThreshold: number, warningThreshold: number, upperBound: number): Piecewise<number, Status> {
    const panicMapping = Mapping(LinearDomain(lowerBound, panicThreshold), this.panicRange());
    const alertMapping = Mapping(LinearDomain(panicThreshold, alertThreshold), this.alertRange(1, 0));
    const warningMapping = Mapping(LinearDomain(alertThreshold, warningThreshold), this.warningRange(1, 0));
    const normalMapping = Mapping(LinearDomain(warningThreshold, upperBound), this.normalRange());
    return Piecewise(panicMapping, alertMapping, warningMapping, normalMapping);
  }
}

/** @internal */
export const StatusInterpolator = (function (_super: typeof Interpolator) {
  const StatusInterpolator = function (s0: Status, s1: Status): Interpolator<Status> {
    const interpolator = function (u: number): Status {
      const s0 = interpolator[0];
      const s1 = interpolator[1];
      const unknown = s0.unknown + u * (s1.unknown - s0.unknown);
      const warning = s0.warning + u * (s1.warning - s0.warning);
      const alert = s0.alert + u * (s1.alert - s0.alert);
      return new Status(unknown, warning, alert);
    } as Interpolator<Status>;
    Object.setPrototypeOf(interpolator, StatusInterpolator.prototype);
    (interpolator as Mutable<typeof interpolator>)[0] = s0;
    (interpolator as Mutable<typeof interpolator>)[1] = s1;
    return interpolator;
  } as {
    (s0: Status, s1: Status): Interpolator<Status>;

    /** @internal */
    prototype: Interpolator<Status>;
  };

  StatusInterpolator.prototype = Object.create(_super.prototype);
  StatusInterpolator.prototype.constructor = StatusInterpolator;

  return StatusInterpolator;
})(Interpolator);
