// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Lazy} from "@swim/util";
import type {Class} from "@swim/util";
import type {Observes} from "@swim/util";
import {Property} from "@swim/component";
import type {Trait} from "@swim/model";
import {TraitRef} from "@swim/model";
import type {SelectionOptions} from "@swim/model";
import {SelectableTrait} from "@swim/model";
import {Look} from "@swim/theme";
import {Feel} from "@swim/theme";
import type {View} from "@swim/view";
import {ViewRef} from "@swim/view";
import type {PositionGestureInput} from "@swim/view";
import type {Graphics} from "@swim/graphics";
import {VectorIcon} from "@swim/graphics";
import {TraitViewRef} from "@swim/controller";
import {TextCellView} from "@swim/table";
import {IconCellView} from "@swim/table";
import type {LeafView} from "@swim/table";
import type {LeafTrait} from "@swim/table";
import type {RowControllerObserver} from "@swim/table";
import {RowController} from "@swim/table";
import {Status} from "@nstream/domain";
import {EntityTrait} from "@nstream/domain";

/** @public */
export interface EntityRowControllerObserver<C extends EntityRowController = EntityRowController> extends RowControllerObserver<C> {
  controllerWillAttachEntityTrait?(entityTrait: EntityTrait, controller: C): void;

  controllerDidDetachEntityTrait?(entityTrait: EntityTrait, controller: C): void;

  controllerWillAttachIconCellView?(iconCellView: IconCellView, controller: C): void;

  controllerDidDetachIconCellView?(iconCellView: IconCellView, controller: C): void;

  controllerWillAttachTitleCellView?(titleCellView: TextCellView, controller: C): void;

  controllerDidDetachTitleCellView?(titleCellView: TextCellView, controller: C): void;

  controllerWillAttachMoreCellView?(moreCellView: IconCellView, controller: C): void;

  controllerDidDetachMoreCellView?(moreCellView: IconCellView, controller: C): void;

  controllerDidPressMoreCellView?(input: PositionGestureInput, event: Event | null, controller: C): void;
}

/** @public */
export class EntityRowController extends RowController {
  declare readonly observerType?: Class<EntityRowControllerObserver>;

  @Property({
    valueType: Status,
    value: Status.unknown(),
    didSetValue(status: Status): void {
      this.owner.leaf.updateStatus(status);
    },
  })
  readonly status!: Property<this, Status>;

  @TraitRef({
    traitType: EntityTrait,
    consumed: true,
    observes: true,
    initTrait(entityTrait: EntityTrait): void {
      this.owner.status.setIntrinsic(entityTrait.status.value);
    },
    willAttachTrait(entityTrait: EntityTrait): void {
      this.owner.callObservers("controllerWillAttachEntityTrait", entityTrait, this.owner);
    },
    didAttachTrait(entityTrait: EntityTrait): void {
      this.owner.selectable.setTrait(entityTrait.getTrait(SelectableTrait));
      const rowView = this.owner.row.attachView();
      const leafView = rowView.leaf.insertView();
      this.owner.iconCell.insertView(leafView).setIntrinsic({
        graphics: entityTrait.icon.value,
      });
      this.owner.titleCell.insertView(leafView).setIntrinsic({
        content: entityTrait.title.value,
      });
      if (entityTrait.relatable.value && this.owner.moreCell.enabled) {
        this.owner.moreCell.insertView(leafView);
      }
    },
    deinitTrait(entityTrait: EntityTrait): void {
      this.owner.status.setIntrinsic(Status.unknown());
    },
    willDetachTrait(entityTrait: EntityTrait): void {
      this.owner.selectable.setTrait(null);
    },
    didDetachTrait(entityTrait: EntityTrait): void {
      this.owner.callObservers("controllerDidDetachEntityTrait", entityTrait, this.owner);
    },
    traitDidInsertTrait(memberTrait: Trait, targetTrait: Trait | null): void {
      if (memberTrait instanceof SelectableTrait) {
        this.owner.selectable.setTrait(memberTrait);
      }
    },
    traitDidSetTitle(title: string): void {
      const titleCellView = this.owner.titleCell.view;
      if (titleCellView !== null) {
        titleCellView.content.setIntrinsic(title);
      }
    },
    traitDidSetIcon(icon: Graphics | null): void {
      const iconCellView = this.owner.iconCell.view;
      if (iconCellView !== null) {
        iconCellView.graphics.setIntrinsic(icon);
      }
    },
    traitDidSetStatus(status: Status): void {
      this.owner.status.setIntrinsic(status);
    },
    traitDidSetRelatable(relatable: boolean): void {
      if (!this.owner.moreCell.enabled) {
        return;
      } else if (relatable) {
        this.owner.moreCell.insertView();
      } else {
        this.owner.moreCell.removeView();
      }
    },
  })
  readonly entity!: TraitRef<this, EntityTrait> & Observes<EntityTrait>;

  @TraitRef({
    traitType: SelectableTrait,
    observes: true,
    initTrait(selectableTrait: SelectableTrait): void {
      const leafView = this.owner.leaf.view;
      if (leafView !== null) {
        leafView.highlight.setIntrinsic(selectableTrait.selected);
      }
    },
    traitDidSelect(options: SelectionOptions | null): void {
      const leafView = this.owner.leaf.view;
      if (leafView !== null) {
        leafView.highlight.focus();
      }
    },
    traitWillUnselect(): void {
      const leafView = this.owner.leaf.view;
      if (leafView !== null) {
        leafView.highlight.unfocus();
      }
    },
  })
  readonly selectable!: TraitRef<this, SelectableTrait> & Observes<SelectableTrait>;

  @TraitViewRef({
    extends: true,
    initView(leafView: LeafView): void {
      super.initView(leafView);
      this.updateStatus(this.owner.status.value);

      leafView.style.cursor.setIntrinsic("pointer");
      this.owner.iconCell.insertView(leafView);
      this.owner.titleCell.insertView(leafView);
      const entityTrait = this.owner.entity.trait;
      if (entityTrait !== null && entityTrait.relatable.value && this.owner.moreCell.enabled) {
        this.owner.moreCell.insertView(leafView);
      }

      const selectableTrait = this.owner.selectable.trait;
      if (selectableTrait !== null) {
        leafView.highlight.setIntrinsic(selectableTrait.selected);
      }
    },
    didAttachView(leafView: LeafView, targetView: View | null): void {
      super.didAttachView(leafView, targetView);
      if (leafView.mounted && !leafView.culled) {
        this.owner.consume(leafView);
      }
    },
    viewDidMount(leafView: LeafView): void {
      if (!leafView.culled) {
        this.owner.consume(leafView);
      }
    },
    viewWillUnmount(leafView: LeafView): void {
      this.owner.unconsume(leafView);
    },
    viewDidCull(leafView: LeafView): void {
      this.owner.unconsume(leafView);
    },
    viewWillUncull(leafView: LeafView): void {
      this.owner.consume(leafView);
    },
    updateStatus(status: Status): void {
      const leafView = this.view;
      if (leafView !== null) {
        leafView.modifyMood(Feel.default, status.moodModifier);
      }
    },
  })
  override readonly leaf!: TraitViewRef<this, LeafTrait, LeafView> & RowController["leaf"] & {
    updateStatus(status: Status): void;
  };

  @ViewRef({
    viewType: IconCellView,
    viewKey: "icon",
    get parentView(): View | null {
      return this.owner.leaf.attachView();
    },
    willAttachView(iconCellView: IconCellView): void {
      this.owner.callObservers("controllerWillAttachIconCellView", iconCellView, this.owner);
    },
    didDetachView(iconCellView: IconCellView): void {
      this.owner.callObservers("controllerDidDetachIconCellView", iconCellView, this.owner);
    },
    createView(): IconCellView {
      return (super.createView() as IconCellView).setIntrinsic({
        iconLayout: {width: 32, height: 32},
        iconColor: Look.accentColor,
      });
    },
  })
  readonly iconCell!: ViewRef<this, IconCellView>;

  @ViewRef({
    viewType: TextCellView,
    viewKey: "title",
    get parentView(): View | null {
      return this.owner.leaf.attachView();
    },
    willAttachView(titleCellView: TextCellView): void {
      this.owner.callObservers("controllerWillAttachTitleCellView", titleCellView, this.owner);
    },
    didDetachView(titleCellView: TextCellView): void {
      this.owner.callObservers("controllerDidDetachTitleCellView", titleCellView, this.owner);
    },
  })
  readonly titleCell!: ViewRef<this, TextCellView>;

  @ViewRef({
    viewType: IconCellView,
    viewKey: "more",
    observes: true,
    init(): void {
      this.enabled = true;
    },
    get parentView(): View | null {
      return this.owner.leaf.attachView();
    },
    willAttachView(iconCellView: IconCellView): void {
      this.owner.callObservers("controllerWillAttachMoreCellView", iconCellView, this.owner);
    },
    didDetachView(iconCellView: IconCellView): void {
      this.owner.callObservers("controllerDidDetachMoreCellView", iconCellView, this.owner);
    },
    viewDidPress(input: PositionGestureInput, event: Event | null): void {
      this.owner.callObservers("controllerDidPressMoreCellView", input, event, this.owner);
    },
    createView(): IconCellView {
      return (super.createView() as IconCellView).setIntrinsic({
        iconLayout: {width: 24, height: 24},
        iconColor: Look.legendColor,
        graphics: EntityRowController.moreIcon,
      });
    },
  })
  readonly moreCell!: ViewRef<this, IconCellView> & Observes<IconCellView> & {
    /** @internal */
    enabled: boolean;
  };

  /** @internal */
  @Lazy
  static get moreIcon(): VectorIcon {
    return VectorIcon.create(24, 24, "M9.59,8.92 L12.67,12 L9.59,15.08 L11,16.5 L15.5,12 L11,7.5 L9.59,8.92 Z M2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 C6.48,2 2,6.48 2,12 Z M20,12 C20,16.42 16.42,20 12,20 C7.58,20 4,16.42 4,12 C4,7.58 7.58,4 12,4 C16.42,4 20,7.58 20,12 Z");
  }
}
