// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import type {Timing} from "@swim/util";
import type {Observes} from "@swim/util";
import {Format} from "@swim/codec";
import {Property} from "@swim/component";
import {Provider} from "@swim/component";
import type {DateTime} from "@swim/time";
import {Look} from "@swim/theme";
import type {View} from "@swim/view";
import {ViewRef} from "@swim/view";
import {ViewSet} from "@swim/view";
import type {PositionGestureInput} from "@swim/view";
import {GraphicsView} from "@swim/graphics";
import {TextRunView} from "@swim/graphics";
import type {ControllerObserver} from "@swim/controller";
import {Controller} from "@swim/controller";
import {CellView} from "@swim/table";
import {TextCellView} from "@swim/table";
import {LeafView} from "@swim/table";
import {RowView} from "@swim/table";
import {SliceView} from "@swim/pie";
import {DataPointView} from "@swim/chart";
import {SeriesPlotView} from "@swim/chart";
import {LinePlotView} from "@swim/chart";
import {CalendarService} from "@nstream/domain";

/** @public */
export interface TimeSeriesControllerObserver<C extends TimeSeriesController = TimeSeriesController> extends ControllerObserver<C> {
  controllerDidSetTitle?(title: string, controller: C): void;

  controllerDidSetFocused?(focused: boolean, controller: C): void;

  controllerDidSetPinned?(pinned: boolean, controller: C): void;

  controllerWillAttachPlot?(plotView: SeriesPlotView<DateTime, number>, targetView: View | null, controller: C): void;

  controllerDidDetachPlot?(plotView: SeriesPlotView<DateTime, number>, controller: C): void;

  controllerWillAttachSlice?(sliceView: SliceView, targetView: View | null, controller: C): void;

  controllerDidDetachSlice?(sliceView: SliceView, controller: C): void;

  controllerWillAttachRow?(rowView: RowView, targetView: View | null, controller: C): void;

  controllerDidDetachRow?(rowView: RowView, controller: C): void;

  controllerWillAttachLatest?(dataPointView: DataPointView<DateTime, number>, controller: C): void;

  controllerDidDetachLatest?(dataPointView: DataPointView<DateTime, number>, controller: C): void;

  controllerWillAttachCurrent?(dataPointView: DataPointView<DateTime, number>, controller: C): void;

  controllerDidDetachCurrent?(dataPointView: DataPointView<DateTime, number>, controller: C): void;
}

/** @public */
export class TimeSeriesController extends Controller {
  declare readonly observerType?: Class<TimeSeriesControllerObserver>;

  protected formatLatestCell(dataPointView: DataPointView<DateTime, number>): string | undefined {
    return this.formatCell(dataPointView);
  }

  protected formatCurrentCell(dataPointView: DataPointView<DateTime, number>): string | undefined {
    return this.formatCell(dataPointView);
  }

  protected formatCell(dataPointView: DataPointView<DateTime, number>): string | undefined {
    const value = dataPointView.y.getValue();
    return Format.prefix(value, 1);
  }

  protected formatSliceLabel(value: number, total: number): string | undefined {
    return void 0;
  }

  protected formatSliceLegend(value: number, total: number): string | null | undefined {
    return void 0;
  }

  protected applySliceStatus(sliceView: SliceView, dataPointView: DataPointView<DateTime, number>, timing: Timing | undefined): void {
    if (dataPointView.color.look !== null) {
      sliceView.sliceColor.setIntrinsic(dataPointView.color.look, timing);
      sliceView.moodModifier.setIntrinsic(dataPointView.moodModifier.value);
      if (sliceView.theme.value !== null && sliceView.mood.value !== null) {
        sliceView.applyTheme(sliceView.theme.value, sliceView.mood.value, timing);
      }
    } else if (dataPointView.color.value !== null) {
      sliceView.sliceColor.setIntrinsic(dataPointView.color.value, timing);
      sliceView.moodModifier.setIntrinsic(null);
    } else {
      sliceView.sliceColor.setIntrinsic(Look.accentColor, timing);
      sliceView.moodModifier.setIntrinsic(null);
      if (sliceView.theme.value !== null && sliceView.mood.value !== null) {
        sliceView.applyTheme(sliceView.theme.value, sliceView.mood.value, timing);
      }
    }
  }

  @Property({
    valueType: String,
    value: "",
    didSetValue(title: string): void {
      this.owner.callObservers("controllerDidSetTitle", title, this.owner);
    },
  })
  readonly title!: Property<this, string>;

  @Property({
    valueType: Boolean,
    value: false,
    didSetValue(focused: boolean): void {
      this.owner.callObservers("controllerDidSetFocused", focused, this.owner);
    },
  })
  readonly focused!: Property<this, boolean>;

  @Property({
    valueType: Boolean,
    value: false,
    didSetValue(defocused: boolean): void {
      const plotView = this.owner.plot.view;
      if (plotView !== null) {
        const timing = plotView.getLook(Look.timing);
        if (defocused && !this.owner.focused.value) {
          plotView.opacity.setIntrinsic(0.5, timing);
        } else {
          plotView.opacity.setIntrinsic(1, timing);
        }
      }
      const leafView = this.owner.leaf.view;
      if (leafView !== null) {
        const timing = leafView.getLook(Look.timing);
        if (defocused && !this.owner.focused.value) {
          leafView.style.opacity.setIntrinsic(0.5, timing);
        } else {
          leafView.style.opacity.setIntrinsic(1, timing);
        }
      }
    },
  })
  readonly defocused!: Property<this, boolean>;

  @Property({
    valueType: Boolean,
    value: false,
    didSetValue(pinned: boolean): void {
      this.owner.callObservers("controllerDidSetPinned", pinned, this.owner);
    },
    update(): void {
      const leafView = this.owner.leaf.view;
      const hovering = leafView !== null && leafView.hover.state.focused;
      const highlighted = leafView !== null && leafView.highlight.state.focused;
      const pinned = hovering || highlighted;
      this.setIntrinsic(pinned);
    },
  })
  readonly pinned!: Property<this, boolean> & {
    update(): void,
  };

  @ViewRef({
    viewType: SeriesPlotView,
    observes: true,
    init(): void {
      this.onPointerEnter = this.onPointerEnter.bind(this);
      this.onPointerLeave = this.onPointerLeave.bind(this);
    },
    willAttachView(plotView: SeriesPlotView<DateTime, number>, targetView: View | null): void {
      this.owner.callObservers("controllerWillAttachPlot", plotView, targetView, this.owner);
    },
    didAttachView(plotView: SeriesPlotView<DateTime, number>, targetView: View | null): void {
      this.owner.dataPoints.setViews(plotView.dataPoints.views);
      plotView.addEventListener("pointerenter", this.onPointerEnter);
      plotView.addEventListener("pointerleave", this.onPointerLeave);
    },
    willDetachView(plotView: SeriesPlotView<DateTime, number>): void {
      plotView.removeEventListener("pointerenter", this.onPointerEnter);
      plotView.removeEventListener("pointerleave", this.onPointerLeave);
      this.owner.dataPoints.deleteViews();
    },
    didDetachView(plotView: SeriesPlotView<DateTime, number>): void {
      this.owner.callObservers("controllerDidDetachPlot", plotView, this.owner);
    },
    viewWillAttachDataPoint(dataPointView: DataPointView<DateTime, number>, targetView: View | null): void {
      this.owner.dataPoints.addView(dataPointView, targetView);
    },
    viewDidDetachDataPoint(dataPointView: DataPointView<DateTime, number>): void {
      this.owner.dataPoints.removeView(dataPointView);
    },
    onPointerEnter(event: PointerEvent): void {
      this.owner.focused.setIntrinsic(true);
    },
    onPointerLeave(event: PointerEvent): void {
      this.owner.focused.setIntrinsic(false);
    },
    createView(): SeriesPlotView<DateTime, number> {
      return new LinePlotView<DateTime, number>().setIntrinsic({
        hitMode: "plot",
        strokeWidth: 1,
      });
    },
  })
  readonly plot!: ViewRef<this, SeriesPlotView<DateTime, number>> & Observes<SeriesPlotView<DateTime, number>> & {
    onPointerEnter(event: PointerEvent): void,
    onPointerLeave(event: PointerEvent): void,
  };

  @ViewSet({
    viewType: DataPointView,
    get parentView(): View | null {
      return this.owner.plot.attachView();
    },
  })
  readonly dataPoints!: ViewSet<this, DataPointView<DateTime, number>>;

  @ViewRef({
    viewType: DataPointView,
    get parentView(): View | null {
      return this.owner.plot.attachView();
    },
    willAttachView(dataPointView: DataPointView<DateTime, number>): void {
      this.owner.callObservers("controllerWillAttachLatest", dataPointView, this.owner);
    },
    didAttachView(dataPointView: DataPointView<DateTime, number>): void {
      this.owner.latestCell.setLatest(dataPointView);
      if (this.owner.calendar.getService().scrubTime.value === null) {
        this.owner.current.setView(dataPointView);
      }
    },
    didDetachView(dataPointView: DataPointView<DateTime, number>): void {
      dataPointView.remove();
      this.owner.callObservers("controllerDidDetachLatest", dataPointView, this.owner);
    },
  })
  readonly latest!: ViewRef<this, DataPointView<DateTime, number>>;

  @ViewRef({
    viewType: DataPointView,
    willAttachView(dataPointView: DataPointView<DateTime, number>): void {
      this.owner.callObservers("controllerWillAttachCurrent", dataPointView, this.owner);
    },
    didAttachView(dataPointView: DataPointView<DateTime, number>): void {
      this.owner.slice.setCurrent(dataPointView);
      this.owner.currentCell.setCurrent(dataPointView);
    },
    didDetachView(dataPointView: DataPointView<DateTime, number>): void {
      this.owner.callObservers("controllerDidDetachCurrent", dataPointView, this.owner);
    },
  })
  readonly current!: ViewRef<this, DataPointView<DateTime, number>>;

  @ViewRef({
    viewType: SliceView,
    observes: true,
    init(): void {
      this.onPointerEnter = this.onPointerEnter.bind(this);
      this.onPointerLeave = this.onPointerLeave.bind(this);
    },
    initView(sliceView: SliceView): void {
      this.updateValue(sliceView.value.value, sliceView.total.value);
    },
    willAttachView(sliceView: SliceView, targetView: View | null): void {
      this.owner.callObservers("controllerWillAttachSlice", sliceView, targetView, this.owner);
    },
    didAttachView(sliceView: SliceView, targetView: View | null): void {
      this.owner.sliceLabel.setView(sliceView.label.view);
      this.owner.sliceLegend.setView(sliceView.legend.view);
      sliceView.addEventListener("pointerenter", this.onPointerEnter);
      sliceView.addEventListener("pointerleave", this.onPointerLeave);
    },
    willDetachView(sliceView: SliceView): void {
      sliceView.removeEventListener("pointerenter", this.onPointerEnter);
      sliceView.removeEventListener("pointerleave", this.onPointerLeave);
      this.owner.sliceLabel.setView(null);
      this.owner.sliceLegend.setView(null);
    },
    didDetachView(sliceView: SliceView): void {
      this.owner.callObservers("controllerDidDetachSlice", sliceView, this.owner);
    },
    viewWillAttachLabel(labelView: GraphicsView): void {
      this.owner.sliceLabel.setView(labelView);
    },
    viewDidDetachLabel(labelView: GraphicsView): void {
      this.owner.sliceLabel.setView(null);
    },
    viewWillAttachLegend(legendView: GraphicsView): void {
      this.owner.sliceLegend.setView(legendView);
    },
    viewDidDetachLegend(legendView: GraphicsView): void {
      this.owner.sliceLegend.setView(null);
    },
    onPointerEnter(event: PointerEvent): void {
      this.owner.focused.setIntrinsic(true);
    },
    onPointerLeave(event: PointerEvent): void {
      this.owner.focused.setIntrinsic(false);
    },
    viewDidSetValue(value: number, sliceView: SliceView): void {
      this.updateValue(value, sliceView.total.value);
    },
    viewDidSetTotal(total: number, sliceView: SliceView): void {
      this.updateValue(sliceView.value.value, total);
    },
    updateValue(value: number, total: number): void {
      const label = this.owner.formatSliceLabel(value, total);
      if (label !== void 0) {
        this.owner.sliceLabel.setText(label);
      }
      const legend = this.owner.formatSliceLegend(value, total);
      if (legend !== void 0) {
        if (legend !== null) {
          this.owner.sliceLegend.setText(legend);
        } else {
          this.owner.sliceLegend.deleteView();
        }
      }
    },
    setCurrent(dataPointView: DataPointView<DateTime, number>): void {
      const sliceView = this.view;
      if (sliceView === null) {
        return;
      }
      let timing: Timing | undefined;
      if (sliceView.value.tweening) {
        timing = void 0;
      } else {
        timing = sliceView.getLookOr(Look.timing, void 0);
      }

      const sliceValue = dataPointView.y.getValue();
      sliceView.value.setIntrinsic(sliceValue, timing);

      this.owner.applySliceStatus(sliceView, dataPointView, timing);
    },
  })
  readonly slice!: ViewRef<this, SliceView> & Observes<SliceView> & {
    onPointerEnter(event: PointerEvent): void,
    onPointerLeave(event: PointerEvent): void,
    updateValue(value: number, total: number): void,
    setCurrent(dataPointView: DataPointView<DateTime, number>): void,
  };

  @ViewRef({
    viewType: GraphicsView,
    viewKey: "label",
    get parentView(): View | null {
      return this.owner.slice.attachView();
    },
    initView(labelView: GraphicsView): void {
      if (labelView instanceof TextRunView) {
        labelView.font.setInherits(false);
        labelView.font.setIntrinsic(Look.smallFont);
        labelView.textColor.setIntrinsic(Look.backgroundColor);
      }
    },
    setText(label: string | undefined): GraphicsView {
      return this.owner.slice.attachView().label.set(label);
    },
  })
  readonly sliceLabel!: ViewRef<this, GraphicsView> & {
    setText(label: string | undefined): GraphicsView,
  };

  @ViewRef({
    viewType: GraphicsView,
    viewKey: "legend",
    get parentView(): View | null {
      return this.owner.slice.attachView();
    },
    initView(legendView: GraphicsView): void {
      if (legendView instanceof TextRunView) {
        legendView.font.setInherits(false);
        legendView.font.setIntrinsic(Look.smallFont);
        legendView.textColor.setIntrinsic(Look.labelColor);
      }
    },
    setText(legend: string | undefined): GraphicsView {
      return this.owner.slice.attachView().legend.set(legend);
    },
  })
  readonly sliceLegend!: ViewRef<this, GraphicsView> & {
    setText(legend: string | undefined): GraphicsView,
  };

  @ViewRef({
    viewType: RowView,
    observes: true,
    willAttachView(rowView: RowView, targetView: View | null): void {
      this.owner.callObservers("controllerWillAttachRow", rowView, targetView, this.owner);
    },
    didAttachView(rowView: RowView, targetView: View | null): void {
      this.owner.leaf.setView(rowView.leaf.view, targetView);
    },
    willDetachView(rowView: RowView): void {
      this.owner.leaf.setView(null);
    },
    didDetachView(rowView: RowView): void {
      this.owner.callObservers("controllerDidDetachRow", rowView, this.owner);
    },
    viewWillAttachLeaf(leafView: LeafView, targetView: View | null): void {
      this.owner.leaf.setView(leafView, targetView);
    },
    viewDidDetachLeaf(leafView: LeafView): void {
      this.owner.leaf.setView(null);
    },
  })
  readonly row!: ViewRef<this, RowView> & Observes<RowView>;

  @ViewRef({
    viewType: LeafView,
    viewKey: true,
    observes: true,
    get parentView(): View | null {
      return this.owner.row.attachView();
    },
    didAttachView(leafView: LeafView, targetView: View | null): void {
      this.owner.cells.setViews(leafView.cells.views);
    },
    willDetachView(leafView: LeafView): void {
      this.owner.cells.deleteViews();
    },
    viewWillAttachCell(cellView: CellView, targetView: View | null): void {
      this.owner.cells.addView(cellView, targetView);
    },
    viewDidDetachCell(cellView: CellView): void {
      this.owner.cells.removeView(cellView);
    },
    viewDidEnter(leafView: LeafView): void {
      if (leafView.hovers.value) {
        this.owner.focused.setIntrinsic(true);
        this.owner.pinned.update();
      }
    },
    viewDidLeave(leafView: LeafView): void {
      if (leafView.hovers.value) {
        this.owner.focused.setIntrinsic(false);
        this.owner.pinned.update();
      }
    },
    viewWillHighlight(leafView: LeafView): void {
      this.owner.pinned.update();
    },
    viewWillUnhighlight(leafView: LeafView): void {
      this.owner.pinned.update();
    },
    viewDidPress(input: PositionGestureInput, event: Event | null, leafView: LeafView): void {
      leafView.highlight.toggle();
      this.owner.pinned.update();
    },
    createView(): LeafView {
      return (super.createView() as LeafView).setIntrinsic({
        //hovers: true,
      });
    },
  })
  readonly leaf!: ViewRef<this, LeafView> & Observes<LeafView>;

  @ViewSet({
    viewType: CellView,
    get parentView(): View | null {
      return this.owner.leaf.insertView();
    },
  })
  readonly cells!: ViewSet<this, CellView>;

  @ViewRef({
    viewType: CellView,
    viewKey: "name",
    get parentView(): View | null {
      return this.owner.leaf.insertView();
    },
    createView(): CellView {
      return TextCellView.create();
    },
  })
  readonly nameCell!: ViewRef<this, CellView>;

  @ViewRef({
    viewType: CellView,
    viewKey: "latest",
    get parentView(): View | null {
      return this.owner.leaf.insertView();
    },
    initView(cellView: CellView): void {
      const dataPointView = this.owner.latest.view;
      if (dataPointView !== null) {
        this.setLatest(dataPointView);
      }
    },
    setLatest(dataPointView: DataPointView<DateTime, number>): void {
      const cellView = this.view;
      if (cellView instanceof TextCellView) {
        const content = this.owner.formatLatestCell(dataPointView);
        if (content !== void 0) {
          cellView.content.set(content);
        }
      }
    },
    createView(): CellView {
      return TextCellView.create();
    },
  })
  readonly latestCell!: ViewRef<this, CellView> & {
    setLatest(dataPointView: DataPointView<DateTime, number>): void,
  };

  @ViewRef({
    viewType: CellView,
    viewKey: "current",
    get parentView(): View | null {
      return this.owner.leaf.insertView();
    },
    initView(cellView: CellView): void {
      const dataPointView = this.owner.latest.view;
      if (dataPointView !== null) {
        this.setCurrent(dataPointView);
      }
    },
    setCurrent(dataPointView: DataPointView<DateTime, number>): void {
      const cellView = this.view;
      if (cellView instanceof TextCellView) {
        const content = this.owner.formatCurrentCell(dataPointView);
        if (content !== void 0) {
          cellView.content.set(content);
        }
      }
    },
    createView(): CellView {
      return TextCellView.create();
    },
  })
  readonly currentCell!: ViewRef<this, CellView> & {
    setCurrent(dataPointView: DataPointView<DateTime, number>): void,
  };

  @Provider({
    serviceType: CalendarService,
    observes: true,
    serviceDidSetScrubTime(scrubTime: DateTime | null): void {
      let dataPointView: DataPointView<DateTime, number> | null | undefined = null;
      if (scrubTime !== null) {
        const plotView = this.owner.plot.view;
        if (plotView !== null) {
          dataPointView = plotView.dataPointViews.get(scrubTime);
          if (dataPointView === void 0) {
            dataPointView = plotView.dataPointViews.previousValue(scrubTime);
            if (dataPointView === void 0) {
              dataPointView = null;
            }
          }
        }
      }
      this.setReferenceDataPoint(dataPointView);
    },
    setReferenceDataPoint(dataPointView: DataPointView<DateTime, number> | null): void {
      if (dataPointView !== null) {
        this.owner.current.setView(dataPointView);
      } else {
        this.owner.current.setView(this.owner.latest.view);
      }
    },
  })
  readonly calendar!: Provider<this, CalendarService> & Observes<CalendarService> & {
    setReferenceDataPoint(dataPointView: DataPointView<DateTime, number> | null): void,
  };
}
