// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import type {TimingLike} from "@swim/util";
import type {Observes} from "@swim/util";
import {Property} from "@swim/component";
import {Provider} from "@swim/component";
import {UriPath} from "@swim/uri";
import type {Trait} from "@swim/model";
import {TraitRef} from "@swim/model";
import {SelectionService} from "@swim/model";
import {Look} from "@swim/theme";
import {Feel} from "@swim/theme";
import {ViewRef} from "@swim/view";
import type {PositionGestureInput} from "@swim/view";
import type {ControllerFlags} from "@swim/controller";
import {Controller} from "@swim/controller";
import {ControllerRef} from "@swim/controller";
import {TraitViewRef} from "@swim/controller";
import {TraitViewControllerRef} from "@swim/controller";
import {TraitViewControllerSet} from "@swim/controller";
import type {HistoryStateInit} from "@swim/controller";
import type {HistoryState} from "@swim/controller";
import {HistoryService} from "@swim/controller";
import type {DrawerView} from "@swim/window";
import type {ToolController} from "@swim/toolbar";
import type {BarView} from "@swim/toolbar";
import {BarController} from "@swim/toolbar";
import type {SheetView} from "@swim/sheet";
import {SheetController} from "@swim/sheet";
import {NavBarController} from "@swim/sheet";
import type {StackView} from "@swim/sheet";
import {AppBarController} from "@swim/sheet";
import type {FolioStyle} from "@swim/sheet";
import type {FolioView} from "@swim/sheet";
import type {FolioControllerObserver} from "@swim/sheet";
import {FolioController} from "@swim/sheet";
import type {TabBarController} from "@swim/sheet";
import type {BinderView} from "@swim/sheet";
import {Status} from "@nstream/domain";
import {EntityTrait} from "@nstream/domain";
import type {RelationTrait} from "@nstream/domain";
import {AspectBinderController} from "./AspectBinderController";
import type {EntityRowController} from "./EntityRowController";
import {EntityNavController} from "./EntityNavController";

/** @public */
export interface EntityFolioControllerObserver<C extends EntityFolioController = EntityFolioController> extends FolioControllerObserver<C> {
}

/** @public */
export class EntityFolioController extends FolioController {
  declare readonly observerType?: Class<EntityFolioControllerObserver>;

  @TraitRef({
    traitType: EntityTrait,
    didAttachTrait(entityTrait: EntityTrait): void {
      if (this.owner.mounted) {
        this.owner.requireUpdate(Controller.NeedsResolve);
      }
    },
  })
  readonly rootEntity!: TraitRef<this, EntityTrait>;

  @ViewRef({
    extends: true,
    initView(drawerView: DrawerView): void {
      super.initView(drawerView);
      drawerView.style.backgroundColor.setIntrinsic(Look.backgroundColor);
    },
    updateFullBleed(fullBleed: boolean, drawerView: DrawerView): void {
      super.updateFullBleed(fullBleed, drawerView);
      drawerView.style.backdropFilter.setIntrinsic(fullBleed ? "blur(2px)" : "none");
      drawerView.modifyMood(Feel.default, [[Feel.translucent, fullBleed ? 1 : 0]], false);
    },
  })
  override readonly drawer!: ViewRef<this, DrawerView> & FolioController["drawer"];

  @TraitViewRef({
    extends: true,
    updateFolioStyle(folioStyle: FolioStyle | undefined, stackView: StackView): void {
      const fullBleed = this.owner.fullBleed.value;
      this.owner.backAlign.setIntrinsic(fullBleed && folioStyle === "unstacked" ? -1 : -(1 / 3));
    },
    updateFullBleed(fullBleed: boolean, stackView: StackView): void {
      super.updateFullBleed(fullBleed, stackView);
      const folioStyle = this.owner.folioStyle.value;
      this.owner.backAlign.setIntrinsic(fullBleed && folioStyle === "unstacked" ? -1 : -(1 / 3));
      this.owner.navBar.updateFrontScroll(this.owner.front.view);
    },
  })
  override readonly stack!: TraitViewRef<this, Trait, StackView> & FolioController["stack"];

  @TraitViewControllerSet({
    extends: true,
    updateFolioStyle(folioStyle: FolioStyle | undefined, sheetView: SheetView, sheetController: SheetController): void {
      super.updateFolioStyle(folioStyle, sheetView, sheetController);
      const fullBleed = this.owner.fullBleed.value;
      sheetView.modifyMood(Feel.default, [[Feel.transparent, fullBleed && folioStyle === "unstacked" ? 1 : 0]], false);
    },
    updateFullBleed(fullBleed: boolean, sheetView: SheetView, sheetController: SheetController): void {
      super.updateFullBleed(fullBleed, sheetView, sheetController);
      const folioStyle = this.owner.folioStyle.value;
      sheetView.modifyMood(Feel.default, [[Feel.transparent, fullBleed && folioStyle === "unstacked" ? 1 : 0]], false);
    },
  })
  override readonly sheets!: TraitViewControllerSet<this, Trait, SheetView, SheetController> & FolioController["sheets"];

  @TraitViewControllerRef({
    extends: true,
    didAttachController(frontController: SheetController, targetController: Controller | null): void {
      super.didAttachController(frontController, targetController);
      if (!(frontController instanceof EntityNavController)) {
        return;
      }
      const frontEntityTrait = frontController.entity.trait;
      this.owner.frontEntity.setTrait(frontEntityTrait);
      this.owner.frontNav.setController(frontController);
    },
    willDetachController(frontController: SheetController): void {
      super.willDetachController(frontController);
      if (frontController instanceof EntityNavController) {
        this.owner.frontNav.setController(null);
        // Don't unset frontEntity trait.
      }
    },
    controllerWillAttachTitle(titleController: ToolController, frontController: SheetController): void {
      const titleView = titleController.tool.view;
      if (titleView !== null) {
        titleView.style.cursor.setIntrinsic("pointer");
      }
    },
  })
  override readonly front!: TraitViewControllerRef<this, Trait, SheetView, SheetController> & FolioController["front"];

  @ControllerRef({
    controllerType: EntityNavController,
    observes: true,
    controllerDidPressEntityRow(input: PositionGestureInput, event: Event | null, entityRowController: EntityRowController): void {
      if (input.defaultPrevented) {
        return;
      }
      const entityTrait = entityRowController.entity.trait;
      let entityPath: UriPath | null;
      if (entityTrait === null || (entityPath = entityTrait.path.value) === null) {
        return;
      }
      const historyService = this.owner.history.getService();
      const historyState = historyService.historyState;

      let fragment: string | undefined;
      if (entityPath.isAbsolute() && !entityPath.tail().isEmpty()) {
        fragment = entityPath.appendedSlash().toString();
      }

      const parameters: HistoryStateInit["parameters"] = {};
      if (this.owner.folioStyle.value === "stacked") {
        let aspectId = historyState.parameters.aspect;
        if (aspectId === void 0 || aspectId.length === 0) {
          aspectId = historyState.environment.aspect;
        }
        if (aspectId === void 0 || aspectId.length === 0) {
          const aspectTrait = entityTrait.aspect.trait;
          aspectId = aspectTrait !== null ? aspectTrait.id.value : void 0;
        }
        parameters.aspect = aspectId;
      }

      if (fragment !== historyState.fragment || parameters.aspect !== historyState.parameters.aspect) {
        historyService.pushHistory({
          fragment,
          parameters,
        });
      }
    },
    controllerDidLongPressEntityRow(input: PositionGestureInput, entityRowController: EntityRowController): void {
      if (input.defaultPrevented) {
        return;
      }
      input.preventDefault();
    },
    controllerDidPressRelatedEntityRow(input: PositionGestureInput, event: Event | null, entityRowController: EntityRowController): void {
      if (input.defaultPrevented) {
        return;
      }
      const entityTrait = entityRowController.entity.trait;
      let entityPath: UriPath | null;
      if (entityTrait === null || (entityPath = entityTrait.path.value) === null) {
        return;
      }
      const historyService = this.owner.history.getService();
      const historyState = historyService.historyState;

      const fragment = entityPath.toString();

      const parameters: HistoryStateInit["parameters"] = {};
      if (this.owner.folioStyle.value === "stacked") {
        let aspectId = historyState.parameters.aspect;
        if (aspectId === void 0 || aspectId.length === 0) {
          aspectId = historyState.environment.aspect;
        }
        if (aspectId === void 0 || aspectId.length === 0) {
          const aspectTrait = entityTrait.aspect.trait;
          aspectId = aspectTrait !== null ? aspectTrait.id.value : void 0;
        }
        parameters.aspect = aspectId;
      }

      if (fragment !== historyState.fragment || parameters.aspect !== historyState.parameters.aspect) {
        historyService.pushHistory({
          fragment,
          parameters,
        });
      }
    },
    controllerDidLongPressRelatedEntityRow(input: PositionGestureInput, entityRowController: EntityRowController): void {
      if (input.defaultPrevented) {
        return;
      }
      input.preventDefault();
    },
    controllerDidPressRelatedEntityMore(input: PositionGestureInput, event: Event | null, entityRowController: EntityRowController): void {
      if (input.defaultPrevented) {
        return;
      }
      input.preventDefault();
      const entityTrait = entityRowController.entity.trait;
      let entityPath: UriPath | null;
      if (entityTrait === null || (entityPath = entityTrait.path.value) === null) {
        return;
      }
      const historyService = this.owner.history.getService();
      const historyState = historyService.historyState;

      let fragment: string | undefined;
      if (entityPath.isAbsolute() && !entityPath.tail().isEmpty()) {
        fragment = entityPath.appendedSlash().toString();
      }

      if (fragment !== historyState.fragment) {
        historyService.pushHistory({fragment});
      }
    },
  })
  readonly frontNav!: ControllerRef<this, EntityNavController> & Observes<EntityNavController>;

  @TraitRef({
    traitType: EntityTrait,
    observes: true,
    initTrait(entityTrait: EntityTrait): void {
      this.owner.frontStatus.setIntrinsic(entityTrait.status.value);
    },
    traitDidSetStatus(status: Status): void {
      this.owner.frontStatus.setIntrinsic(status);
    },
  })
  readonly frontEntity!: TraitRef<this, EntityTrait> & Observes<EntityTrait>;

  @Property({
    valueType: Status,
    value: Status.unknown(),
    didSetValue(status: Status): void {
      this.owner.navBar.updateStatus(status);
    },
  })
  readonly frontStatus!: Property<this, Status>;

  @TraitViewRef({
    extends: true,
    initView(folioView: FolioView): void {
      super.initView(folioView);
      const drawerView = folioView.drawer.attachView();
      drawerView.expandedWidth.set(280);
      drawerView.modifyTheme(Feel.default, [[Feel.raised, 1]]);
    },
    viewDidSetFolioStyle(folioStyle: FolioStyle | undefined, folioView: FolioView): void {
      super.viewDidSetFolioStyle(folioStyle, folioView);
      this.owner.requireUpdate(Controller.NeedsResolve);
    },
  })
  override readonly folio!: TraitViewRef<this, Trait, FolioView> & FolioController["folio"];

  @TraitViewControllerRef({
    extends: true,
    initController(navBarController: BarController): void {
      super.initController(navBarController);
      if (!(navBarController instanceof NavBarController)) {
        return;
      }
      const closeButtonController = navBarController.closeButton.insertController();
      closeButtonController.tool.setView(null);
      navBarController.backButton.insertController();
      navBarController.searchButton.insertController();
      navBarController.searchInput.insertController();
      navBarController.cancelSearch.insertController();
    },
    attachNavBarView(navBarView: BarView, navBarController: BarController): void {
      super.attachNavBarView(navBarView, navBarController);
      navBarView.modifyMood(Feel.default, [[Feel.translucent, 0], [Feel.transparent, 1]]);
      navBarView.style.backgroundColor.setIntrinsic(Look.backgroundColor);
      navBarView.barHeight.setIntrinsic(48);
      this.updateFrontScroll(this.owner.front.view);
    },
    updateFolioStyle(folioStyle: FolioStyle | undefined, navBarController: BarController): void {
      super.updateFolioStyle(folioStyle, navBarController);
      if (!(navBarController instanceof NavBarController)) {
        return;
      }
      navBarController.showBackTitle.setIntrinsic(folioStyle === "stacked");
    },
    frontViewDidScroll(frontView: SheetView, navBarController: BarController): void {
      super.frontViewDidScroll(frontView, navBarController);
      this.updateFrontScroll(frontView);
    },
    updateFrontScroll(frontView: SheetView | null): void {
      const navBarView = this.view;
      if (navBarView === null) {
        return;
      }
      const scrollTop = frontView !== null ? frontView.node.scrollTop : 0;
      const folioStyle = this.owner.folioStyle.value;
      const fullBleed = this.owner.fullBleed.value;
      const transparent = scrollTop === 0 && (!fullBleed || folioStyle !== "stacked");
      const timing = navBarView.getLook(Look.timing);
      navBarView.style.backgroundColor.setIntrinsic(Look.backgroundColor, timing);
      navBarView.style.backdropFilter.setIntrinsic(transparent ? "none" : "blur(2px)");
      navBarView.modifyMood(Feel.default, [[Feel.translucent, 1],
                                           [Feel.transparent, transparent ? 1 : 0]], timing);
    },
    updateStatus(status: Status): void {
      const navBarView = this.view;
      if (navBarView !== null) {
        navBarView.modifyMood(Feel.default, status.moodModifier);
      }
    },
  })
  override readonly navBar!: TraitViewControllerRef<this, Trait, BarView, BarController> & FolioController["navBar"] & {
    updateFrontScroll(frontView: SheetView | null): void,
    updateStatus(status: Status): void,
  };

  @TraitViewControllerRef({
    extends: true,
    initController(appBarController: BarController): void {
      super.initController(appBarController);
      if (appBarController instanceof AppBarController) {
        appBarController.menuButton.insertController();
      }
    },
    attachAppBarView(appBarView: BarView, appBarController: BarController): void {
      super.attachAppBarView(appBarView, appBarController);
      appBarView.modifyMood(Feel.default, [[Feel.translucent, 1], [Feel.transparent, 1]]);
      appBarView.style.backgroundColor.setIntrinsic(Look.backgroundColor);
      appBarView.barHeight.setIntrinsic(48);
    },
    coverViewDidScroll(coverView: SheetView, appBarController: BarController): void {
      super.coverViewDidScroll(coverView, appBarController);
      const appBarView = appBarController.bar.view;
      if (appBarView === null) {
        return;
      } else if (coverView.node.scrollTop === 0) {
        appBarView.style.backdropFilter.setIntrinsic("none");
        appBarView.modifyMood(Feel.default, [[Feel.transparent, 1]]);
      } else {
        appBarView.style.backdropFilter.setIntrinsic("blur(2px)");
        appBarView.modifyMood(Feel.default, [[Feel.transparent, 0]]);
      }
    },
  })
  override readonly appBar!: TraitViewControllerRef<this, Trait, BarView, BarController> & FolioController["appBar"];

  @TraitViewControllerRef({
    extends: true,
    attachCoverTrait(coverTrait: Trait, coverController: SheetController): void {
      super.attachCoverTrait(coverTrait, coverController);
      if (coverTrait instanceof EntityTrait) {
        this.owner.coverEntity.setTrait(coverTrait);
      }
    },
    detachCoverTrait(coverTrait: Trait, coverController: SheetController): void {
      if (coverTrait instanceof EntityTrait) {
        this.owner.coverEntity.setTrait(null);
      }
      super.detachCoverTrait(coverTrait, coverController);
    },
    controllerWillPresentSheetView(sheetView: SheetView, coverController: SheetController): void {
      const coverTrait = coverController.sheet.trait;
      if (coverTrait instanceof EntityTrait) {
        this.owner.coverEntity.setTrait(coverTrait);
      }
    },
    controllerWillDismissSheetView(sheetView: SheetView, coverController: SheetController): void {
      const coverTrait = coverController.sheet.trait;
      if (coverTrait instanceof EntityTrait) {
        this.owner.coverEntity.setTrait(null);
      }
    },
  })
  override readonly cover!: TraitViewControllerRef<this, Trait, SheetView, SheetController> & FolioController["cover"];

  @TraitRef({
    traitType: EntityTrait,
  })
  readonly coverEntity!: TraitRef<this, EntityTrait>;

  @TraitViewControllerRef({
    controllerType: BarController,
    observes: true,
    getTraitViewRef(tabBarController: BarController): TraitViewRef<unknown, Trait, BarView> {
      return tabBarController.bar;
    },
    didAttachController(tabBarController: BarController): void {
      const tabBarView = tabBarController.bar.view;
      if (tabBarView !== null) {
        this.attachTabBarView(tabBarView, tabBarController);
      }
    },
    willDetachController(tabBarController: BarController): void {
      const tabBarView = tabBarController.bar.view;
      if (tabBarView !== null) {
        this.detachTabBarView(tabBarView, tabBarController);
      }
    },
    controllerWillAttachBarView(tabBarView: BarView, tabBarController: BarController): void {
      this.attachTabBarView(tabBarView, tabBarController);
    },
    controllerDidDetachBarView(tabBarView: BarView, tabBarController: BarController): void {
      this.detachTabBarView(tabBarView, tabBarController);
    },
    attachTabBarView(tabBarView: BarView, tabBarController: BarController): void {
      // hook
    },
    detachTabBarView(tabBarView: BarView, tabBarController: BarController): void {
      // hook
    },
    controllerDidPressTabHandle(input: PositionGestureInput, event: Event | null, tabController: SheetController): void {
      // hook
    },
    controllerDidLongPressTabHandle(input: PositionGestureInput, tabController: SheetController): void {
      // hook
    },
  })
  readonly tabBar!: TraitViewControllerRef<this, Trait, BarView, BarController> & Observes<TabBarController> & {
    attachTabBarView(tabBarView: BarView, tabBarController: BarController): void,
    detachTabBarView(tabBarView: BarView, tabBarController: BarController): void,
  };

  @TraitViewControllerRef({
    controllerType: AspectBinderController,
    observes: true,
    getTraitViewRef(binderController: AspectBinderController): TraitViewRef<unknown, Trait, BinderView> {
      return binderController.binder;
    },
    initController(binderController: AspectBinderController): void {
      binderController.binder.attachView();
      binderController.tabBar.insertController();
    },
    didAttachController(binderController: AspectBinderController): void {
      const binderTrait = binderController.binder.trait;
      if (binderTrait !== null) {
        this.attachBinderTrait(binderTrait, binderController);
      }
      const binderView = binderController.binder.view;
      if (binderView !== null) {
        this.attachBinderView(binderView, binderController);
      }
      this.owner.tabBar.bindInlet(binderController.tabBar);
      this.owner.active.bindInlet(binderController.active);
    },
    willDetachController(binderController: AspectBinderController): void {
      this.owner.active.unbindInlet(binderController.active);
      this.owner.tabBar.unbindInlet(binderController.tabBar);
      const binderView = binderController.binder.view;
      if (binderView !== null) {
        this.detachBinderView(binderView, binderController);
      }
      const binderTrait = binderController.binder.trait;
      if (binderTrait !== null) {
        this.detachBinderTrait(binderTrait, binderController);
      }
    },
    controllerWillAttachBinderTrait(binderTrait: Trait, binderController: AspectBinderController): void {
      this.attachBinderTrait(binderTrait, binderController);
    },
    controllerDidDetachBinderTrait(binderTrait: Trait, binderController: AspectBinderController): void {
      this.detachBinderTrait(binderTrait, binderController);
    },
    attachBinderTrait(binderTrait: Trait, binderController: AspectBinderController): void {
      // hook
    },
    detachBinderTrait(binderTrait: Trait, binderController: AspectBinderController): void {
      // hook
    },
    controllerWillAttachBinderView(binderView: BinderView, binderController: AspectBinderController): void {
      this.attachBinderView(binderView, binderController);
    },
    controllerDidDetachBinderView(binderView: BinderView, binderController: AspectBinderController): void {
      this.detachBinderView(binderView, binderController);
    },
    attachBinderView(binderView: BinderView, binderController: AspectBinderController): void {
      // hook
    },
    detachBinderView(binderView: BinderView, binderController: AspectBinderController): void {
      this.detachController();
    },
  })
  readonly binder!: TraitViewControllerRef<this, Trait, BinderView, AspectBinderController> & Observes<AspectBinderController> & {
    attachBinderTrait(binderTrait: Trait, binderController: AspectBinderController): void;
    detachBinderTrait(binderTrait: Trait, binderController: AspectBinderController): void;
    attachBinderView(binderView: BinderView, binderController: AspectBinderController): void;
    detachBinderView(binderView: BinderView, binderController: AspectBinderController): void;
  };

  @TraitViewControllerRef({
    controllerType: SheetController,
    observes: true,
    getTraitViewRef(activeController: SheetController): TraitViewRef<unknown, Trait, SheetView> {
      return activeController.sheet;
    },
    didAttachController(activeController: SheetController): void {
      const activeTrait = activeController.sheet.trait;
      if (activeTrait !== null) {
        this.attachActiveTrait(activeTrait, activeController);
      }
      const activeView = activeController.sheet.attachView();
      this.attachActiveView(activeView, activeController);
    },
    willDetachController(activeController: SheetController): void {
      const activeView = activeController.sheet.view;
      if (activeView !== null) {
        this.detachActiveView(activeView, activeController);
      }
      const activeTrait = activeController.sheet.trait;
      if (activeTrait !== null) {
        this.detachActiveTrait(activeTrait, activeController);
      }
    },
    controllerWillAttachSheetTrait(activeTrait: Trait, activeController: SheetController): void {
      this.attachActiveTrait(activeTrait, activeController);
    },
    controllerDidDetachSheetTrait(activeTrait: Trait, activeController: SheetController): void {
      this.detachActiveTrait(activeTrait, activeController);
    },
    attachActiveTrait(activeTrait: Trait, activeController: SheetController): void {
      // hook
    },
    detachActiveTrait(activeTrait: Trait, activeController: SheetController): void {
      // hook
    },
    controllerWillAttachSheetView(activeView: SheetView, activeController: SheetController): void {
      this.attachActiveView(activeView, activeController);
    },
    controllerDidDetachSheetView(activeView: SheetView, activeController: SheetController): void {
      this.detachActiveView(activeView, activeController);
    },
    attachActiveView(activeView: SheetView, activeController: SheetController): void {
      // hook
    },
    detachActiveView(activeView: SheetView, activeController: SheetController): void {
      // hook
    },
  })
  readonly active!: TraitViewControllerRef<this, Trait, SheetView, SheetController> & Observes<SheetController> & {
    attachActiveTrait(activeTrait: Trait, activeController: SheetController): void,
    detachActiveTrait(activeTrait: Trait, activeController: SheetController): void,
    attachActiveView(activeView: SheetView, activeController: SheetController): void,
    detachActiveView(activeView: SheetView, activeController: SheetController): void,
  };

  @Provider({serviceType: SelectionService})
  readonly selection!: Provider<this, SelectionService>;

  protected createEntityNav(entityTrait: EntityTrait): SheetController {
    let navController = entityTrait.createNavController();
    if (navController === null) {
      navController = new EntityNavController();
      (navController as EntityNavController).entity.setTrait(entityTrait);
    }
    return navController;
  }

  showEntityNav(entityTrait: EntityTrait, timing?: TimingLike | boolean | null): void {
    const navController = this.createEntityNav(entityTrait);
    navController.sheet.setTrait(entityTrait);
    const navView = navController.sheet.attachView();
    navView.dismiss();
    this.appendChild(navController);
    this.sheets.addController(navController);
    navView.present(timing);
  }

  showEntityBinder(entityTrait: EntityTrait, timing?: TimingLike | boolean | null): void {
    const binderController = this.binder.insertController();
    binderController.sheet.setTrait(entityTrait);
    binderController.sheet.attachView().dismiss();
    this.cover.setController(binderController);
    this.cover.present(timing);
  }

  protected override didPressBackButton(input: PositionGestureInput, event: Event | null): void {
    this.callObservers("controllerDidPressBackButton", input, event, this);
    if (input.defaultPrevented) {
      return;
    }

    const frontTrait = this.frontEntity.trait;
    let frontPath: UriPath | null;
    if (frontTrait === null || (frontPath = frontTrait.path.value) === null) {
      // Can't go back when uninitialized.
      return;
    }
    const coverTrait = this.coverEntity.trait;
    const coverPath = coverTrait !== null ? coverTrait.path.value : null;

    const historyService = this.history.getService();
    const historyState = historyService.historyState;

    let fragment: string | undefined;
    const parameters: HistoryStateInit["parameters"] = {};
    const environment: HistoryStateInit["parameters"] = {};
    if (this.folioStyle.value === "unstacked") {
      // `/relation/entity/` => `/relation/entity`
      fragment = frontPath.toString();
    } else if (coverPath === null || frontPath.equals(UriPath.slash())) {
      // `/relation/entity/` => `/`
      let parentPath = frontPath.parent().parent();
      if (parentPath.isEmpty()) {
        parentPath = UriPath.slash();
      }
      fragment = parentPath.toString();
      parameters.aspect = void 0;
    } else {
      fragment = frontPath.appendedSlash().toString();
      parameters.aspect = void 0;
    }
    if (historyState.parameters.aspect !== void 0) {
      environment.aspect = historyState.parameters.aspect;
    }

    historyService.pushHistory({
      fragment,
      parameters,
      environment,
    });
  }

  protected updateNavigationState(): void {
    let targetTrait = this.rootEntity.trait;
    if (targetTrait === null) {
      return;
    } else if (this.root.controller === null) {
      // Inject the root navigation controller.
      this.showEntityNav(targetTrait);
    }

    let targetController = this.root.controller;
    if (!(targetController instanceof EntityNavController)) {
      return;
    }

    const historyState = this.history.getService().historyState;
    let targetPath: UriPath;
    try {
      targetPath = historyState.fragment !== void 0
                 ? UriPath.parse(historyState.fragment)
                 : UriPath.empty();
    } catch (error) {
      return; // swallow parse errors
    }

    //let unmatchedController = targetController.forward.controller;
    let unmatchedPath = targetPath;
    if (unmatchedPath.isAbsolute()) {
      unmatchedPath = unmatchedPath.tail();
    }

    // Match the current navigation stack against the target path.
    while (unmatchedPath.isSegment()) {
      let subpath = unmatchedPath;

      // Try to match a relation segment.
      const relationId = subpath.head();
      const relationTrait: RelationTrait | null = targetTrait.relations.get(relationId);
      if (relationTrait === null) {
        // Unknown relationId.
        break;
      }
      subpath = subpath.tail();
      if (subpath.isAbsolute()) {
        subpath = subpath.tail();
      }

      // Try to match an entity segment.
      const entityId = subpath.head();
      const entityTrait: EntityTrait | null = relationTrait.entities.get(entityId);
      if (entityTrait === null) {
        // Unknown entityId.
        break;
      }
      subpath = subpath.tail();

      // Get the entity of the forward navigation sheet.
      const forwardController: SheetController | null = targetController.forward.controller;
      if (!(forwardController instanceof EntityNavController)) {
        // Reached the end of the existing navigation stack.
        break;
      }
      const forwardTrait = forwardController.entity.trait;
      if (forwardTrait === null) {
        // Uninitialized entity.
        return;
      }

      // Compare the resolved entity path to the forward navigation sheet.
      if (entityTrait !== forwardTrait) {
        // Forward navigation sheet represents a different entity.
        break;
      }

      if (!subpath.isAbsolute()) {
        break;
      }
      subpath = subpath.tail();
      targetTrait = forwardTrait;
      targetController = forwardController;
      unmatchedPath = subpath;
    }

    // Dismiss old navigation sheets.
    let unmatchedController = targetController.forward.controller;
    if (unmatchedController !== null) {
      // First traverse forward to the top of the navigation stack.
      do {
        if (unmatchedController.forward.controller === null) {
          break;
        }
        unmatchedController = unmatchedController.forward.controller;
      } while (unmatchedController !== null);
      // Then traverse backwards popping unmatched navigation sheets off the stack.
      while (unmatchedController !== null && unmatchedController !== targetController) {
        const backController: SheetController | null = unmatchedController.back.controller;
        const sheetView = unmatchedController.sheet.view;
        if (sheetView !== null) {
          sheetView.dismiss();
        }
        unmatchedController = backController;
      }
    }

    // Present new navigation sheets.
    while (unmatchedPath.isSegment()) {
      const relationId = unmatchedPath.head();
      const relationTrait: RelationTrait | null = targetTrait.relations.get(relationId);
      if (relationTrait === null) {
        return;
      }
      unmatchedPath = unmatchedPath.tail();
      if (!unmatchedPath.isAbsolute()) {
        return;
      }
      unmatchedPath = unmatchedPath.tail();

      const entityId = unmatchedPath.head();
      const entityTrait: EntityTrait | null = relationTrait.entities.getOrInsert(entityId);
      if (entityTrait === null) {
        return;
      }
      unmatchedPath = unmatchedPath.tail();

      if (unmatchedPath.isAbsolute()) {
        this.showEntityNav(entityTrait);
        unmatchedPath = unmatchedPath.tail();
      }

      targetTrait = entityTrait;
    }

    if (this.folioStyle.value === "unstacked"
        || historyState.parameters.aspect !== void 0
        || targetPath.isEmpty() || !targetPath.foot().isAbsolute()) {
      this.showEntityBinder(targetTrait);
      this.selection.getService().select(targetTrait.model!);
    } else {
      this.selection.getService().unselectAll();
    }
  }

  @Provider({
    serviceType: HistoryService,
    observes: true,
    updateHistory(newHistoryState: HistoryState, oldHistoryState: HistoryState | null): void {
      if (oldHistoryState === null
          || newHistoryState.fragment !== oldHistoryState.fragment
          || newHistoryState.parameters.aspect !== oldHistoryState.parameters.aspect) {
        this.owner.requireUpdate(Controller.NeedsResolve);
      }
    },
    serviceDidPushHistory(newHistoryState: HistoryState, oldHistoryState: HistoryState): void {
      this.updateHistory(newHistoryState, oldHistoryState);
    },
    serviceDidPopHistory(newHistoryState: HistoryState, oldHistoryState: HistoryState): void {
      this.updateHistory(newHistoryState, oldHistoryState);
    },
  })
  readonly history!: Provider<this, HistoryService> & Observes<HistoryService> & {
    updateHistory(newHistoryState: HistoryState, oldHistoryState: HistoryState | null): void;
  };

  protected override onResolve(): void {
    super.onResolve();
    this.updateNavigationState();
  }

  static override MountFlags: ControllerFlags = FolioController.MountFlags | Controller.NeedsResolve;
}
