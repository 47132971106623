// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import type {Observes} from "@swim/util";
import {Property} from "@swim/component";
import {UriPath} from "@swim/uri";
import {UriPathBuilder} from "@swim/uri";
import {Model} from "@swim/model";
import type {TraitObserver} from "@swim/model";
import {Trait} from "@swim/model";
import {TraitModelSet} from "@swim/model";
import {Status} from "./Status";
import {EntityTrait} from "./EntityTrait";

/** @public */
export interface RelationTraitObserver<E extends EntityTrait = EntityTrait, T extends RelationTrait<E> = RelationTrait<E>> extends TraitObserver<T> {
  traitDidSetPath?(path: UriPath | null, trait: T): void;

  traitDidSetTitle?(title: string, trait: T): void;

  traitDidSetStatus?(status: Status, trait: T): void;

  traitDidSetHidden?(hidden: boolean, trait: T): void;

  traitDidSetDisclosed?(disclosed: boolean, trait: T): void;

  traitWillAttachEntity?(entityTrait: EntityTrait, trait: T): void;

  traitDidDetachEntity?(entityTrait: EntityTrait, trait: T): void;

  traitDidReinsertEntity?(entityTrait: EntityTrait, targetTrait: EntityTrait | null, trait: T): void;
}

/** @public */
export class RelationTrait<E extends EntityTrait = EntityTrait> extends Trait {
  declare readonly observerType?: Class<RelationTraitObserver<E>>;

  @Property({valueType: String})
  readonly id!: Property<this, string | undefined>;

  @Property({
    valueType: UriPath,
    value: null,
    inherits: true,
    parentType: EntityTrait,
    deriveValue(basePath: UriPath | null): UriPath | null {
      const id = this.owner.id.value;
      if (id === void 0) {
        return null;
      }
      const pathBuilder = new UriPathBuilder();
      if (basePath !== null) {
        pathBuilder.addPath(basePath);
      }
      pathBuilder.addSegment(id);
      return pathBuilder.build();
    },
    didSetValue(newPath: UriPath | null, oldPath: UriPath | null): void {
      this.owner.callObservers("traitDidSetPath", newPath, this.owner);
    },
  })
  readonly path!: Property<this, UriPath | null>;

  @Property({
    valueType: String,
    value: "",
    didSetValue(newTitle: string, oldTitle: string): void {
      this.owner.callObservers("traitDidSetTitle", newTitle, this.owner);
    },
    equalValues(newTitle: string, oldTitle: string): boolean {
      return newTitle === oldTitle;
    },
  })
  readonly title!: Property<this, string>;

  @Property({
    valueType: Status,
    value: Status.normal(),
    didSetValue(status: Status): void {
      this.owner.callObservers("traitDidSetStatus", status, this.owner);
    },
  })
  readonly status!: Property<this, Status>;

  @Property({
    valueType: Boolean,
    value: false,
    didSetValue(hidden: boolean): void {
      this.owner.callObservers("traitDidSetHidden", hidden, this.owner);
    },
  })
  readonly hidden!: Property<this, boolean>;

  @Property({
    valueType: Boolean,
    value: true,
    didSetValue(disclosed: boolean): void {
      this.owner.callObservers("traitDidSetDisclosed", disclosed, this.owner);
    },
  })
  readonly disclosed!: Property<this, boolean>;

  @TraitModelSet({
    traitType: EntityTrait,
    traitKey: "entity",
    modelType: Model,
    binds: true,
    observes: true,
    init(): void {
      this.ids = {};
    },
    willAttachTrait(entityTrait: E): void {
      if (entityTrait.id.value !== void 0) {
        this.ids[entityTrait.id.value] = entityTrait;
      }
      this.owner.callObservers("traitWillAttachEntity", entityTrait, this.owner);
    },
    didDetachTrait(entityTrait: E): void {
      this.owner.callObservers("traitDidDetachEntity", entityTrait, this.owner);
      if (entityTrait.id.value !== void 0) {
        delete this.ids[entityTrait.id.value];
      }
    },
    modelDidAttachParent(parent: Model, model: Model): void {
      const entityTrait = this.detectModelTrait(model);
      const targetModel = model.nextSibling;
      const targetTrait = targetModel !== null ? this.detectModelTrait(targetModel) : null;
      if (entityTrait !== null) {
        this.owner.callObservers("traitDidReinsertEntity", entityTrait, targetTrait, this.owner);
      }
    },
    createTrait(id?: string): E {
      const trait = super.createTrait();
      if (id !== void 0) {
        trait.id.setIntrinsic(id);
      }
      this.createModel(trait);
      return trait;
    },
    get(id?: string): any/*Model | E | null*/ {
      if (arguments.length === 0) {
        return null;
      }
      const trait = this.ids[id as string];
      return trait !== void 0 ? trait : null;
    },
    getOrInsert(id: string): E | null {
      let trait = this.get(id);
      if (trait === null) {
        trait = this.createTrait(id);
        this.addTrait(trait);
      }
      return trait;
    },
  })
  readonly entities!: TraitModelSet<this, E, Model> & Observes<Model> & {
    /** @internal */
    ids: {[id: string]: E | undefined};
    /** @override */
    createTrait(id?: string): E;
    get(id: string): E | null;
    getOrInsert(id: string): E | null;
  };
}
