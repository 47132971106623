// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import type {Observes} from "@swim/util";
import {TraitRef} from "@swim/model";
import {Look} from "@swim/theme";
import type {View} from "@swim/view";
import {ViewRef} from "@swim/view";
import type {PositionGestureInput} from "@swim/view";
import {TraitViewRef} from "@swim/controller";
import {TraitControllerSet} from "@swim/controller";
import {TraitViewControllerRef} from "@swim/controller";
import {TraitViewControllerSet} from "@swim/controller";
import {ColLayout} from "@swim/table";
import {TableLayout} from "@swim/table";
import type {LeafView} from "@swim/table";
import type {RowView} from "@swim/table";
import type {RowTrait} from "@swim/table";
import type {RowController} from "@swim/table";
import {TextColView} from "@swim/table";
import {DisclosureColView} from "@swim/table";
import type {HeaderView} from "@swim/table";
import type {HeaderTrait} from "@swim/table";
import type {HeaderController} from "@swim/table";
import type {TableView} from "@swim/table";
import type {TableTrait} from "@swim/table";
import type {TableControllerObserver} from "@swim/table";
import {TableController} from "@swim/table";
import type {EntityTrait} from "@nstream/domain";
import {RelationTrait} from "@nstream/domain";
import {EntityRowController} from "./EntityRowController";

/** @public */
export interface RelationTableControllerObserver<C extends RelationTableController = RelationTableController> extends TableControllerObserver<C> {
  controllerWillAttachRelationTrait?(relationTrait: RelationTrait, controller: C): void;

  controllerDidDetachRelationTrait?(relationTrait: RelationTrait, controller: C): void;

  controllerWillAttachDisclosureColView?(disclosureColView: DisclosureColView, controller: C): void;

  controllerDidDetachDisclosureColView?(disclosureColView: DisclosureColView, controller: C): void;

  controllerWillAttachTitleColView?(titleColView: TextColView, controller: C): void;

  controllerDidDetachTitleColView?(titleColView: TextColView, controller: C): void;

  controllerWillAttachEntityRow?(entityRowController: EntityRowController, controller: C): void;

  controllerDidDetachEntityRow?(entityRowController: EntityRowController, controller: C): void;

  controllerDidPressEntityRow?(input: PositionGestureInput, event: Event | null, entityRowController: EntityRowController, controller: C): void;

  controllerDidLongPressEntityRow?(input: PositionGestureInput, entityRowController: EntityRowController, controller: C): void;

  controllerDidPressEntityMore?(input: PositionGestureInput, event: Event | null, entityRowController: EntityRowController, controller: C): void;
}

/** @public */
export class RelationTableController extends TableController {
  declare readonly observerType?: Class<RelationTableControllerObserver>;

  @TraitRef({
    traitType: RelationTrait,
    observes: true,
    willAttachTrait(relationTrait: RelationTrait): void {
      this.owner.callObservers("controllerWillAttachRelationTrait", relationTrait, this.owner);
    },
    didAttachTrait(relationTrait: RelationTrait): void {
      this.owner.rows.sort(relationTrait.entities.sorted);
      this.owner.entityRows.sort(relationTrait.entities.sorted);
      this.owner.entityRows.insertTraits(void 0, relationTrait.entities.traits);

      this.owner.titleCol.attachView().setIntrinsic({
        label: relationTrait.title.value,
      });

      const tableView = this.owner.table.view;
      if (tableView !== null) {
        tableView.expansion.set(relationTrait.disclosed.value);
        if (tableView.mounted) {
          this.mountTable(tableView, relationTrait);
        }
      }
    },
    willDetachTrait(relationTrait: RelationTrait): void {
      const tableView = this.owner.table.view;
      if (tableView !== null && tableView.mounted) {
        this.unmountTable(tableView, relationTrait);
      }
      this.owner.entityRows.deleteTraits(relationTrait.entities.traits);
    },
    didDetachTrait(relationTrait: RelationTrait): void {
      this.owner.callObservers("controllerDidDetachRelationTrait", relationTrait, this.owner);
    },
    traitDidSetTitle(relationTitle: string): void {
      this.owner.titleCol.attachView().setIntrinsic({
        label: relationTitle,
      });
    },
    traitDidSetDisclosed(disclosed: boolean): void {
      const tableView = this.owner.table.view;
      if (tableView !== null) {
        tableView.expansion.set(disclosed);
      }
    },
    traitWillAttachEntity(entityTrait: EntityTrait): void {
      this.owner.entityRows.insertTrait(void 0, entityTrait);
    },
    traitDidDetachEntity(entityTrait: EntityTrait): void {
      this.owner.entityRows.deleteTrait(entityTrait);
    },
    traitDidReinsertEntity(entityTrait: EntityTrait, targetTrait: EntityTrait | null): void {
      this.owner.entityRows.reinsertTrait(entityTrait, targetTrait);
    },
    mountTable(tableView: TableView, relationTrait: RelationTrait): void {
      if (!tableView.expansion.collapsed) {
        relationTrait.consume(this.owner);
      }
    },
    unmountTable(tableView: TableView, relationTrait: RelationTrait): void {
      relationTrait.unconsume(this.owner);
    },
  })
  readonly relation!: TraitRef<this, RelationTrait> & Observes<RelationTrait> & {
    mountTable(tableView: TableView, relationTrait: RelationTrait): void,
    unmountTable(tableView: TableView, relationTrait: RelationTrait): void,
  };

  @TraitViewRef({
    extends: true,
    observesView: true,
    initView(tableView: TableView): void {
      super.initView(tableView);

      tableView.header.setView(this.owner.header.attachView());
      tableView.set({
        style: {
          backgroundColor: null,
          userSelect: "none",
        },
        rowHeight: tableView.viewIdiom === "mobile" ? 48 : 36,
      });

      const iconLayout = ColLayout.create("icon", 0, 0, 48);
      const titleLayout = ColLayout.create("title", 1, 0);
      const moreLayout = ColLayout.create("more", 0, 0, 48);
      const tableLayout = TableLayout.create([iconLayout, titleLayout, moreLayout]);
      tableView.layout.setIntrinsic(tableLayout);

      const relationTrait = this.owner.relation.trait;
      if (relationTrait !== null) {
        tableView.expansion.set(relationTrait.disclosed.value);
      }
    },
    didAttachView(tableView: TableView, targetView: View): void {
      const relationTrait = this.owner.relation.trait;
      if (tableView.mounted && relationTrait !== null) {
        this.owner.relation.mountTable(tableView, relationTrait);
      }
      super.didAttachView(tableView, targetView);
    },
    willDetachView(tableView: TableView): void {
      super.willDetachView(tableView);
      const relationTrait = this.owner.relation.trait;
      if (tableView.mounted && relationTrait !== null) {
        this.owner.relation.unmountTable(tableView, relationTrait);
      }
    },
    viewWillExpand(tableView: TableView): void {
      const relationTrait = this.owner.relation.trait;
      if (relationTrait !== null) {
        this.owner.relation.mountTable(tableView, relationTrait);
      }
    },
    viewDidCollapse(tableView: TableView): void {
      const relationTrait = this.owner.relation.trait;
      if (relationTrait !== null) {
        this.owner.relation.unmountTable(tableView, relationTrait);
      }
    },
    viewWillResize(tableView: TableView): void {
      tableView.rowHeight.setIntrinsic(tableView.viewIdiom === "mobile" ? 48 : 36);
    },
    viewDidMount(tableView: TableView): void {
      const relationTrait = this.owner.relation.trait;
      if (relationTrait !== null) {
        this.owner.relation.mountTable(tableView, relationTrait);
      }
    },
    viewWillUnmount(tableView: TableView): void {
      const relationTrait = this.owner.relation.trait;
      if (relationTrait !== null) {
        this.owner.relation.unmountTable(tableView, relationTrait);
      }
    },
  })
  override readonly table!: TraitViewRef<this, TableTrait, TableView> & TableController["table"];

  @TraitViewControllerRef({
    extends: true,
    attachHeaderView(headerView: HeaderView, headerController: HeaderController): void {
      super.attachHeaderView(headerView, headerController);
      this.initHeaderView(headerView, headerController);
    },
    initHeaderView(headerView: HeaderView, headerController: HeaderController): void {
      this.owner.disclosureCol.insertView(headerView);
      this.owner.titleCol.insertView(headerView);
    },
  })
  override readonly header!: TraitViewControllerRef<this, HeaderTrait, HeaderView, HeaderController> & TableController["header"] & {
    initHeaderView(headerView: HeaderView, headerController: HeaderController): void;
  };

  @ViewRef({
    viewType: DisclosureColView,
    viewKey: "icon",
    get parentView(): View | null {
      return this.owner.header.attachView();
    },
    initView(disclosureColView: DisclosureColView): void {
      const disclosureButton = disclosureColView.button.view;
      if (disclosureButton !== null) {
        disclosureButton.set({
          collapsedColor: Look.legendColor,
          expandedColor: Look.legendColor,
        });
      }
    },
    willAttachView(disclosureColView: DisclosureColView): void {
      this.owner.callObservers("controllerWillAttachDisclosureColView", disclosureColView, this.owner);
    },
    didDetachView(disclosureColView: DisclosureColView): void {
      this.owner.callObservers("controllerDidDetachDisclosureColView", disclosureColView, this.owner);
    },
  })
  readonly disclosureCol!: ViewRef<this, DisclosureColView>;

  @ViewRef({
    viewType: TextColView,
    viewKey: "title",
    get parentView(): View | null {
      return this.owner.header.attachView();
    },
    willAttachView(titleCellView: TextColView): void {
      this.owner.callObservers("controllerWillAttachTitleColView", titleCellView, this.owner);
    },
    didDetachView(titleCellView: TextColView): void {
      this.owner.callObservers("controllerDidDetachTitleColView", titleCellView, this.owner);
    },
  })
  readonly titleCol!: ViewRef<this, TextColView>;

  @TraitViewControllerSet({
    extends: true,
    compare(a: RowController, b: RowController): number {
      return this.owner.compareRowControllers(a, b);
    },
  })
  override readonly rows!: TraitViewControllerSet<this, RowTrait, RowView, RowController> & TableController["rows"];

  @TraitControllerSet({
    controllerType: EntityRowController,
    binds: false,
    observes: true,
    getTraitRef(entityRowController: EntityRowController): TraitRef<unknown, EntityTrait> {
      return entityRowController.entity;
    },
    willAttachController(entityRowController: EntityRowController): void {
      this.owner.callObservers("controllerWillAttachEntityRow", entityRowController, this.owner);
    },
    didAttachController(entityRowController: EntityRowController): void {
      const targetView = this.owner.rows.getTargetView(entityRowController);
      entityRowController.row.insertView(this.owner.table.view, void 0, targetView);
    },
    didDetachController(entityRowController: EntityRowController): void {
      this.owner.callObservers("controllerDidDetachEntityRow", entityRowController, this.owner);
    },
    controllerDidPressLeafView(input: PositionGestureInput, event: Event | null, leafView: LeafView, entityRowController: EntityRowController): void {
      this.owner.callObservers("controllerDidPressEntityRow", input, event, entityRowController, this.owner);
    },
    controllerDidLongPressLeafView(input: PositionGestureInput, leafView: LeafView, entityRowController: EntityRowController): void {
      this.owner.callObservers("controllerDidLongPressEntityRow", input, entityRowController, this.owner);
    },
    controllerDidPressMoreCellView(input: PositionGestureInput, event: Event | null, entityRowController: EntityRowController): void {
      this.owner.callObservers("controllerDidPressEntityMore", input, event, entityRowController, this.owner);
    },
    compare(a: EntityRowController, b: EntityRowController): number {
      return this.owner.compareRowControllers(a, b);
    },
  })
  readonly entityRows!: TraitControllerSet<this, EntityTrait, EntityRowController> & Observes<EntityRowController>;

  protected compareRowControllers(a: RowController, b: RowController): number {
    const relationTrait = this.relation.trait;
    if (relationTrait !== null && a instanceof EntityRowController && b instanceof EntityRowController) {
      const x = a.entity.trait;
      const y = b.entity.trait;
      if (x !== null && y !== null) {
        return relationTrait.entities.compareTraits(x, y);
      }
    }
    return 0;
  }
}
