// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import type {Timing} from "@swim/util";
import type {ContinuousScale} from "@swim/util";
import type {Observes} from "@swim/util";
import {Format} from "@swim/codec";
import {Provider} from "@swim/component";
import {Angle} from "@swim/math";
import {Length} from "@swim/math";
import type {DateTime} from "@swim/time";
import {DateTimeFormat} from "@swim/time";
import type {Trait} from "@swim/model";
import {Look} from "@swim/theme";
import type {View} from "@swim/view";
import {ViewRef} from "@swim/view";
import {ViewSet} from "@swim/view";
import type {HtmlView} from "@swim/dom";
import type {GraphicsPointerEvent} from "@swim/graphics";
import {GraphicsView} from "@swim/graphics";
import {CanvasView} from "@swim/graphics";
import {TextRunView} from "@swim/graphics";
import {TraitViewRef} from "@swim/controller";
import {PanelView} from "@swim/panel";
import type {PanelControllerObserver} from "@swim/panel";
import {PanelController} from "@swim/panel";
import {DialView} from "@swim/gauge";
import {GaugeView} from "@swim/gauge";
import {DataPointView} from "@swim/chart";
import {SeriesPlotView} from "@swim/chart";
import {LinePlotView} from "@swim/chart";
import {GraphView} from "@swim/chart";
import {TickView} from "@swim/chart";
import {TopTickView} from "@swim/chart";
import {AxisView} from "@swim/chart";
import {TopAxisView} from "@swim/chart";
import {ChartView} from "@swim/chart";
import {CalendarService} from "@nstream/domain";

/** @public */
export interface TimeGaugeControllerObserver<C extends TimeGaugeController = TimeGaugeController> extends PanelControllerObserver<C> {
}

/** @public */
export class TimeGaugeController extends PanelController {
  declare readonly observerType?: Class<TimeGaugeControllerObserver>;

  protected formatPanelTitle(dataPointView: DataPointView<DateTime, number>): string | undefined {
    return void 0;
  }

  protected formatPanelSubtitle(dataPointView: DataPointView<DateTime, number>): string | undefined {
    const time = dataPointView.x.getValue();
    return this.formatDate(time);
  }

  protected formatDialLabel(value: number, limit: number): string | undefined {
    return void 0;
  }

  protected formatDialLegend(value: number, limit: number): string | undefined {
    return void 0;
  }

  protected formatGaugeTitle(value: number, limit: number): string | undefined {
    return Format.prefix(value, 1);
  }

  protected formatDate(time: DateTime): string {
    return (this.constructor as typeof TimeGaugeController).dateFormat.format(time);
  }

  @TraitViewRef({
    extends: true,
    viewDidMount(panelView: PanelView): void {
      this.owner.consume(panelView);
    },
    viewWillUnmount(panelView: PanelView): void {
      this.owner.unconsume(panelView);
    },
    createView(): PanelView {
      return (super.createView() as PanelView).setIntrinsic({
        classList: ["time-gauge"],
        style: {
          userSelect: "none",
        },
        panelStyle: "card",
      });
    },
  })
  override readonly panel!: TraitViewRef<this, Trait, PanelView> & PanelController["panel"];

  @ViewRef({
    extends: true,
    setCurrent(dataPointView: DataPointView<DateTime, number>): void {
      const title = this.owner.formatPanelTitle(dataPointView);
      if (title !== void 0) {
        this.set(title);
      }
    },
  })
  override readonly panelTitle!: ViewRef<this, HtmlView> & PanelController["panelTitle"] & {
    setCurrent(dataPointView: DataPointView<DateTime, number>): void,
  };

  @ViewRef({
    extends: true,
    setCurrent(dataPointView: DataPointView<DateTime, number>): void {
      const subtitle = this.owner.formatPanelSubtitle(dataPointView);
      if (subtitle !== void 0) {
        this.set(subtitle);
      }
    },
    updateScrubTime(scrubTime: DateTime | null): void {
      const subtitleView = this.view;
      if (subtitleView === null) {
        return;
      } else if (scrubTime !== null) {
        subtitleView.style.color.setIntrinsic(Look.textColor, true);
      } else {
        subtitleView.style.color.setIntrinsic(Look.labelColor, true);
      }
    },
  })
  override readonly panelSubtitle!: ViewRef<this, HtmlView> & PanelController["panelSubtitle"] & {
    setCurrent(dataPointView: DataPointView<DateTime, number>): void,
    updateScrubTime(scrubTime: DateTime | null): void,
  };

  @ViewRef({
    viewType: PanelView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.panel.attachView();
    },
    insertChild(parent: View, child: PanelView, target: View | null, key: string | undefined): void {
      if (target === null) {
        target = this.owner.chartPanel.view;
      }
      parent.insertChild(child, target, key);
    },
    createView(): PanelView {
      return (super.createView() as PanelView).setIntrinsic({
        style: {
          marginTop: 24,
        },
        unitWidth: 1,
        unitHeight: 4 / 5,
        minPanelHeight: 0,
      });
    },
  })
  readonly gaugePanel!: ViewRef<this, PanelView>;

  @ViewRef({
    viewType: CanvasView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.gaugePanel.insertView();
    },
  })
  readonly gaugeCanvas!: ViewRef<this, CanvasView>;

  @ViewRef({
    viewType: GaugeView,
    viewKey: true,
    observes: true,
    get parentView(): View | null {
      return this.owner.gaugeCanvas.insertView();
    },
    didAttachView(gaugeView: GaugeView): void {
      this.owner.gaugeTitle.setView(gaugeView.title.view);
    },
    willDetachView(gaugeView: GaugeView): void {
      this.owner.gaugeTitle.setView(null);
    },
    viewWillAttachTitle(titleView: GraphicsView): void {
      this.owner.gaugeTitle.setView(titleView);
    },
    viewDidDetachTitle(titleView: GraphicsView): void {
      this.owner.gaugeTitle.setView(null);
    },
    createView(): GaugeView {
      return new GaugeView().setIntrinsic({
        innerRadius: Length.pct(35),
        outerRadius: Length.pct(45),
        startAngle: Angle.rad(Math.PI * 5 / 8),
        sweepAngle: Angle.rad(Math.PI * 11 / 8),
        cornerRadius: Length.zero(),
        tickAlign: 0.5,
        tickRadius: Length.pct(50),
        tickLength: Length.pct(45),
        font: Look.font,
      });
    },
  })
  readonly gauge!: ViewRef<this, GaugeView> & Observes<GaugeView>;

  @ViewRef({
    viewType: GraphicsView,
    viewKey: "title",
    get parentView(): View | null {
      return this.owner.gauge.attachView();
    },
    initView(titleView: GraphicsView): void {
      if (titleView instanceof TextRunView) {
        titleView.font.setInherits(false);
        titleView.font.setIntrinsic(Look.largeFont);
        titleView.textColor.setIntrinsic(Look.textColor);
      }
    },
    setText(title: string | undefined): GraphicsView {
      return this.owner.gauge.attachView().title.set(title);
    },
  })
  readonly gaugeTitle!: ViewRef<this, GraphicsView> & {
    setText(title: string | undefined): GraphicsView,
  };

  @ViewRef({
    viewType: DialView,
    viewKey: true,
    observes: true,
    get parentView(): View | null {
      return this.owner.gauge.insertView();
    },
    initView(dialView: DialView): void {
      this.updateValue(dialView.value.value, dialView.limit.value);
    },
    didAttachView(dialView: DialView): void {
      this.owner.dialLabel.setView(dialView.label.view);
      this.owner.dialLegend.setView(dialView.legend.view);
    },
    willDetachView(dialView: DialView): void {
      this.owner.dialLabel.setView(null);
      this.owner.dialLegend.setView(null);
    },
    viewWillAttachLabel(labelView: GraphicsView): void {
      this.owner.dialLabel.setView(labelView);
    },
    viewDidDetachLabel(labelView: GraphicsView): void {
      this.owner.dialLabel.setView(null);
    },
    viewWillAttachLegend(legendView: GraphicsView): void {
      this.owner.dialLegend.setView(legendView);
    },
    viewDidDetachLegend(legendView: GraphicsView): void {
      this.owner.dialLegend.setView(null);
    },
    viewDidSetValue(value: number, dialView: DialView): void {
      this.updateValue(value, dialView.limit.value);
    },
    viewDidSetLimit(limit: number, dialView: DialView): void {
      this.updateValue(dialView.value.value, limit);
    },
    updateValue(value: number, limit: number): void {
      const title = this.owner.formatGaugeTitle(value, limit);
      if (title !== void 0) {
        this.owner.gaugeTitle.setText(title);
      }
      const label = this.owner.formatDialLabel(value, limit);
      if (label !== void 0) {
        this.owner.dialLabel.setText(label);
      }
      const legend = this.owner.formatDialLegend(value, limit);
      if (legend !== void 0) {
        this.owner.dialLegend.setText(legend);
      }
    },
    setCurrent(dataPointView: DataPointView<DateTime, number>): void {
      const dialView = this.attachView();
      let timing: Timing | undefined;
      if (dialView.value.tweening) {
        timing = void 0;
      } else {
        timing = dialView.getLookOr(Look.timing, void 0);
      }

      const dialValue = dataPointView.y.getValue();
      dialView.value.setIntrinsic(dialValue, timing);

      if (dataPointView.color.look !== null) {
        dialView.meterColor.setIntrinsic(dataPointView.color.look, timing);
        dialView.moodModifier.setIntrinsic(dataPointView.moodModifier.value);
        if (dialView.theme.value !== null && dialView.mood.value !== null) {
          dialView.applyTheme(dialView.theme.value, dialView.mood.value, timing);
        }
      } else if (dataPointView.color.value !== null) {
        dialView.meterColor.setIntrinsic(dataPointView.color.value, timing);
        dialView.moodModifier.setIntrinsic(null);
      } else {
        dialView.meterColor.setIntrinsic(Look.accentColor, timing);
        dialView.moodModifier.setIntrinsic(null);
        if (dialView.theme.value !== null && dialView.mood.value !== null) {
          dialView.applyTheme(dialView.theme.value, dialView.mood.value, timing);
        }
      }
    },
  })
  readonly dial!: ViewRef<this, DialView> & Observes<DialView> & {
    updateValue(value: number, limit: number): void,
    setCurrent(dataPointView: DataPointView<DateTime, number>): void,
  };

  @ViewRef({
    viewType: GraphicsView,
    viewKey: "label",
    get parentView(): View | null {
      return this.owner.dial.attachView();
    },
    initView(labelView: GraphicsView): void {
      if (labelView instanceof TextRunView) {
        labelView.font.setInherits(false);
        labelView.font.setIntrinsic(Look.smallFont);
        labelView.textColor.setIntrinsic(Look.labelColor);
      }
    },
    setText(label: string | undefined): GraphicsView {
      return this.owner.dial.attachView().label.set(label);
    },
  })
  readonly dialLabel!: ViewRef<this, GraphicsView> & {
    setText(label: string | undefined): GraphicsView,
  };

  @ViewRef({
    viewType: GraphicsView,
    viewKey: "legend",
    get parentView(): View | null {
      return this.owner.dial.attachView();
    },
    initView(legendView: GraphicsView): void {
      if (legendView instanceof TextRunView) {
        legendView.font.setInherits(false);
        legendView.font.setIntrinsic(Look.smallFont);
        legendView.textColor.setIntrinsic(Look.labelColor);
      }
    },
    setText(legend: string | undefined): GraphicsView {
      return this.owner.dial.attachView().legend.set(legend);
    },
  })
  readonly dialLegend!: ViewRef<this, GraphicsView> & {
    setText(legend: string | undefined): GraphicsView,
  };

  @ViewRef({
    viewType: PanelView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.panel.attachView();
    },
    createView(): PanelView {
      return (super.createView() as PanelView).setIntrinsic({
        unitWidth: 1,
        unitHeight: 1 / 5,
        minPanelHeight: 0,
      });
    },
  })
  readonly chartPanel!: ViewRef<this, PanelView>;

  @ViewRef({
    viewType: CanvasView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.chartPanel.insertView();
    },
    createView(): CanvasView {
      return (super.createView() as CanvasView).setIntrinsic({
        pointerEvents: true,
      });
    },
  })
  readonly chartCanvas!: ViewRef<this, CanvasView>;

  @ViewRef({
    viewType: ChartView,
    viewKey: true,
    observes: true,
    get parentView(): View | null {
      return this.owner.chartCanvas.insertView();
    },
    didAttachView(chartView: ChartView<DateTime, number>): void {
      this.owner.graph.setView(chartView.graph.view);
    },
    willDetachView(chartView: ChartView<DateTime, number>): void {
      this.owner.graph.setView(null);
    },
    viewWillAttachGraph(graphView: GraphView<DateTime, number>): void {
      this.owner.graph.setView(graphView);
    },
    viewDidDetachGraph(graphView: GraphView<DateTime, number>): void {
      this.owner.graph.setView(null);
    },
    viewDidSetYScale(yScale: ContinuousScale<number, number> | null): void {
      if (yScale !== null) {
        this.owner.dial.attachView().setIntrinsic({
          limit: yScale.domain[1],
        });
      }
    },
    createView(): ChartView<DateTime, number> {
      const chartView = super.createView() as ChartView<DateTime, number>;
      chartView.domainTracking(true);
      return chartView.setIntrinsic({
        gutterTop: 6,
        gutterRight: 12,
        gutterBottom: 12,
        gutterLeft: 12,
      });
    },
  })
  readonly chart!: ViewRef<this, ChartView<DateTime, number>> & Observes<ChartView<DateTime, number>>;

  @ViewRef({
    viewType: AxisView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.chart.insertView();
    },
    initView(tickAxisView: AxisView<DateTime>): void {
      const scrubTime = this.owner.calendar.getService().scrubTime.value;
      this.owner.currentTick.updateScrubTime(scrubTime);
    },
    createView(): AxisView<DateTime> {
      return new TopAxisView<DateTime>().setIntrinsic({
        tickGenerator: null,
        tickMarkWidth: 2,
        tickMarkLength: 6,
        tickMarkColor: Look.textColor,
        gridLineWidth: 2,
        gridLineColor: Look.textColor,
        borderWidth: 0,
      });
    },
  })
  readonly tickAxis!: ViewRef<this, AxisView<DateTime>>;

  @ViewRef({
    viewType: TickView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.tickAxis.view;
    },
    updateScrubTime(scrubTime: DateTime | null): void {
      if (scrubTime === null) {
        this.deleteView();
        return;
      }
      const currentTickView = new TopTickView<DateTime>(scrubTime).setIntrinsic({
        gridLineWidth: 1,
      });
      currentTickView.setIntangible(true);
      this.insertView(null, currentTickView);
    },
  })
  readonly currentTick!: ViewRef<this, TickView<DateTime>> & {
    updateScrubTime(scrubTime: DateTime | null): void,
  };

  @ViewRef({
    viewType: GraphView,
    viewKey: true,
    init(): void {
      this.onPointerMove = this.onPointerMove.bind(this);
      this.onPointerEnter = this.onPointerEnter.bind(this);
      this.onPointerLeave = this.onPointerLeave.bind(this);
    },
    get parentView(): View | null {
      return this.owner.chart.insertView();
    },
    initView(graphView: GraphView<DateTime, number>): void {
      graphView.addEventListener("pointermove", this.onPointerMove);
      graphView.addEventListener("pointerenter", this.onPointerEnter);
      graphView.addEventListener("pointerleave", this.onPointerLeave);
    },
    deinitView(graphView: GraphView<DateTime, number>): void {
      graphView.removeEventListener("pointermove", this.onPointerMove);
      graphView.removeEventListener("pointerenter", this.onPointerEnter);
      graphView.removeEventListener("pointerleave", this.onPointerLeave);
    },
    updatePointer(event: GraphicsPointerEvent): void {
      const graphView = this.view;
      const xScale = graphView !== null ? graphView.xScale.value : null;
      if (xScale !== null) {
        const clientBounds = graphView!.clientBounds;
        const x = event.clientX - clientBounds.x;
        const t = xScale.inverse(x);
        this.owner.calendar.getService().scrubTime.setIntrinsic(t);
      }
    },
    onPointerMove(event: GraphicsPointerEvent): void {
      this.updatePointer(event);
    },
    onPointerEnter(event: GraphicsPointerEvent): void {
      this.updatePointer(event);
    },
    onPointerLeave(event: GraphicsPointerEvent): void {
      this.owner.calendar.getService().scrubTime.setIntrinsic(null);
    },
  })
  readonly graph!: ViewRef<this, GraphView<DateTime, number>> & {
    updatePointer(event: GraphicsPointerEvent): void,
    onPointerMove(event: GraphicsPointerEvent): void,
    onPointerEnter(event: GraphicsPointerEvent): void,
    onPointerLeave(event: GraphicsPointerEvent): void,
  };

  @ViewRef({
    viewType: SeriesPlotView,
    viewKey: true,
    observes: true,
    get parentView(): View | null {
      return this.owner.graph.insertView();
    },
    didAttachView(plotView: SeriesPlotView<DateTime, number>, targetView: View | null): void {
      this.owner.dataPoints.setViews(plotView.dataPoints.views);
    },
    willDetachView(plotView: SeriesPlotView<DateTime, number>): void {
      this.owner.dataPoints.deleteViews();
    },
    viewWillAttachDataPoint(dataPointView: DataPointView<DateTime, number>, targetView: View | null): void {
      this.owner.dataPoints.addView(dataPointView, targetView);
    },
    viewDidDetachDataPoint(dataPointView: DataPointView<DateTime, number>): void {
      this.owner.dataPoints.removeView(dataPointView);
    },
    createView(): SeriesPlotView<DateTime, number> {
      return new LinePlotView<DateTime, number>().setIntrinsic({
        hitMode: "none",
        strokeWidth: 1,
      });
    },
  })
  readonly plot!: ViewRef<this, SeriesPlotView<DateTime, number>> & Observes<SeriesPlotView<DateTime, number>>;

  @ViewSet({
    viewType: DataPointView,
    get parentView(): View | null {
      return this.owner.plot.attachView();
    },
  })
  readonly dataPoints!: ViewSet<this, DataPointView<DateTime, number>>;

  @ViewRef({
    viewType: DataPointView,
    get parentView(): View | null {
      return this.owner.plot.attachView();
    },
    didAttachView(dataPointView: DataPointView<DateTime, number>): void {
      if (this.owner.calendar.getService().scrubTime.value === null) {
        this.owner.current.setView(dataPointView);
      }
    },
    didDetachView(dataPointView: DataPointView<DateTime, number>): void {
      dataPointView.remove();
    },
  })
  readonly latest!: ViewRef<this, DataPointView<DateTime, number>>;

  @ViewRef({
    viewType: DataPointView,
    didAttachView(dataPointView: DataPointView<DateTime, number>): void {
      this.owner.panelTitle.setCurrent(dataPointView);
      this.owner.panelSubtitle.setCurrent(dataPointView);
      this.owner.dial.setCurrent(dataPointView);
    },
  })
  readonly current!: ViewRef<this, DataPointView<DateTime, number>>;

  @Provider({
    serviceType: CalendarService,
    observes: true,
    serviceDidSetScrubTime(scrubTime: DateTime | null): void {
      let dataPointView: DataPointView<DateTime, number> | null | undefined = null;
      if (scrubTime !== null) {
        const plotView = this.owner.plot.view;
        if (plotView !== null) {
          dataPointView = plotView.dataPointViews.get(scrubTime);
          if (dataPointView === void 0) {
            dataPointView = plotView.dataPointViews.previousValue(scrubTime);
            if (dataPointView === void 0) {
              dataPointView = null;
            }
          }
        }
      } else {
        dataPointView = this.owner.latest.view;
      }
      this.owner.current.setView(dataPointView);
      this.owner.currentTick.updateScrubTime(scrubTime);
      this.owner.panelSubtitle.updateScrubTime(scrubTime);
    },
  })
  readonly calendar!: Provider<this, CalendarService> & Observes<CalendarService>;

  static dateFormat: DateTimeFormat = DateTimeFormat.pattern("%b %d @ %H:%M");
}
