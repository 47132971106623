// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import {Property} from "@swim/component";
import type {ServiceObserver} from "@swim/component";
import {Service} from "@swim/component";
import {DateTime} from "@swim/time";
import {TimeZone} from "@swim/time";

/** @public */
export interface CalendarServiceObserver<S extends CalendarService = CalendarService> extends ServiceObserver<S> {
  serviceDidSetTimeZone?(timeZone: TimeZone, service: S): void;

  serviceDidSetScrubTime?(newScrubTime: DateTime | null, service: S): void;
}

/** @public */
export class CalendarService extends Service {
  declare readonly observerType?: Class<CalendarServiceObserver>;

  @Property({
    valueType: TimeZone,
    value: TimeZone.local(),
    didSetValue(timeZone: TimeZone): void {
      this.owner.callObservers("serviceDidSetTimeZone", timeZone, this.owner);
    },
  })
  readonly timeZone!: Property<this, TimeZone>;

  @Property({
    valueType: DateTime,
    value: null,
    didSetValue(scrubTime: DateTime | null): void {
      this.owner.callObservers("serviceDidSetScrubTime", scrubTime, this.owner);
    },
  })
  readonly scrubTime!: Property<this, DateTime | null>;
}
