// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import type {Observes} from "@swim/util";
import {TraitRef} from "@swim/model";
import type {View} from "@swim/view";
import type {PositionGestureInput} from "@swim/view";
import {TraitViewRef} from "@swim/controller";
import {TraitControllerRef} from "@swim/controller";
import {TraitViewControllerSet} from "@swim/controller";
import {ColLayout} from "@swim/table";
import {TableLayout} from "@swim/table";
import type {LeafView} from "@swim/table";
import type {RowView} from "@swim/table";
import type {RowTrait} from "@swim/table";
import type {RowController} from "@swim/table";
import type {TableView} from "@swim/table";
import type {TableTrait} from "@swim/table";
import type {TableControllerObserver} from "@swim/table";
import {TableController} from "@swim/table";
import {EntityTrait} from "@nstream/domain";
import {AspectRowController} from "./AspectRowController";
import {EntityRowController} from "./EntityRowController";

/** @public */
export interface EntityTableControllerObserver<C extends EntityTableController = EntityTableController> extends TableControllerObserver<C> {
  controllerWillAttachEntityTrait?(entityTrait: EntityTrait, controller: C): void;

  controllerDidDetachEntityTrait?(entityTrait: EntityTrait, controller: C): void;

  controllerWillAttachEntityRow?(entityRowController: EntityRowController, controller: C): void;

  controllerDidDetachEntityRow?(entityRowController: EntityRowController, controller: C): void;

  controllerDidPressEntityRow?(input: PositionGestureInput, event: Event | null, entityRowController: EntityRowController, controller: C): void;

  controllerDidLongPressEntityRow?(input: PositionGestureInput, entityRowController: EntityRowController, controller: C): void;

  controllerWillAttachAspectRow?(aspectRowController: AspectRowController, controller: C): void;

  controllerDidDetachAspectRow?(aspectRowController: AspectRowController, controller: C): void;

  controllerDidPressAspectRow?(input: PositionGestureInput, event: Event | null, aspectRowController: AspectRowController, controller: C): void;

  controllerDidLongPressAspectRow?(input: PositionGestureInput, aspectRowController: AspectRowController, controller: C): void;
}

/** @public */
export class EntityTableController extends TableController {
  declare readonly observerType?: Class<EntityTableControllerObserver>;

  @TraitRef({
    traitType: EntityTrait,
    observes: true,
    willAttachTrait(entityTrait: EntityTrait): void {
      this.owner.callObservers("controllerWillAttachEntityTrait", entityTrait, this.owner);
    },
    didAttachTrait(entityTrait: EntityTrait): void {
      this.owner.entityRow.insertTrait(void 0, entityTrait);

      const tableView = this.owner.table.view;
      if (tableView !== null && tableView.mounted) {
        this.mountTable(tableView, entityTrait);
      }
    },
    willDetachTrait(entityTrait: EntityTrait): void {
      const tableView = this.owner.table.view;
      if (tableView !== null && tableView.mounted) {
        this.unmountTable(tableView, entityTrait);
      }

      this.owner.entityRow.deleteTrait(entityTrait);
    },
    didDetachTrait(entityTrait: EntityTrait): void {
      this.owner.callObservers("controllerDidDetachEntityTrait", entityTrait, this.owner);
    },
    mountTable(tableView: TableView, entityTrait: EntityTrait): void {
      entityTrait.consume(this.owner);
    },
    unmountTable(tableView: TableView, entityTrait: EntityTrait): void {
      entityTrait.unconsume(this.owner);
    },
  })
  readonly entity!: TraitRef<this, EntityTrait> & Observes<EntityTrait> & {
    mountTable(tableView: TableView, entityTrait: EntityTrait): void,
    unmountTable(tableView: TableView, entityTrait: EntityTrait): void,
  };

  @TraitViewRef({
    extends: true,
    observesView: true,
    initView(tableView: TableView): void {
      super.initView(tableView);

      tableView.style.backgroundColor.set(null);
      tableView.style.userSelect.setIntrinsic("none");
      tableView.rowHeight.setIntrinsic(tableView.viewIdiom === "mobile" ? 48 : 36);

      const iconLayout = ColLayout.create("icon", 0, 0, 48);
      const titleLayout = ColLayout.create("title", 1, 0);
      const moreLayout = ColLayout.create("more", 0, 0, 48);
      const tableLayout = TableLayout.create([iconLayout, titleLayout, moreLayout]);
      tableView.layout.setIntrinsic(tableLayout);
    },
    didAttachView(tableView: TableView, targetView: View): void {
      const entityTrait = this.owner.entity.trait;
      if (tableView.mounted && entityTrait !== null) {
        this.owner.entity.mountTable(tableView, entityTrait);
      }
      super.didAttachView(tableView, targetView);
    },
    willDetachView(tableView: TableView): void {
      super.willDetachView(tableView);
      const entityTrait = this.owner.entity.trait;
      if (tableView.mounted && entityTrait !== null) {
        this.owner.entity.unmountTable(tableView, entityTrait);
      }
    },
    viewWillExpand(tableView: TableView): void {
      const entityTrait = this.owner.entity.trait;
      if (entityTrait !== null) {
        this.owner.entity.mountTable(tableView, entityTrait);
      }
    },
    viewDidCollapse(tableView: TableView): void {
      const entityTrait = this.owner.entity.trait;
      if (entityTrait !== null) {
        this.owner.entity.unmountTable(tableView, entityTrait);
      }
    },
    viewWillResize(tableView: TableView): void {
      tableView.rowHeight.setIntrinsic(tableView.viewIdiom === "mobile" ? 48 : 36);
    },
    viewDidMount(tableView: TableView): void {
      const entityTrait = this.owner.entity.trait;
      if (entityTrait !== null) {
        this.owner.entity.mountTable(tableView, entityTrait);
      }
    },
    viewWillUnmount(tableView: TableView): void {
      const entityTrait = this.owner.entity.trait;
      if (entityTrait !== null) {
        this.owner.entity.unmountTable(tableView, entityTrait);
      }
    },
  })
  override readonly table!: TraitViewRef<this, TableTrait, TableView> & TableController["table"];

  @TraitViewControllerSet({
    extends: true,
    compare(a: RowController, b: RowController): number {
      return this.owner.compareRowControllers(a, b);
    },
  })
  override readonly rows!: TraitViewControllerSet<this, RowTrait, RowView, RowController> & TableController["rows"];

  @TraitControllerRef({
    controllerType: EntityRowController,
    binds: false,
    observes: true,
    getTraitRef(entityRowController: EntityRowController): TraitRef<unknown, EntityTrait> {
      return entityRowController.entity;
    },
    willAttachController(entityRowController: EntityRowController): void {
      this.owner.callObservers("controllerWillAttachEntityRow", entityRowController, this.owner);
    },
    didAttachController(entityRowController: EntityRowController): void {
      const targetView = this.owner.rows.getTargetView(entityRowController);
      entityRowController.row.insertView(this.owner.table.view, void 0, targetView);
    },
    didDetachController(entityRowController: EntityRowController): void {
      this.owner.callObservers("controllerDidDetachEntityRow", entityRowController, this.owner);
    },
    controllerDidPressLeafView(input: PositionGestureInput, event: Event | null, leafView: LeafView, entityRowController: EntityRowController): void {
      this.owner.callObservers("controllerDidPressEntityRow", input, event, entityRowController, this.owner);
    },
    controllerDidLongPressLeafView(input: PositionGestureInput, leafView: LeafView, entityRowController: EntityRowController): void {
      this.owner.callObservers("controllerDidLongPressEntityRow", input, entityRowController, this.owner);
    },
    createController(trait?: EntityTrait): EntityRowController {
      const entityRowController = super.createController(trait);
      entityRowController.moreCell.enabled = false;
      return entityRowController;
    },
  })
  readonly entityRow!: TraitControllerRef<this, EntityTrait, EntityRowController> & Observes<EntityRowController>;

  protected compareRowControllers(a: RowController, b: RowController): number {
    const entityTrait = this.entity.trait;
    if (entityTrait !== null && a instanceof AspectRowController && b instanceof AspectRowController) {
      const x = a.aspect.trait;
      const y = b.aspect.trait;
      if (x !== null && y !== null) {
        return entityTrait.aspects.compareTraits(x, y);
      }
    }
    return 0;
  }
}
