// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import type {Observes} from "@swim/util";
import {Format} from "@swim/codec";
import {Provider} from "@swim/component";
import {Length} from "@swim/math";
import type {DateTime} from "@swim/time";
import {DateTimeFormat} from "@swim/time";
import type {Trait} from "@swim/model";
import {Look} from "@swim/theme";
import type {View} from "@swim/view";
import {ViewRef} from "@swim/view";
import {ViewSet} from "@swim/view";
import type {HtmlView} from "@swim/dom";
import type {GraphicsPointerEvent} from "@swim/graphics";
import {CanvasView} from "@swim/graphics";
import type {Controller,} from "@swim/controller";
import {ControllerSet} from "@swim/controller";
import {TraitViewRef} from "@swim/controller";
import {PanelView} from "@swim/panel";
import type {PanelControllerObserver} from "@swim/panel";
import {PanelController} from "@swim/panel";
import {ColLayout} from "@swim/table";
import {TableLayout} from "@swim/table";
import {RowView} from "@swim/table";
import {ColView} from "@swim/table";
import {TextColView} from "@swim/table";
import {HeaderView} from "@swim/table";
import {TableView} from "@swim/table";
import {SeriesPlotView} from "@swim/chart";
import {GraphView} from "@swim/chart";
import {TickView} from "@swim/chart";
import {TopTickView} from "@swim/chart";
import {AxisView} from "@swim/chart";
import {TopAxisView} from "@swim/chart";
import {BottomAxisView} from "@swim/chart";
import {LeftAxisView} from "@swim/chart";
import {ChartView} from "@swim/chart";
import {CalendarService} from "@nstream/domain";
import {TimeSeriesController} from "./TimeSeriesController";

/** @public */
export interface TimeTableControllerObserver<C extends TimeTableController = TimeTableController> extends PanelControllerObserver<C> {
}

/** @public */
export class TimeTableController extends PanelController {
  declare readonly observerType?: Class<TimeTableControllerObserver>;

  protected formatDate(time: DateTime): string {
    return (this.constructor as typeof TimeTableController).dateFormat.format(time);
  }

  protected formatDateTickLabel(tickView: TickView<DateTime>): string | undefined {
    return void 0;
  }

  protected formatValueTickLabel(tickView: TickView<number>): string | undefined {
    return Format.prefix(tickView.value, 1);
  }

  @TraitViewRef({
    extends: true,
    viewDidMount(panelView: PanelView): void {
      this.owner.consume(panelView);
    },
    viewWillUnmount(panelView: PanelView): void {
      this.owner.unconsume(panelView);
    },
    createView(): PanelView {
      return (super.createView() as PanelView).setIntrinsic({
        classList: ["time-table"],
        style: {
          userSelect: "none",
        },
        panelStyle: "card",
      });
    },
  })
  override readonly panel!: TraitViewRef<this, Trait, PanelView> & PanelController["panel"];

  @ViewRef({
    viewType: PanelView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.panel.attachView();
    },
    createView(): PanelView {
      return (super.createView() as PanelView).setIntrinsic({
        style: {
          marginTop: 24,
        },
        unitWidth: 1,
        unitHeight: 1 / 2,
        minPanelHeight: 0,
      });
    },
  })
  readonly chartPanel!: ViewRef<this, PanelView>;

  @ViewRef({
    viewType: CanvasView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.chartPanel.insertView();
    },
    createView(): CanvasView {
      return (super.createView() as CanvasView).setIntrinsic({
        style: {
          touchAction: "manipulation",
        },
        wheelEvents: true,
        pointerEvents: true,
      });
    },
  })
  readonly chartCanvas!: ViewRef<this, CanvasView>;

  @ViewRef({
    viewType: ChartView,
    viewKey: true,
    observes: true,
    get parentView(): View | null {
      return this.owner.chartCanvas.insertView();
    },
    didAttachView(chartView: ChartView<DateTime, number>): void {
      this.owner.graph.setView(chartView.graph.view);
    },
    willDetachView(chartView: ChartView<DateTime, number>): void {
      this.owner.graph.setView(null);
    },
    viewWillAttachGraph(graphView: GraphView<DateTime, number>): void {
      this.owner.graph.setView(graphView);
    },
    viewDidDetachGraph(graphView: GraphView<DateTime, number>): void {
      this.owner.graph.setView(null);
    },
    createView(): ChartView<DateTime, number> {
      const chartView = super.createView() as ChartView<DateTime, number>;
      chartView.setIntrinsic({
        gutterTop: 12,
        gutterRight: 60,
        gutterBottom: 32,
        gutterLeft: 60,
      });
      chartView.domainTracking(true);
      chartView.xScaleGestures(true);
      chartView.graph.insertView();
      return chartView;
    },
  })
  readonly chart!: ViewRef<this, ChartView<DateTime, number>> & Observes<ChartView<DateTime, number>>;

  @ViewRef({
    viewType: AxisView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.chart.insertView();
    },
    initView(tickAxisView: AxisView<DateTime>): void {
      const scrubTime = this.owner.calendar.getService().scrubTime.value;
      this.owner.currentTick.updateScrubTime(scrubTime);
    },
    createView(): AxisView<DateTime> {
      return new TopAxisView<DateTime>().setIntrinsic({
        tickGenerator: null,
        tickMarkWidth: 2,
        tickMarkLength: 6,
        tickMarkColor: Look.textColor,
        gridLineWidth: 2,
        gridLineColor: Look.textColor,
        borderWidth: 0,
      });
    },
  })
  readonly tickAxis!: ViewRef<this, AxisView<DateTime>>;

  @ViewRef({
    viewType: TickView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.tickAxis.view;
    },
    updateScrubTime(scrubTime: DateTime | null): void {
      if (scrubTime === null) {
        this.deleteView();
        return;
      }
      const currentTickView = new TopTickView<DateTime>(scrubTime).setIntrinsic({
        gridLineWidth: 1,
      });
      currentTickView.setIntangible(true);
      this.insertView(null, currentTickView);
    },
  })
  readonly currentTick!: ViewRef<this, TickView<DateTime>> & {
    updateScrubTime(scrubTime: DateTime | null): void,
  };

  @ViewRef({
    viewType: AxisView,
    viewKey: true,
    observes: true,
    get parentView(): View | null {
      return this.owner.chart.attachView();
    },
    formatTickLabel(tickLabel: string, tickView: TickView<DateTime>): string | undefined {
      return this.owner.formatDateTickLabel(tickView);
    },
    createView(): AxisView<DateTime> {
      return new BottomAxisView<DateTime>();
    },
  })
  readonly timeAxis!: ViewRef<this, AxisView<DateTime>> & Observes<AxisView<DateTime>>;

  @ViewRef({
    viewType: AxisView,
    viewKey: true,
    observes: true,
    get parentView(): View | null {
      return this.owner.chart.attachView();
    },
    formatTickLabel(tickLabel: string, tickView: TickView<number>): string | undefined {
      return this.owner.formatValueTickLabel(tickView);
    },
    createView(): AxisView<number> {
      return new LeftAxisView<number>();
    },
  })
  readonly valueAxis!: ViewRef<this, AxisView<number>> & Observes<AxisView<number>>;

  @ViewRef({
    viewType: GraphView,
    viewKey: true,
    init(): void {
      this.onPointerMove = this.onPointerMove.bind(this);
      this.onPointerEnter = this.onPointerEnter.bind(this);
      this.onPointerLeave = this.onPointerLeave.bind(this);
    },
    get parentView(): View | null {
      return this.owner.chart.insertView();
    },
    initView(graphView: GraphView<DateTime, number>): void {
      graphView.addEventListener("pointermove", this.onPointerMove);
      graphView.addEventListener("pointerenter", this.onPointerEnter);
      graphView.addEventListener("pointerleave", this.onPointerLeave);
    },
    deinitView(graphView: GraphView<DateTime, number>): void {
      graphView.removeEventListener("pointermove", this.onPointerMove);
      graphView.removeEventListener("pointerenter", this.onPointerEnter);
      graphView.removeEventListener("pointerleave", this.onPointerLeave);
    },
    updatePointer(event: GraphicsPointerEvent): void {
      const graphView = this.view;
      const xScale = graphView !== null ? graphView.xScale.value : null;
      if (xScale !== null) {
        const clientBounds = graphView!.clientBounds;
        const x = event.clientX - clientBounds.x;
        const t = xScale.inverse(x);
        this.owner.calendar.getService().scrubTime.setIntrinsic(t);
      }
    },
    onPointerMove(event: GraphicsPointerEvent): void {
      this.updatePointer(event);
    },
    onPointerEnter(event: GraphicsPointerEvent): void {
      this.updatePointer(event);
    },
    onPointerLeave(event: GraphicsPointerEvent): void {
      this.owner.calendar.getService().scrubTime.setIntrinsic(null);
    },
  })
  readonly graph!: ViewRef<this, GraphView<DateTime, number>> & {
    updatePointer(event: GraphicsPointerEvent): void,
    onPointerMove(event: GraphicsPointerEvent): void,
    onPointerEnter(event: GraphicsPointerEvent): void,
    onPointerLeave(event: GraphicsPointerEvent): void,
  };

  @ViewSet({
    viewType: SeriesPlotView,
    get parentView(): View | null {
      return this.owner.graph.attachView();
    },
  })
  readonly plots!: ViewSet<this, SeriesPlotView<DateTime, number>>;

  @ViewRef({
    viewType: PanelView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.panel.attachView();
    },
    createView(): PanelView {
      return (super.createView() as PanelView).setIntrinsic({
        style: {
          marginBottom: 12,
          overflowX: "hidden",
          overflowY: "auto",
        },
        unitWidth: 1,
        unitHeight: 1 / 2,
        minPanelHeight: 0,
      });
    },
  })
  readonly tablePanel!: ViewRef<this, PanelView>;

  @ViewRef({
    viewType: TableView,
    viewKey: true,
    observes: true,
    get parentView(): View | null {
      return this.owner.tablePanel.insertView();
    },
    initView(tableView: TableView): void {
      this.updateLayout();
    },
    didAttachView(tableView: TableView): void {
      this.owner.header.setView(tableView.header.view);
      this.owner.rows.setViews(tableView.rows.views);
    },
    willDetachView(tableView: TableView): void {
      this.owner.rows.deleteViews();
      this.owner.header.setView(null);
    },
    viewWillAttachHeader(headerView: HeaderView): void {
      this.owner.header.setView(headerView);
    },
    viewDidDetachHeader(headerView: HeaderView): void {
      this.owner.header.setView(null);
    },
    viewWillAttachRow(rowView: RowView): void {
      this.owner.rows.addView(rowView);
    },
    viewDidDetachRow(rowView: RowView): void {
      this.owner.rows.deleteView(rowView);
    },
    updateLayout(): void {
      const tableView = this.view;
      if (tableView !== null) {
        const layout = this.createLayout();
        tableView.layout.setIntrinsic(layout);
      }
    },
    createLayout(): TableLayout {
      const cols = new Array<ColLayout>();
      const nameColLayout = this.owner.nameCol.layout;
      if (nameColLayout !== null) {
        cols.push(nameColLayout);
      }
      const colViews = this.owner.cols.views;
      for (const viewId in colViews) {
        const colView = colViews[viewId]!;
        if (colView.key !== "name") {
          const colLayout = this.owner.cols.getLayout(colView);
          if (colLayout !== null) {
            cols.push(colLayout);
          }
        }
      }
      return new TableLayout(null, null, null, Length.px(12), cols);
    },
    createView(): TableView {
      return (super.createView() as TableView).style.setIntrinsic({
        paddingLeft: 12,
        paddingRight: 12,
      });
    },
  })
  readonly table!: ViewRef<this, TableView> & Observes<TableView> & {
    updateLayout(): void,
    createLayout(): TableLayout,
  };

  @ViewRef({
    viewType: HeaderView,
    viewKey: true,
    observes: true,
    get parentView(): View | null {
      return this.owner.table.attachView();
    },
    insertChild(parent: View, child: HtmlView, target: View | null, key: string | undefined): void {
      parent.prependChild(child, key);
    },
    didAttachView(headerView: HeaderView): void {
      this.owner.cols.setViews(headerView.cols.views);
    },
    willDetachView(headerView: HeaderView): void {
      this.owner.cols.deleteViews();
    },
    viewWillAttachCol(colView: ColView): void {
      this.owner.cols.addView(colView);
    },
    viewDidDetachCol(colView: ColView): void {
      this.owner.cols.removeView(colView);
    },
  })
  readonly header!: ViewRef<this, HeaderView> & Observes<HeaderView>;

  @ViewSet({
    viewType: ColView,
    get parentView(): View | null {
      return this.owner.header.attachView();
    },
    didAttachView(colView: ColView): void {
      this.owner.table.updateLayout();
    },
    willDetachView(colView: ColView): void {
      this.owner.table.updateLayout();
    },
    getLayout(colView: ColView): ColLayout | null {
      const colKey = colView.key;
      if (colKey === void 0) {
        return null;
      }
      return ColLayout.create(colKey, 1, 0, 0, false, false, Look.textColor);
    },
  })
  readonly cols!: ViewSet<this, ColView> & {
    getLayout(colView: ColView): ColLayout | null,
  };

  @ViewRef({
    viewType: ColView,
    viewKey: "name",
    init(): void {
      this.layout = ColLayout.create(this.viewKey!, 2, 0, 0, false, false, Look.labelColor);
    },
    get parentView(): View | null {
      return this.owner.header.attachView();
    },
    createView(): ColView {
      return TextColView.create().setIntrinsic({
        label: "Name",
      });
    },
  })
  readonly nameCol!: ViewRef<this, ColView> & {
    layout: ColLayout | null,
  };

  @ViewRef({
    viewType: ColView,
    viewKey: "latest",
    get parentView(): View | null {
      return this.owner.header.attachView();
    },
    createView(): ColView {
      return TextColView.create().setIntrinsic({
        label: "Latest",
      });
    },
  })
  readonly latestCol!: ViewRef<this, ColView>;

  @ViewRef({
    viewType: ColView,
    viewKey: "current",
    get parentView(): View | null {
      return this.owner.header.attachView();
    },
    createView(): ColView {
      return TextColView.create().setIntrinsic({
        label: "Current"
      });
    },
  })
  readonly currentCol!: ViewRef<this, ColView>;

  @ViewSet({
    viewType: RowView,
    get parentView(): View | null {
      return this.owner.table.attachView();
    },
  })
  readonly rows!: ViewSet<this, RowView>;

  @ControllerSet({
    controllerType: TimeSeriesController,
    binds: true,
    observes: true,
    didAttachController(timeSeriesController: TimeSeriesController, targetController: Controller | null): void {
      const rowView = timeSeriesController.row.view;
      if (rowView !== null) {
        let targetView: View | null = null;
        if (targetController instanceof TimeSeriesController) {
          targetView = targetController.row.view;
        }
        this.owner.rows.insertView(null, rowView, targetView, timeSeriesController.key);
      }
      this.pinTop();
    },
    willDetachController(timeSeriesController: TimeSeriesController): void {
      this.owner.pinned.detachController(timeSeriesController);
      const rowView = timeSeriesController.row.view;
      if (rowView !== null) {
        this.owner.rows.deleteView(rowView);
      }
    },
    didDetachController(timeSeriesController: TimeSeriesController): void {
      timeSeriesController.remove();
      this.pinTop();
    },
    controllerDidSetPinned(pinned: boolean, timeSeriesController: TimeSeriesController): void {
      if (pinned) {
        this.owner.pinned.attachController(timeSeriesController);
        this.unpinTop();
      } else {
        this.owner.pinned.detachController(timeSeriesController);
        this.pinTop();
      }
    },
    controllerWillAttachRow(rowView: RowView, targetView: View | null, timeSeriesController: TimeSeriesController): void {
      this.owner.rows.insertView(null, rowView, targetView, timeSeriesController.key);
    },
    controllerDidDetachRow(rowView: RowView, timeSeriesController: TimeSeriesController): void {
      this.owner.rows.deleteView(rowView);
    },
    controllerDidSetFocused(focused: boolean, timeSeriesController: TimeSeriesController): void {
      if (focused) {
        this.owner.focused.attachController(timeSeriesController);
      } else {
        this.owner.focused.detachController(timeSeriesController);
      }
      const focusedControllerCount = this.owner.focused.controllerCount;
      const seriesControllers = this.controllers;
      for (const controllerId in seriesControllers) {
        const seriesController = seriesControllers[controllerId]!;
        if (focusedControllerCount !== 0 && !this.owner.focused.hasController(seriesController)) {
          seriesController.defocused.setIntrinsic(true);
        } else {
          seriesController.defocused.setIntrinsic(false);
        }
      }
    },
    pinTop(): void {
      if (this.owner.pinned.controllerCount > 1) {
        return;
      }
      const topController = this.owner.getFirstChild(TimeSeriesController);
      let pinnedController: TimeSeriesController | null = null;
      const pinnedControllers = this.owner.pinned.controllers;
      for (const controllerId in pinnedControllers) {
        pinnedController = pinnedControllers[controllerId]!;
        break;
      }
      if (topController !== null && topController !== pinnedController &&
          (pinnedController === null || !pinnedController.pinned.value)) {
        if (pinnedController !== null) {
          this.owner.pinned.detachController(pinnedController);
        }
        this.owner.pinned.attachController(topController);
      }
    },
    unpinTop(): void {
      if (this.owner.pinned.controllerCount <= 1) {
        return;
      }
      const pinnedControllers = this.owner.pinned.controllers;
      for (const controllerId in pinnedControllers) {
        const pinnedController = pinnedControllers[controllerId]!;
        if (!pinnedController.pinned.value) {
          this.owner.pinned.detachController(pinnedController);
          break;
        }
      }
    },
  })
  readonly series!: ControllerSet<this, TimeSeriesController> & Observes<TimeSeriesController> & {
    pinTop(): void,
    unpinTop(): void,
  };

  @ControllerSet({
    controllerType: TimeSeriesController,
    observes: true,
    didAttachController(timeSeriesController: TimeSeriesController, targetController: Controller | null): void {
      if (this.owner.consuming) {
        timeSeriesController.consume(this.owner);
      }
      const plotView = timeSeriesController.plot.view;
      if (plotView !== null) {
        let targetView: View | null = null;
        if (targetController instanceof TimeSeriesController) {
          targetView = targetController.plot.view;
        }
        this.owner.plots.insertView(null, plotView, targetView, timeSeriesController.key);
      }
    },
    willDetachController(timeSeriesController: TimeSeriesController): void {
      const plotView = timeSeriesController.plot.view;
      if (plotView !== null) {
        this.owner.plots.deleteView(plotView);
      }
      if (this.owner.consuming) {
        timeSeriesController.unconsume(this.owner);
      }
    },
    controllerWillAttachPlot(plotView: SeriesPlotView<DateTime, number>, targetView: View | null, timeSeriesController: TimeSeriesController): void {
      this.owner.plots.insertView(null, plotView, targetView, timeSeriesController.key);
    },
    controllerDidDetachPlot(plotView: SeriesPlotView<DateTime, number>, timeSeriesController: TimeSeriesController): void {
      this.owner.plots.deleteView(plotView);
    },
  })
  readonly pinned!: ControllerSet<this, TimeSeriesController> & Observes<TimeSeriesController>;

  @ControllerSet({
    controllerType: TimeSeriesController,
  })
  readonly focused!: ControllerSet<this, TimeSeriesController>;

  @Provider({
    serviceType: CalendarService,
    observes: true,
    serviceDidSetScrubTime(scrubTime: DateTime | null): void {
      this.owner.currentTick.updateScrubTime(scrubTime);
    },
  })
  readonly calendar!: Provider<this, CalendarService> & Observes<CalendarService>;

  protected override onReinsertChild(child: Controller, target: Controller | null): void {
    super.onReinsertChild(child, target);
    this.series.pinTop();
  }

  protected override onStartConsuming(): void {
    super.onStartConsuming();
    this.pinned.consumeControllers(this);
  }

  protected override onStopConsuming(): void {
    super.onStopConsuming();
    this.pinned.unconsumeControllers(this);
  }

  static dateFormat: DateTimeFormat = DateTimeFormat.pattern("%b %d @ %H:%M");
}
