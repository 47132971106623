// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import type {Observes} from "@swim/util";
import {Format} from "@swim/codec";
import {Provider} from "@swim/component";
import {Length} from "@swim/math";
import type {DateTime} from "@swim/time";
import {DateTimeFormat} from "@swim/time";
import type {Trait} from "@swim/model";
import {Look} from "@swim/theme";
import type {View} from "@swim/view";
import {ViewRef} from "@swim/view";
import {ViewSet} from "@swim/view";
import type {GraphicsPointerEvent} from "@swim/graphics";
import {GraphicsView} from "@swim/graphics";
import {CanvasView} from "@swim/graphics";
import {TextRunView} from "@swim/graphics";
import type {Controller,} from "@swim/controller";
import {ControllerSet} from "@swim/controller";
import {TraitViewRef} from "@swim/controller";
import {PanelView} from "@swim/panel";
import type {PanelControllerObserver} from "@swim/panel";
import {PanelController} from "@swim/panel";
import {SliceView} from "@swim/pie";
import {PieView} from "@swim/pie";
import {SeriesPlotView} from "@swim/chart";
import {GraphView} from "@swim/chart";
import {TickView} from "@swim/chart";
import {TopTickView} from "@swim/chart";
import {AxisView} from "@swim/chart";
import {TopAxisView} from "@swim/chart";
import {ChartView} from "@swim/chart";
import {CalendarService} from "@nstream/domain";
import {TimeSeriesController} from "./TimeSeriesController";

/** @public */
export interface TimePieControllerObserver<C extends TimePieController = TimePieController> extends PanelControllerObserver<C> {
}

/** @public */
export class TimePieController extends PanelController {
  declare readonly observerType?: Class<TimePieControllerObserver>;

  protected formatDate(time: DateTime): string {
    return (this.constructor as typeof TimePieController).dateFormat.format(time);
  }

  protected formatDateTickLabel(tickView: TickView<DateTime>): string | undefined {
    return void 0;
  }

  protected formatValueTickLabel(tickView: TickView<number>): string | undefined {
    return Format.prefix(tickView.value, 1);
  }

  @TraitViewRef({
    extends: true,
    viewDidMount(panelView: PanelView): void {
      this.owner.consume(panelView);
    },
    viewWillUnmount(panelView: PanelView): void {
      this.owner.unconsume(panelView);
    },
    createView(): PanelView {
      return (super.createView() as PanelView).setIntrinsic({
        classList: ["time-pie"],
        style: {
          userSelect: "none",
        },
        panelStyle: "card",
      });
    },
  })
  override readonly panel!: TraitViewRef<this, Trait, PanelView> & PanelController["panel"];

  @ViewRef({
    viewType: PanelView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.panel.attachView();
    },
    insertChild(parent: View, child: PanelView, target: View | null, key: string | undefined): void {
      if (target === null) {
        target = this.owner.chartPanel.view;
      }
      parent.insertChild(child, target, key);
    },
    createView(): PanelView {
      return (super.createView() as PanelView).setIntrinsic({
        style: {
          marginTop: 24,
        },
        unitWidth: 1,
        unitHeight: 4 / 5,
        minPanelHeight: 0,
      });
    },
  })
  readonly piePanel!: ViewRef<this, PanelView>;

  @ViewRef({
    viewType: CanvasView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.piePanel.insertView();
    },
  })
  readonly pieCanvas!: ViewRef<this, CanvasView>;

  @ViewRef({
    viewType: PieView,
    viewKey: true,
    observes: true,
    get parentView(): View | null {
      return this.owner.pieCanvas.insertView();
    },
    didAttachView(pieView: PieView): void {
      this.owner.pieTitle.setView(pieView.title.view);
      this.owner.slices.setViews(pieView.slices.views);
    },
    willDetachView(pieView: PieView): void {
      this.owner.slices.deleteViews();
      this.owner.pieTitle.setView(null);
    },
    viewWillAttachTitle(titleView: GraphicsView): void {
      this.owner.pieTitle.setView(titleView);
    },
    viewDidDetachTitle(titleView: GraphicsView): void {
      this.owner.pieTitle.setView(null);
    },
    viewWillAttachSlice(sliceView: SliceView): void {
      this.owner.slices.addView(sliceView);
    },
    viewDidDetachSlice(sliceView: SliceView): void {
      this.owner.slices.deleteView(sliceView);
    },
    createView(): PieView {
      return new PieView().setIntrinsic({
        innerRadius: Length.pct(5),
        outerRadius: Length.pct(35),
        tickRadius: Length.pct(40),
        tickLength: Length.pct(45),
        font: Look.font,
      });
    },
  })
  readonly pie!: ViewRef<this, PieView> & Observes<PieView>;

  @ViewRef({
    viewType: GraphicsView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.pie.attachView();
    },
    initView(titleView: GraphicsView): void {
      if (titleView instanceof TextRunView) {
        titleView.font.setInherits(false);
        titleView.font.setIntrinsic(Look.largeFont);
        titleView.textColor.setIntrinsic(Look.textColor);
      }
    },
    setText(title: string | undefined): GraphicsView {
      return this.owner.pie.attachView().title.set(title);
    },
  })
  readonly pieTitle!: ViewRef<this, GraphicsView> & {
    setText(title: string | undefined): GraphicsView,
  };

  @ViewSet({
    viewType: SliceView,
    observes: true,
    get parentView(): View | null {
      return this.owner.pie.attachView();
    },
  })
  readonly slices!: ViewSet<this, SliceView>;

  @ViewRef({
    viewType: PanelView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.panel.attachView();
    },
    createView(): PanelView {
      return (super.createView() as PanelView).setIntrinsic({
        unitWidth: 1,
        unitHeight: 1 / 5,
        minPanelHeight: 0,
      });
    },
  })
  readonly chartPanel!: ViewRef<this, PanelView>;

  @ViewRef({
    viewType: CanvasView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.chartPanel.insertView();
    },
    createView(): CanvasView {
      return (super.createView() as CanvasView).setIntrinsic({
        style: {
          touchAction: "manipulation",
        },
        wheelEvents: true,
        pointerEvents: true,
      });
    },
  })
  readonly chartCanvas!: ViewRef<this, CanvasView>;

  @ViewRef({
    viewType: ChartView,
    viewKey: true,
    observes: true,
    get parentView(): View | null {
      return this.owner.chartCanvas.insertView();
    },
    didAttachView(chartView: ChartView<DateTime, number>): void {
      this.owner.graph.setView(chartView.graph.view);
    },
    willDetachView(chartView: ChartView<DateTime, number>): void {
      this.owner.graph.setView(null);
    },
    viewWillAttachGraph(graphView: GraphView<DateTime, number>): void {
      this.owner.graph.setView(graphView);
    },
    viewDidDetachGraph(graphView: GraphView<DateTime, number>): void {
      this.owner.graph.setView(null);
    },
    createView(): ChartView<DateTime, number> {
      const chartView = (super.createView() as ChartView<DateTime, number>).setIntrinsic({
        gutterTop: 12,
        gutterRight: 60,
        gutterBottom: 32,
        gutterLeft: 60,
      });
      chartView.domainTracking(true);
      chartView.xScaleGestures(true);
      chartView.graph.insertView();
      return chartView;
    },
  })
  readonly chart!: ViewRef<this, ChartView<DateTime, number>> & Observes<ChartView<DateTime, number>>;

  @ViewRef({
    viewType: AxisView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.chart.insertView();
    },
    initView(tickAxisView: AxisView<DateTime>): void {
      const scrubTime = this.owner.calendar.getService().scrubTime.value;
      this.owner.currentTick.updateScrubTime(scrubTime);
    },
    createView(): AxisView<DateTime> {
      return new TopAxisView<DateTime>().setIntrinsic({
        tickGenerator: null,
        tickMarkWidth: 2,
        tickMarkLength: 6,
        tickMarkColor: Look.textColor,
        gridLineWidth: 2,
        gridLineColor: Look.textColor,
        borderWidth: 0,
      });
    },
  })
  readonly tickAxis!: ViewRef<this, AxisView<DateTime>>;

  @ViewRef({
    viewType: TickView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.tickAxis.view;
    },
    updateScrubTime(scrubTime: DateTime | null): void {
      if (scrubTime === null) {
        this.deleteView();
        return;
      }
      const currentTickView = new TopTickView<DateTime>(scrubTime).setIntrinsic({
        gridLineWidth: 1,
      });
      currentTickView.setIntangible(true);
      this.insertView(null, currentTickView);
    },
  })
  readonly currentTick!: ViewRef<this, TickView<DateTime>> & {
    updateScrubTime(scrubTime: DateTime | null): void,
  };

  @ViewRef({
    viewType: GraphView,
    viewKey: true,
    init(): void {
      this.onPointerMove = this.onPointerMove.bind(this);
      this.onPointerEnter = this.onPointerEnter.bind(this);
      this.onPointerLeave = this.onPointerLeave.bind(this);
    },
    get parentView(): View | null {
      return this.owner.chart.insertView();
    },
    initView(graphView: GraphView<DateTime, number>): void {
      graphView.addEventListener("pointermove", this.onPointerMove);
      graphView.addEventListener("pointerenter", this.onPointerEnter);
      graphView.addEventListener("pointerleave", this.onPointerLeave);
    },
    deinitView(graphView: GraphView<DateTime, number>): void {
      graphView.removeEventListener("pointermove", this.onPointerMove);
      graphView.removeEventListener("pointerenter", this.onPointerEnter);
      graphView.removeEventListener("pointerleave", this.onPointerLeave);
    },
    updatePointer(event: GraphicsPointerEvent): void {
      const graphView = this.view;
      const xScale = graphView !== null ? graphView.xScale.value : null;
      if (xScale !== null) {
        const clientBounds = graphView!.clientBounds;
        const x = event.clientX - clientBounds.x;
        const t = xScale.inverse(x);
        this.owner.calendar.getService().scrubTime.setIntrinsic(t);
      }
    },
    onPointerMove(event: GraphicsPointerEvent): void {
      this.updatePointer(event);
    },
    onPointerEnter(event: GraphicsPointerEvent): void {
      this.updatePointer(event);
    },
    onPointerLeave(event: GraphicsPointerEvent): void {
      this.owner.calendar.getService().scrubTime.setIntrinsic(null);
    },
  })
  readonly graph!: ViewRef<this, GraphView<DateTime, number>> & {
    updatePointer(event: GraphicsPointerEvent): void,
    onPointerMove(event: GraphicsPointerEvent): void,
    onPointerEnter(event: GraphicsPointerEvent): void,
    onPointerLeave(event: GraphicsPointerEvent): void,
  };

  @ViewSet({
    viewType: SeriesPlotView,
    get parentView(): View | null {
      return this.owner.graph.attachView();
    },
  })
  readonly plots!: ViewSet<this, SeriesPlotView<DateTime, number>>;

  @ControllerSet({
    controllerType: TimeSeriesController,
    binds: true,
    observes: true,
    didAttachController(timeSeriesController: TimeSeriesController, targetController: Controller | null): void {
      const sliceView = timeSeriesController.slice.view;
      if (sliceView !== null) {
        let targetView: View | null = null;
        if (targetController instanceof TimeSeriesController) {
          targetView = targetController.slice.view;
        }
        this.owner.slices.insertView(null, sliceView, targetView, timeSeriesController.key);
      }
      this.pinTop();
    },
    willDetachController(timeSeriesController: TimeSeriesController): void {
      this.owner.pinned.detachController(timeSeriesController);
      const sliceView = timeSeriesController.slice.view;
      if (sliceView !== null) {
        this.owner.slices.deleteView(sliceView);
      }
    },
    didDetachController(timeSeriesController: TimeSeriesController): void {
      timeSeriesController.remove();
      this.pinTop();
    },
    controllerDidSetPinned(pinned: boolean, timeSeriesController: TimeSeriesController): void {
      if (pinned) {
        this.owner.pinned.attachController(timeSeriesController);
        this.unpinTop();
      } else {
        this.owner.pinned.detachController(timeSeriesController);
        this.pinTop();
      }
    },
    controllerWillAttachSlice(sliceView: SliceView, targetView: View | null, timeSeriesController: TimeSeriesController): void {
      this.owner.slices.insertView(null, sliceView, targetView, timeSeriesController.key);
    },
    controllerDidDetachSlice(sliceView: SliceView, timeSeriesController: TimeSeriesController): void {
      this.owner.slices.deleteView(sliceView);
    },
    controllerDidSetFocused(focused: boolean, timeSeriesController: TimeSeriesController): void {
      if (focused) {
        this.owner.focused.attachController(timeSeriesController);
      } else {
        this.owner.focused.detachController(timeSeriesController);
      }
      const focusedControllerCount = this.owner.focused.controllerCount;
      const seriesControllers = this.controllers;
      for (const controllerId in seriesControllers) {
        const seriesController = seriesControllers[controllerId]!;
        if (focusedControllerCount !== 0 && !this.owner.focused.hasController(seriesController)) {
          seriesController.defocused.setIntrinsic(true);
        } else {
          seriesController.defocused.setIntrinsic(false);
        }
      }
    },
    pinTop(): void {
      if (this.owner.pinned.controllerCount <= 1) {
        const topController = this.owner.getFirstChild(TimeSeriesController);
        let pinnedController: TimeSeriesController | null = null;
        const pinnedControllers = this.owner.pinned.controllers;
        for (const controllerId in pinnedControllers) {
          pinnedController = pinnedControllers[controllerId]!;
          break;
        }
        if (topController !== null && topController !== pinnedController &&
            (pinnedController === null || !pinnedController.pinned.value)) {
          if (pinnedController !== null) {
            this.owner.pinned.detachController(pinnedController);
          }
          this.owner.pinned.attachController(topController);
        }
      }
    },
    unpinTop(): void {
      if (this.owner.pinned.controllerCount > 1) {
        const pinnedControllers = this.owner.pinned.controllers;
        for (const controllerId in pinnedControllers) {
          const pinnedController = pinnedControllers[controllerId]!;
          if (!pinnedController.pinned.value) {
            this.owner.pinned.detachController(pinnedController);
            break;
          }
        }
      }
    },
  })
  readonly series!: ControllerSet<this, TimeSeriesController> & Observes<TimeSeriesController> & {
    pinTop(): void,
    unpinTop(): void,
  };

  @ControllerSet({
    controllerType: TimeSeriesController,
    observes: true,
    didAttachController(timeSeriesController: TimeSeriesController, targetController: Controller | null): void {
      if (this.owner.consuming) {
        timeSeriesController.consume(this.owner);
      }
      const plotView = timeSeriesController.plot.view;
      if (plotView !== null) {
        let targetView: View | null = null;
        if (targetController instanceof TimeSeriesController) {
          targetView = targetController.plot.view;
        }
        this.owner.plots.insertView(null, plotView, targetView, timeSeriesController.key);
      }
    },
    willDetachController(timeSeriesController: TimeSeriesController): void {
      const plotView = timeSeriesController.plot.view;
      if (plotView !== null) {
        this.owner.plots.deleteView(plotView);
      }
      if (this.owner.consuming) {
        timeSeriesController.unconsume(this.owner);
      }
    },
    controllerWillAttachPlot(plotView: SeriesPlotView<DateTime, number>, targetView: View | null, timeSeriesController: TimeSeriesController): void {
      this.owner.plots.insertView(null, plotView, targetView, timeSeriesController.key);
    },
    controllerDidDetachPlot(plotView: SeriesPlotView<DateTime, number>, timeSeriesController: TimeSeriesController): void {
      this.owner.plots.deleteView(plotView);
    },
  })
  readonly pinned!: ControllerSet<this, TimeSeriesController> & Observes<TimeSeriesController>;

  @ControllerSet({
    controllerType: TimeSeriesController,
  })
  readonly focused!: ControllerSet<this, TimeSeriesController>;

  @Provider({
    serviceType: CalendarService,
    observes: true,
    serviceDidSetScrubTime(scrubTime: DateTime | null): void {
      this.owner.currentTick.updateScrubTime(scrubTime);
    },
  })
  readonly calendar!: Provider<this, CalendarService> & Observes<CalendarService>;

  protected override onReinsertChild(child: Controller, target: Controller | null): void {
    super.onReinsertChild(child, target);
    this.series.pinTop();
  }

  protected override onStartConsuming(): void {
    super.onStartConsuming();
    this.pinned.consumeControllers(this);
  }

  protected override onStopConsuming(): void {
    super.onStopConsuming();
    this.pinned.unconsumeControllers(this);
  }

  static dateFormat: DateTimeFormat = DateTimeFormat.pattern("%b %d @ %H:%M");
}
