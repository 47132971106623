// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import type {Observes} from "@swim/util";
import {Property} from "@swim/component";
import type {Model} from "@swim/model";
import type {TraitObserver} from "@swim/model";
import {Trait} from "@swim/model";
import {TraitRef} from "@swim/model";
import {SelectableTrait} from "@swim/model";
import {Graphics} from "@swim/graphics";
import type {SheetController} from "@swim/sheet";
import {Status} from "./Status";
import {EntityTrait} from "./"; // forward import

/** @public */
export interface AspectTraitObserver<T extends AspectTrait = AspectTrait> extends TraitObserver<T> {
  traitDidSetTitle?(title: string, trait: T): void;

  traitDidSetIcon?(icon: Graphics | null, trait: T): void;

  traitDidSetStatus?(status: Status, trait: T): void;

  traitWillAttachEntity?(entityTrait: EntityTrait, targetTrait: Trait | null, trait: T): void;

  traitDidDetachEntity?(entityTrait: EntityTrait, trait: T): void;
}

/** @public */
export class AspectTrait extends Trait {
  declare readonly observerType?: Class<AspectTraitObserver>;

  @Property({valueType: String})
  readonly id!: Property<this, string | undefined>;

  @TraitRef({
    get traitType(): typeof EntityTrait {
      return EntityTrait;
    },
    willAttachTrait(entityTrait: EntityTrait, targetTrait: Trait | null): void {
      this.owner.callObservers("traitWillAttachEntity", entityTrait, targetTrait, this.owner);
    },
    initTrait(entityTrait: EntityTrait): void {
      this.owner.status.bindInlet(entityTrait.status);
    },
    deinitTrait(entityTrait: EntityTrait): void {
      this.owner.status.unbindInlet(entityTrait.status);
    },
    didDetachTrait(entityTrait: EntityTrait): void {
      this.owner.callObservers("traitDidDetachEntity", entityTrait, this.owner);
    },
  })
  readonly entity!: TraitRef<this, EntityTrait> & Observes<EntityTrait>;

  @Property({
    valueType: String,
    value: "",
    didSetValue(newTitle: string, oldTitle: string): void {
      this.owner.callObservers("traitDidSetTitle", newTitle, this.owner);
    },
    equalValues(newTitle: string, oldTitle: string): boolean {
      return newTitle === oldTitle;
    },
  })
  readonly title!: Property<this, string>;

  @Property({
    valueType: Graphics,
    value: null,
    didSetValue(icon: Graphics | null): void {
      this.owner.callObservers("traitDidSetIcon", icon, this.owner);
    },
    equalValues(newIcon: Graphics | null, oldIcon: Graphics | null): boolean {
      return newIcon === oldIcon;
    },
  })
  readonly icon!: Property<this, Graphics | null>;

  @Property({
    valueType: Status,
    value: Status.normal(),
    didSetValue(status: Status): void {
      this.owner.callObservers("traitDidSetStatus", status, this.owner);
    },
  })
  readonly status!: Property<this, Status>;

  createTabController(): SheetController | null {
    return null;
  }

  protected override onAttachModel(model: Model): void {
    super.onAttachModel(model);
    if (model.getTrait(SelectableTrait) === null) {
      model.appendTrait(SelectableTrait, "selectable");
    }
  }
}
