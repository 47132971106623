// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import type {Observes} from "@swim/util";
import {Property} from "@swim/component";
import type {Trait} from "@swim/model";
import {TraitRef} from "@swim/model";
import type {View} from "@swim/view";
import {ViewRef} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {TraitViewRef} from "@swim/controller";
import {TraitViewControllerRef} from "@swim/controller";
import type {SheetView} from "@swim/sheet";
import type {SheetControllerObserver} from "@swim/sheet";
import {SheetController} from "@swim/sheet";
import {PanelView} from "@swim/panel";
import type {GeoPerspective} from "@swim/map";
import type {GeoView} from "@swim/map";
import {GeoTrait} from "@swim/map";
import {GeoController} from "@swim/map";
import type {MapTrait} from "@swim/map";
import type {MapView} from "@swim/map";
import {MapController} from "@swim/map";
import {MapboxView} from "@swim/mapbox";
import {EntityTrait} from "@nstream/domain";

/** @public */
export interface AtlasControllerObserver<C extends AtlasController = AtlasController> extends SheetControllerObserver<C> {
  controllerWillAttachMap?(mapController: MapController, controller: C): void;

  controllerDidDetachMap?(mapController: MapController, controller: C): void;
}

/** @public */
export class AtlasController extends SheetController {
  declare readonly observerType?: Class<AtlasControllerObserver>;

  @TraitRef({
    traitType: EntityTrait,
    inherits: true,
    observes: true,
    willAttachTrait(entityTrait: EntityTrait): void {
      entityTrait.consume(this);
    },
    didAttachTrait(entityTrait: EntityTrait): void {
      this.owner.hostUri.bindInlet(entityTrait.hostUri);
      const geoTrait = entityTrait.getTrait(GeoTrait);
      if (geoTrait !== null) {
        this.owner.location.insertTrait(void 0, geoTrait);
      }
    },
    willDetachTrait(entityTrait: EntityTrait): void {
      if (entityTrait.getTrait(GeoTrait) === this.owner.location.trait) {
        this.owner.location.setTrait(null);
      }
      this.owner.hostUri.unbindInlet(entityTrait.hostUri);
    },
    didDetachTrait(entityTrait: EntityTrait): void {
      entityTrait.unconsume(this);
    },
    traitDidInsertTrait(memberTrait: Trait, targetTrait: Trait | null): void {
      if (memberTrait instanceof GeoTrait) {
        this.owner.location.setTrait(memberTrait);
      }
    },
  })
  readonly entity!: TraitRef<this, EntityTrait> & Observes<EntityTrait>;

  @TraitViewControllerRef({
    controllerType: GeoController,
    observes: true,
    getTraitViewRef(geoController: GeoController): TraitViewRef<unknown, GeoTrait, GeoView> {
      return geoController.geo;
    },
    init(): void {
      this.located = false;
    },
    didAttachController(geoController: GeoController): void {
      const geoTrait = geoController.geo.trait;
      if (geoTrait !== null) {
        this.attachGeoTrait(geoTrait, geoController);
      }
      const geoView = geoController.geo.attachView();
      this.attachGeoView(geoView, geoController);
      this.owner.map.attachController().layers.addController(geoController);
    },
    willDetachController(geoController: GeoController): void {
      const mapController = this.owner.map.controller;
      if (mapController !== null) {
        mapController.layers.deleteController(geoController);
      }
      const geoView = geoController.geo.view;
      if (geoView !== null) {
        this.detachGeoView(geoView, geoController);
      }
      const geoTrait = geoController.geo.trait;
      if (geoTrait !== null) {
        this.detachGeoTrait(geoTrait, geoController);
      }
    },
    controllerWillAttachGeoTrait(geoTrait: GeoTrait, geoController: GeoController): void {
      this.attachGeoTrait(geoTrait, geoController);
    },
    controllerDidDetachGeoTrait(geoTrait: GeoTrait, geoController: GeoController): void {
      this.detachGeoTrait(geoTrait, geoController);
    },
    attachGeoTrait(geoTrait: GeoTrait, geoController: GeoController): void {
      geoTrait.consume(this);
      const geoPerspective = geoTrait.geoPerspective.value;
      let mapView: MapView | null;
      if (geoPerspective !== null && (mapView = this.owner.map.view) !== null) {
        mapView.moveTo(geoPerspective, this.located);
        this.located = true;
      } else {
        this.owner.locationTracking.setIntrinsic(true);
      }
    },
    detachGeoTrait(geoTrait: GeoTrait, geoController: GeoController): void {
      this.owner.locationTracking.setIntrinsic(false);
      geoTrait.unconsume(this);
      this.deleteController();
    },
    controllerWillAttachGeoView(geoView: GeoView, geoController: GeoController): void {
      this.attachGeoView(geoView, geoController);
    },
    controllerDidDetachGeoView(geoView: GeoView, geoController: GeoController): void {
      this.detachGeoView(geoView, geoController);
    },
    attachGeoView(geoView: GeoView, geoController: GeoController): void {
      // hook
    },
    detachGeoView(geoView: GeoView, geoController: GeoController): void {
      // hook
    },
    controllerDidSetGeoPerspective(geoPerspective: GeoPerspective | null): void {
      let mapView: MapView | null;
      if (geoPerspective !== null && this.owner.locationTracking.value
          && (mapView = this.owner.map.view) !== null) {
        mapView.moveTo(geoPerspective, this.located);
        this.located = true;
        this.owner.locationTracking.setIntrinsic(false);
      }
    },
    createController(trait?: GeoTrait): GeoController {
      if (trait !== void 0) {
        return trait.createGeoController();
      }
      return super.createController(trait);
    },
  })
  readonly location!: TraitViewControllerRef<this, GeoTrait, GeoView, GeoController> & Observes<GeoController> & {
    /** @internal */
    located: boolean;
    attachGeoTrait(geoTrait: GeoTrait, geoController: GeoController): void,
    detachGeoTrait(geoTrait: GeoTrait, geoController: GeoController): void,
    attachGeoView(geoView: GeoView, geoController: GeoController): void,
    detachGeoView(geoView: GeoView, geoController: GeoController): void,
  };

  @Property({valueType: Boolean, value: false})
  readonly locationTracking!: Property<this, boolean>;

  @TraitViewControllerRef({
    controllerType: MapController,
    consumed: true,
    observes: true,
    get parentView(): SheetView | null {
      return this.owner.sheet.view;
    },
    getTraitViewRef(mapController: MapController): TraitViewRef<unknown, MapTrait, MapView> {
      return mapController.map;
    },
    initController(mapController: MapController): void {
      const sheetView = this.owner.sheet.view;
      if (sheetView !== null) {
        this.attachContainerView(sheetView, mapController);
      }
    },
    deinitController(mapController: MapController): void {
      const sheetView = this.owner.sheet.view;
      if (sheetView !== null) {
        this.detachContainerView(sheetView, mapController);
      }
    },
    willAttachController(mapController: MapController): void {
      this.owner.callObservers("controllerWillAttachMap", mapController, this.owner);
    },
    didDetachController(mapController: MapController): void {
      this.owner.callObservers("controllerDidDetachMap", mapController, this.owner);
    },
    controllerWillAttachMapView(mapView: MapView, mapController: MapController): void {
      mapView.canvas.attachView().setIntrinsic({
        pointerEvents: true,
      });
    },
    attachContainerView(containerView: HtmlView, mapController: MapController): void {
      let mapView = mapController.map.view;
      if (mapView === null) {
        mapView = this.owner.createMapView(containerView);
        mapController.map.setView(mapView);
      }
      mapController.container.setView(containerView);
    },
    detachContainerView(containerView: HtmlView, mapController: MapController): void {
      const mapView = mapController.map.view;
      if (mapView !== null) {
        mapView.container.setView(null);
      }
    },
  })
  readonly map!: TraitViewControllerRef<this, MapTrait, MapView, MapController> & Observes<MapController> & {
    attachContainerView(containerView: HtmlView, mapController: MapController): void,
    detachContainerView(containerView: HtmlView, mapController: MapController): void,
  };

  protected createMapView(containerView: HtmlView): MapView {
    const map = new mapboxgl.Map({
      container: containerView.node,
      boxZoom: false,
      center: {lng: 0, lat: 0},
      zoom: 1,
    });
    return new MapboxView(map);
  }

  @TraitViewRef({
    extends: true,
    initView(sheetView: SheetView): void {
      super.initView(sheetView);
      sheetView.fullBleed.setIntrinsic(true);
      const mapController = this.owner.map.attachController();
      this.owner.map.attachContainerView(sheetView, mapController);
    },
    deinitView(sheetView: SheetView): void {
      const mapController = this.owner.map.controller;
      if (mapController !== null) {
        this.owner.map.detachContainerView(sheetView, mapController);
      }
      super.deinitView(sheetView);
    },
  })
  override readonly sheet!: TraitViewRef<this, Trait, SheetView> & SheetController["sheet"];

  @ViewRef({
    viewType: PanelView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.panel.attachView();
    },
    initView(mapPanelView: PanelView): void {
      super.initView(mapPanelView);
      const mapController = this.owner.map.attachController();
      this.owner.map.attachContainerView(mapPanelView, mapController);
    },
    deinitView(mapPanelView: PanelView): void {
      const mapController = this.owner.map.controller;
      if (mapController !== null) {
        this.owner.map.detachContainerView(mapPanelView, mapController);
      }
      super.deinitView(mapPanelView);
    },
    createView(): PanelView {
      return (super.createView() as PanelView).setIntrinsic({
        classList: ["map-panel"],
        unitWidth: 1,
        unitHeight: 1,
        minPanelHeight: 0,
      });
    },
  })
  readonly mapPanel!: ViewRef<this, PanelView>;

  @ViewRef({
    viewType: PanelView,
    observes: true,
    initView(panelView: PanelView): void {
      this.owner.mapPanel.insertView(panelView);
    },
    didAttachView(panelView: PanelView, targetView: View | null): void {
      this.owner.panelHeader.setView(panelView.header.view);
      this.owner.panelTitle.setView(panelView.headerTitle.view);
      this.owner.panelSubtitle.setView(panelView.headerSubtitle.view);
    },
    willDetachView(panelView: PanelView): void {
      this.owner.panelHeader.setView(null);
      this.owner.panelTitle.setView(null);
      this.owner.panelSubtitle.setView(null);
    },
    viewWillAttachHeader(headerView: HtmlView): void {
      this.owner.panelHeader.setView(headerView);
    },
    viewDidDetachHeader(headerView: HtmlView): void {
      this.owner.panelHeader.setView(null);
    },
    viewWillAttachHeaderTitle(titleView: HtmlView): void {
      this.owner.panelTitle.setView(titleView);
    },
    viewDidDetachHeaderTitle(titleView: HtmlView): void {
      this.owner.panelTitle.setView(null);
    },
    viewWillAttachHeaderSubtitle(subtitleView: HtmlView): void {
      this.owner.panelSubtitle.setView(subtitleView);
    },
    viewDidDetachHeaderSubtitle(subtitleView: HtmlView): void {
      this.owner.panelSubtitle.setView(null);
    },
    viewDidMount(panelView: PanelView): void {
      this.owner.consume(panelView);
    },
    viewWillUnmount(panelView: PanelView): void {
      this.owner.unconsume(panelView);
    },
  })
  readonly panel!: ViewRef<this, PanelView> & Observes<PanelView>;

  @ViewRef({
    viewType: HtmlView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.panel.attachView().header.parentView;
    },
    initView(headerView: HtmlView): void {
      headerView.style.pointerEvents.setIntrinsic("none");
      this.owner.panel.attachView().header.setView(headerView);
    },
    createView(): HtmlView {
      return this.owner.panel.attachView().header.createView();
    },
  })
  readonly panelHeader!: ViewRef<this, HtmlView>;

  @ViewRef({
    viewType: HtmlView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.panel.attachView().headerTitle.parentView;
    },
    initView(titleView: HtmlView): void {
      this.owner.panel.attachView().headerTitle.setView(titleView);
    },
    setText(title: string | undefined): HtmlView {
      return this.owner.panel.attachView().headerTitle.set(title);
    },
    createView(): HtmlView {
      return this.owner.panel.attachView().headerTitle.createView();
    },
  })
  readonly panelTitle!: ViewRef<this, HtmlView> & {
    setText(title: string | undefined): HtmlView,
  };

  @ViewRef({
    viewType: HtmlView,
    viewKey: true,
    get parentView(): View | null {
      return this.owner.panel.attachView().headerSubtitle.parentView;
    },
    initView(subtitleView: HtmlView): void {
      this.owner.panel.attachView().headerSubtitle.setView(subtitleView);
    },
    setText(subtitle: string | undefined): HtmlView {
      return this.owner.panel.attachView().headerSubtitle.set(subtitle);
    },
    createView(): HtmlView {
      return this.owner.panel.attachView().headerSubtitle.createView();
    },
  })
  readonly panelSubtitle!: ViewRef<this, HtmlView> & {
    setText(subtitle: string | undefined): HtmlView,
  };
}
