// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import type {Observes} from "@swim/util";
import {Affinity} from "@swim/component";
import {Property} from "@swim/component";
import type {Uri} from "@swim/uri";
import {UriPath} from "@swim/uri";
import {UriPathBuilder} from "@swim/uri";
import {Model} from "@swim/model";
import type {TraitObserver} from "@swim/model";
import {Trait} from "@swim/model";
import {TraitRef} from "@swim/model";
import {TraitModelSet} from "@swim/model";
import {SelectableTrait} from "@swim/model";
import {Graphics} from "@swim/graphics";
import type {SheetController} from "@swim/sheet";
import {Status} from "./Status";
import {AspectTrait} from "./AspectTrait";
import {RelationTrait} from "./"; // forward import

/** @public */
export interface EntityTraitObserver<T extends EntityTrait = EntityTrait> extends TraitObserver<T> {
  traitDidSetPath?(path: UriPath | null, trait: T): void;

  traitDidSetTitle?(title: string, trait: T): void;

  traitDidSetIcon?(icon: Graphics | null, trait: T): void;

  traitDidSetStatus?(status: Status, trait: T): void;

  traitDidSetRelatable?(relatable: boolean, trait: T): void;

  traitDidSetSearchable?(searchable: boolean, trait: T): void;

  traitDidSetSearching?(searching: boolean, trait: T): void;

  traitDidSetSearchQuery?(query: string, trait: T): void;

  traitWillAttachAspect?(aspectTrait: AspectTrait, trait: T): void;

  traitDidDetachAspect?(aspectTrait: AspectTrait, trait: T): void;

  traitDidReinsertAspect?(aspectTrait: AspectTrait, targetTrait: AspectTrait | null, trait: T): void;

  traitWillAttachRelation?(relationTrait: RelationTrait, trait: T): void;

  traitDidDetachRelation?(relationTrait: RelationTrait, trait: T): void;

  traitDidReinsertRelation?(relationTrait: RelationTrait, targetTrait: RelationTrait | null, trait: T): void;

  traitDidSetRelationHidden?(hidden: boolean, relationTrait: RelationTrait, trait: T): void;

  traitDidSetRelationDisclosed?(disclosed: boolean, relationTrait: RelationTrait, trait: T): void;

  traitWillAttachRelatedEntity?(relatedEntityTrait: EntityTrait, relationTrait: RelationTrait, trait: T): void;

  traitDidDetachRelatedEntity?(relatedEntityTrait: EntityTrait, relationTrait: RelationTrait, trait: T): void;
}

/** @public */
export class EntityTrait extends Trait {
  declare readonly observerType?: Class<EntityTraitObserver>;

  @Property({
    extends: true,
    inherits: false,
  })
  override get nodeUri(): Property<this, Uri | null> {
    return Property.getter();
  }

  @Property({valueType: String})
  readonly id!: Property<this, string | undefined>;

  @Property({
    valueType: UriPath,
    value: null,
    inherits: true,
    get parentType(): typeof RelationTrait {
      return RelationTrait;
    },
    deriveValue(basePath: UriPath | null): UriPath | null {
      const id = this.owner.id.value;
      if (id === void 0) {
        return null;
      }
      const pathBuilder = new UriPathBuilder();
      if (basePath !== null) {
        pathBuilder.addPath(basePath);
      }
      pathBuilder.addSegment(id);
      return pathBuilder.build();
    },
    didSetValue(newPath: UriPath | null, oldPath: UriPath | null): void {
      this.owner.callObservers("traitDidSetPath", newPath, this.owner);
    },
    onMount(): void {
      super.onMount();
      if (this.inherits && !this.derived) {
        this.setValue(UriPath.slash(), Affinity.Reflexive);
      }
    },
  })
  readonly path!: Property<this, UriPath | null>;

  @Property({
    valueType: String,
    value: "",
    didSetValue(newTitle: string, oldTitle: string): void {
      this.owner.callObservers("traitDidSetTitle", newTitle, this.owner);
    },
    equalValues(newTitle: string, oldTitle: string): boolean {
      return newTitle === oldTitle;
    },
  })
  readonly title!: Property<this, string>;

  @Property({
    valueType: Graphics,
    value: null,
    didSetValue(icon: Graphics | null): void {
      this.owner.callObservers("traitDidSetIcon", icon, this.owner);
    },
    equalValues(newIcon: Graphics | null, oldIcon: Graphics | null): boolean {
      return newIcon === oldIcon;
    },
  })
  readonly icon!: Property<this, Graphics | null>;

  @Property({
    valueType: Status,
    value: Status.normal(),
    didSetValue(status: Status): void {
      this.owner.callObservers("traitDidSetStatus", status, this.owner);
    },
  })
  readonly status!: Property<this, Status>;

  @TraitRef({
    traitType: AspectTrait,
  })
  readonly aspect!: TraitRef<this, AspectTrait>;

  @TraitModelSet({
    traitType: AspectTrait,
    traitKey: "aspect",
    modelType: Model,
    binds: true,
    observes: true,
    init(): void {
      this.ids = {};
    },
    willAttachTrait(aspectTrait: AspectTrait): void {
      if (aspectTrait.id.value !== void 0) {
        this.ids[aspectTrait.id.value] = aspectTrait;
      }
      aspectTrait.entity.attachTrait(this.owner);
      this.owner.callObservers("traitWillAttachAspect", aspectTrait, this.owner);
      if (this.owner.aspect.trait === null) {
        this.owner.aspect.attachTrait(aspectTrait);
      }
    },
    didDetachTrait(aspectTrait: AspectTrait): void {
      if (this.owner.aspect.trait === aspectTrait) {
        this.owner.aspect.detachTrait();
      }
      this.owner.callObservers("traitDidDetachAspect", aspectTrait, this.owner);
      aspectTrait.entity.detachTrait();
      if (aspectTrait.id.value !== void 0) {
        delete this.ids[aspectTrait.id.value];
      }
    },
    modelDidAttachParent(parent: Model, model: Model): void {
      const aspectTrait = this.detectModelTrait(model);
      const targetModel = model.nextSibling;
      const targetTrait = targetModel !== null ? this.detectModelTrait(targetModel) : null;
      if (aspectTrait !== null) {
        this.owner.callObservers("traitDidReinsertAspect", aspectTrait, targetTrait, this.owner);
      }
    },
    createTrait(id?: string): AspectTrait {
      const trait = super.createTrait();
      if (id !== void 0) {
        trait.id.setIntrinsic(id);
      }
      this.createModel(trait);
      return trait;
    },
    get(id?: string): any/*Model | AspectTrait | null*/ {
      if (arguments.length === 0) {
        return null;
      }
      const trait = this.ids[id as string];
      return trait !== void 0 ? trait : null;
    },
  })
  readonly aspects!: TraitModelSet<this, AspectTrait, Model> & Observes<Model> & {
    /** @internal */
    ids: {[id: string]: AspectTrait | undefined};
    /** @override */
    createTrait(id?: string): AspectTrait;
    get(id: string): AspectTrait | null;
  };

  @TraitModelSet({
    get traitType(): typeof RelationTrait {
      return RelationTrait;
    },
    traitKey: "entity",
    modelType: Model,
    binds: true,
    observes: true,
    observesTrait: true,
    init(): void {
      this.ids = {};
    },
    willAttachTrait(relationTrait: RelationTrait): void {
      if (relationTrait.id.value !== void 0) {
        this.ids[relationTrait.id.value] = relationTrait;
      }
      this.owner.callObservers("traitWillAttachRelation", relationTrait, this.owner);
    },
    didDetachTrait(relationTrait: RelationTrait): void {
      this.owner.callObservers("traitDidDetachRelation", relationTrait, this.owner);
      if (relationTrait.id.value !== void 0) {
        delete this.ids[relationTrait.id.value];
      }
    },
    traitWillAttachEntity(relatedEntityTrait: EntityTrait, relationTrait: RelationTrait): void {
      this.owner.callObservers("traitWillAttachRelatedEntity", relatedEntityTrait, relationTrait, this.owner);
    },
    traitDidDetachEntity(relatedEntityTrait: EntityTrait, relationTrait: RelationTrait): void {
      this.owner.callObservers("traitDidDetachRelatedEntity", relatedEntityTrait, relationTrait, this.owner);
    },
    traitDidSetHidden(hidden: boolean, relationTrait: RelationTrait): void {
      this.owner.callObservers("traitDidSetRelationHidden", hidden, relationTrait, this.owner);
    },
    traitDidSetDisclosed(disclosed: boolean, relationTrait: RelationTrait): void {
      this.owner.callObservers("traitDidSetRelationDisclosed", disclosed, relationTrait, this.owner);
    },
    modelDidAttachParent(parent: Model, model: Model): void {
      const relationTrait = this.detectModelTrait(model);
      const targetModel = model.nextSibling;
      const targetTrait = targetModel !== null ? this.detectModelTrait(targetModel) : null;
      if (relationTrait !== null) {
        this.owner.callObservers("traitDidReinsertRelation", relationTrait, targetTrait, this.owner);
      }
    },
    createTrait(id?: string): RelationTrait {
      const trait = super.createTrait();
      if (id !== void 0) {
        trait.id.setIntrinsic(id);
      }
      this.createModel(trait);
      return trait;
    },
    get(id?: string): any/*Model | RelationTrait | null*/ {
      if (arguments.length === 0) {
        return null;
      }
      const trait = this.ids[id as string];
      return trait !== void 0 ? trait : null;
    },
  })
  readonly relations!: TraitModelSet<this, RelationTrait, Model> & Observes<Model> & Observes<RelationTrait> & {
    /** @internal */
    ids: {[id: string]: RelationTrait | undefined};
    /** @override */
    createTrait(id?: string): RelationTrait;
    get(id: string): RelationTrait | null;
  };

  @Property({
    valueType: Boolean,
    value: true,
    didSetValue(relatable: boolean): void {
      this.owner.callObservers("traitDidSetRelatable", relatable, this.owner);
    },
  })
  readonly relatable!: Property<this, boolean>;

  @Property({
    valueType: Boolean,
    value: false,
    didSetValue(searchable: boolean): void {
      this.owner.callObservers("traitDidSetSearchable", searchable, this.owner);
    },
  })
  readonly searchable!: Property<this, boolean>;

  @Property({
    valueType: Boolean,
    value: false,
    didSetValue(searching: boolean): void {
      if (searching) {
        this.owner.onStartSearching();
      } else {
        this.owner.onStopSearching();
      }
      this.owner.callObservers("traitDidSetSearching", searching, this.owner);
    },
  })
  readonly searching!: Property<this, boolean>;

  protected onStartSearching(): void {
    // hook
  }

  protected onStopSearching(): void {
    // hook
  }

  updateSearch(query: string): void {
    // hook
  }

  submitSearch(query: string): void {
    // hook
  }

  createNavController(): SheetController | null {
    return null;
  }

  protected override onAttachModel(model: Model): void {
    super.onAttachModel(model);
    if (model.getTrait(SelectableTrait) === null) {
      model.appendTrait(SelectableTrait, "selectable");
    }
  }
}
